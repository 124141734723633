import { IStaticMapSize, IWaypoint } from '../../models';

export function staticMapUrl(waypoints: IWaypoint[], staticMapSize: IStaticMapSize) {
  const path = waypoints
    .map(waypoint => {
      return `${waypoint.lat},${waypoint.lng}`;
    })
    .join('|');

  const size = Math.min(staticMapSize.width, 640);
  const scale = Math.min(staticMapSize.devicePixelRatio, 2);

  return encodeURI(
    `https://maps.googleapis.com/maps/api/staticmap?size=${size}x${size}&scale=${scale}&markers=color:0xffcf02|${waypoints[0].lat},${waypoints[0].lng}&path=color:0x00b0feff|weight:6|${path}&key=${process.env.REACT_APP_MAPS_KEY}`
  );
}

