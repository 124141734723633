import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { IAsyncActionState, reduceFailureAction, reduceRequestAction } from '../utils';
import { RootAction } from '../../store/root';
import actions from './actions';
import { commonActions } from '../common';

export interface IAppFeedbackState extends IAsyncActionState {
  feedbackSent: boolean;
}

const defaultState: IAppFeedbackState = {
  isLoading: false,
  feedbackSent: false,
};

const appFeedbackReducer = createReducer<IAppFeedbackState, RootAction>(defaultState)
  .handleAction(actions.sendFeedbackAsync.request, reduceRequestAction)
  .handleAction(actions.sendFeedbackAsync.failure, reduceFailureAction)
  .handleAction(actions.sendFeedbackAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.feedbackSent = true;
    })
  )
  .handleAction(actions.reset, state =>
    produce(state, draft => {
      draft.feedbackSent = false;
    })
  )
  .handleAction(commonActions.signOut.success, (state, action) => ({
    ...defaultState,
  }));

export default appFeedbackReducer;
