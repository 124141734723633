export enum NotificationPermission {
  GRANTED = 'granted',
  DEFAULT = 'default',
  DENIED = 'denied',
}

export interface NotificationOptions {
  badge?: string;
  body?: string;
  data?: any;
  icon?: string;
  image?: string;
  lang?: string;
  requireInteraction?: boolean;
  silent?: boolean;
}

export interface IPushMessagingService {
  isSupported(): Promise<boolean>;

  getNotificationPermission(): NotificationPermission;

  getToken(): Promise<string>;

  registerMessageListener(handler: (payload: any) => void): Promise<VoidFunction>;

  showNotification(title: string, options: NotificationOptions): Promise<void>;
}
