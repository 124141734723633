import { RootState } from '../../../store/root';
import { connect } from 'react-redux';
import { commonActions, commonSelectors } from '../../common';

const mapStateToProps = (state: RootState) => ({
  dialogConfig: commonSelectors.selectDialogConfig(state),
  snackbarConfig: commonSelectors.selectSnackbarConfig(state),
  isUserRegistered: commonSelectors.selectIsRegisteredUser(state),
});

const dispatchProps = {
  initApp: commonActions.initApp,
  hideDialog: commonActions.hideDialog,
  hideSnackbar: commonActions.hideSnackbar,
};

export type IAppProps = ReturnType<typeof mapStateToProps> & typeof dispatchProps;
export const connectApp = connect(mapStateToProps, dispatchProps);
