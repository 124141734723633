import { IPlayTourState } from './reducer';
import { filterRecord } from '../../utils/helper/object';

export const whitelist: (keyof IPlayTourState)[] = [
  'tourProgresses',
  'tourEvents',
  'currentlyPlayedTour',
  'onboardingCompleted',
  'hasHeartOnboardingAudio',
];

export const migrations: any = {
  '0': (state: IPlayTourState) => {
    return {
      ...state,
      tourProgresses: filterRecord(state.tourProgresses, (progress: any) => !progress.isPreview),
    };
  },
};
