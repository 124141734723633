import { useQueryParameter } from '@lialo/core/lib/utils/helper/url';
import { useServiceProvider } from '../../common/Providers';
import React from 'react';

export function useStoreUtmParameters() {
  const { storageService } = useServiceProvider();

  const queryParams = useQueryParameter();

  React.useEffect(() => {
    const utmParams = Array.from(queryParams.entries()).filter(([key]) => key.toLowerCase().startsWith('utm_'));

    if (utmParams.length > 0) {
      // ⚠️ key is referenced in payment module
      storageService.setItem('utm_params', {
        date: new Date().toISOString(),
        params: new URLSearchParams(utmParams).toString(),
      });
    }
  }, [storageService, queryParams]);
}
