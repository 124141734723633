import { ISimpleLocation } from '../models';

export interface SearchArea {
  north: number;
  east: number;
  south: number;
  west: number;
}

export const translateToNewCenter = (searchArea: SearchArea, center: ISimpleLocation): SearchArea => {
  const previousCenter: ISimpleLocation = {
    lat: (searchArea.north + searchArea.south) / 2,
    lng: (searchArea.west + searchArea.east) / 2,
  };

  const latOffset = center.lat - previousCenter.lat;
  const lngOffset = center.lng - previousCenter.lng;

  return {
    north: searchArea.north + latOffset,
    south: searchArea.south + latOffset,
    west: searchArea.west + lngOffset,
    east: searchArea.east + lngOffset,
  };
};

export const calculateCenter = (searchArea: SearchArea): ISimpleLocation => {
  return {
    lat: (searchArea.north + searchArea.south) / 2,
    lng: (searchArea.west + searchArea.east) / 2,
  };
};
