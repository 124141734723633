import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { emptyStringToUndefined } from '../../../utils/helper/strings';
import { showSnackbar } from '../../common/utils/showSnackbar';
import { requireUserOrThrow } from '../../utils/sagas';

const sendProblemReportSaga = takeLatest(getType(actions.sendProblemReportAsync.request), function* (
  action: ReturnType<typeof actions.sendProblemReportAsync.request>
) {
  yield race({
    send: call(sendProblemReport, action),
    cancelled: take(actions.sendProblemReportAsync.cancel),
  });
});

const sendProblemReport = function* (action: ReturnType<typeof actions.sendProblemReportAsync.request>) {
  const { username, email, onSuccess, ...rest } = action.payload;

  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    yield requireUserOrThrow();
    yield serviceProvider.api.sendTourProblemReport({
      ...rest,
      email: emptyStringToUndefined(email),
      username: emptyStringToUndefined(username),
    });
    yield put(actions.sendProblemReportAsync.success());
    yield showSnackbar({ key: 'play_tour.message_to_author_success' });
    yield call(onSuccess);
  } catch (e) {
    yield put(actions.sendProblemReportAsync.failure(e));
    serviceProvider.analyticsService.logError(e);
  }
};

export default [sendProblemReportSaga];
