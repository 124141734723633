import { LocationDescriptorObject } from 'history';
import { Chapter, StopSection } from '../../models';
import { To } from '../../services';

const ROOT = '/:lang(de|en)';
const CONTACT = ROOT + '/contact';
const ABOUT_APP = ROOT + '/about-app';
const FEEDBACK = ROOT + '/feedback';
const COOKIE_DECLARATION = ROOT + '/cookie-declaration';
const MY_TOURS = ROOT + '/my-tours';
const SIGN_IN = ROOT + '/sign-in';
const REDEEM_COUPON = ROOT + '/coupon';
const MY_TOURS_PLAYED = MY_TOURS + '/played';
const MY_TOURS_CREATED = MY_TOURS + '/created';
const TOUR_DETAIL = ROOT + '/tour/:tourCode';
const TOUR_INSIGHT = TOUR_DETAIL + '/preview';
const TOUR_CHECKOUT = TOUR_DETAIL + '/checkout';
const TOUR_PURCHASE_COUPON = TOUR_DETAIL + '/voucher'; // note: this url is used hardcoded in payment module
const TOUR_CONTENT = ROOT + '/tour/:tourCode/content';
const CREATE_TOUR = ROOT + '/create-tour';
const PROFILE = ROOT + '/profile/edit';
const PUBLIC_PROFILE = ROOT + '/profile/:userId';
const PROFILE_MAIL = PROFILE + '/mail';
const PROFILE_PASSWORD = PROFILE + '/password';
const PROFILE_IMAGE = PROFILE + '/image';
const PROFILE_VENDOR = PROFILE + '/vendor';
const PROFILE_SETTLEMENTS = PROFILE + '/settlements';
const CREATE_TOUR_PREVIEW = CREATE_TOUR + '/preview';
const EDIT_START_TEXT = CREATE_TOUR + '/start';
const EDIT_TOUR_MONETIZATION = CREATE_TOUR + '/monetization';
const EDIT_START_LOCATION = EDIT_START_TEXT + '/location';
const EDIT_IMAGE_LIST = CREATE_TOUR + '/images/:stopLocalId([0-9]+)?/:listType';
const EDIT_IMAGE_DETAIL = EDIT_IMAGE_LIST + '/:localId([0-9]+)';
const EDIT_END_TEXT = CREATE_TOUR + '/end';
const EDIT_END_LOCATION = EDIT_END_TEXT + '/location';
const EDIT_TOUR_STOPS = CREATE_TOUR + '/tour-stops';
const SETTINGS = CREATE_TOUR + '/settings';
const EDIT_TOUR_STOP = EDIT_TOUR_STOPS + '/:localId';
const EDIT_DIRECTIONS = EDIT_TOUR_STOP + '/directions';
const EDIT_WAYPOINTS = EDIT_TOUR_STOP + '/directions/editor';
const EDIT_INFORMATION = EDIT_TOUR_STOP + '/information';
const EDIT_TASK = EDIT_TOUR_STOP + '/task';
const PLAY_TOUR = ROOT + '/play/:tour';
const PLAY_TOUR_START = PLAY_TOUR + '/start';
const PLAY_TOUR_END = PLAY_TOUR + '/end';
const PLAY_TOUR_STOP = PLAY_TOUR + '/stop/:stopIndex';
const PLAY_TOUR_DIRECTIONS = PLAY_TOUR_STOP + '/directions';
const PLAY_TOUR_TASK = PLAY_TOUR_STOP + '/task';
const PLAY_TOUR_INFORMATION = PLAY_TOUR_STOP + '/information';
const PLAY_TOUR_CANCELLED = PLAY_TOUR + '/cancelled';
const PLAY_TOUR_DONATE = PLAY_TOUR + '/donation';
const PLAYBOOK = PLAY_TOUR + '/:tourSection?/:stopIndex?/:stopSection?';
const BLOG = 'https://www.lialo.com/blog/';
const SELL_TOUR_BLOG_ARTICLE = BLOG + 'touren-verkaufen/';
const AT_HOME = ROOT + '/at-home';

const GUIDANCE_PDF = 'https://www.lialo.de/leitfaden'; // .de Domain!

const FACEBOOK = 'https://www.facebook.com/lialo.tours';
const INSTAGRAM = 'https://www.instagram.com/lialo_com/';
const YOUTUBE = 'https://youtube.com/@lialo_com';
const FLYER_GENERATOR = 'https://europe-west3-lialo-com.cloudfunctions.net/flyer';
const FLYER_ALARM = 'https://www.flyeralarm.com/de/p/flyer-klassiker-4191540.html#/p/FV-4424193&/l/5756';

const PARAM_TOUR_SHORTLINK = 'loadTour';

function generateGoogleMapsLink(lat: number, lng: number, mode: 'location' | 'route'): string {
  if (mode === 'route') {
    return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
  } else {
    return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
  }
}

function generateVoucherLink(
  voucherGeneratorUrl: string,
  lang: string,
  tourCode: string,
  couponCode: string,
  validUntil: Date
): string {
  return `${voucherGeneratorUrl}?tourCode=${tourCode}&code=${couponCode}&lang=${lang}&valid=${validUntil.toISOString()}`;
}

function generateTourContentHash(chapter: Chapter, stopSection?: StopSection, orderId?: number) {
  let hash = chapter.toString();
  if (stopSection !== undefined && orderId !== undefined) {
    hash += `-${stopSection}-${orderId}`;
  }
  return hash;
}

function generateAbsoluteTourLink(tourCode: string, withProtocol = true) {
  const link = `lialo.tours/${tourCode}`; //ToDo: put in config
  return withProtocol ? 'https://' + link : link;
}

function generateFlyerLink(tourCode: string, lang: string) {
  return FLYER_GENERATOR + `?tourCode=${tourCode}&lang=${lang}`;
}

function createLocationDescriptorObject(to: To, state: any): LocationDescriptorObject {
  return typeof to === 'string' ? { pathname: to, state } : { ...to, state };
}

const allRoutes = {
  ROOT,
  MY_TOURS,
  MY_TOURS_PLAYED,
  MY_TOURS_CREATED,
  CREATE_TOUR,
  SIGN_IN,
  REDEEM_COUPON,
  TOUR_DETAIL,
  TOUR_CONTENT,
  TOUR_INSIGHT,
  TOUR_CHECKOUT,
  TOUR_PURCHASE_COUPON,
  CREATE_TOUR_PREVIEW,
  EDIT_IMAGE_LIST,
  EDIT_IMAGE_DETAIL,
  EDIT_START_TEXT,
  EDIT_START_LOCATION,
  EDIT_TOUR_MONETIZATION,
  EDIT_END_TEXT,
  EDIT_END_LOCATION,
  EDIT_TOUR_STOPS,
  EDIT_TOUR_STOP,
  EDIT_WAYPOINTS,
  EDIT_DIRECTIONS,
  EDIT_INFORMATION,
  EDIT_TASK,
  FLYER_ALARM,
  CONTACT,
  ABOUT_APP,
  FEEDBACK,
  COOKIE_DECLARATION,
  PUBLIC_PROFILE,
  PROFILE,
  PROFILE_MAIL,
  PROFILE_PASSWORD,
  PROFILE_IMAGE,
  PROFILE_VENDOR,
  PROFILE_SETTLEMENTS,
  PLAYBOOK,
  SETTINGS,
  PLAY_TOUR,
  PLAY_TOUR_START,
  PLAY_TOUR_END,
  PLAY_TOUR_DIRECTIONS,
  PLAY_TOUR_TASK,
  PLAY_TOUR_INFORMATION,
  PARAM_TOUR_SHORTLINK,
  PLAY_TOUR_CANCELLED,
  PLAY_TOUR_DONATE,
  FACEBOOK,
  INSTAGRAM,
  YOUTUBE,
  BLOG,
  SELL_TOUR_BLOG_ARTICLE,
  AT_HOME,
  GUIDANCE_PDF,
  createLocationDescriptorObject,
  generateGoogleMapsLink,
  generateTourContentHash,
  generateAbsoluteTourLink,
  generateFlyerLink,
  generateVoucherLink,
};

export default allRoutes;
