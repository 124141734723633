import { Language } from '@lialo/common/lib/language';
import { ISimpleLocation } from './Location';
import { ITourStop, selectImageUrlsFromTourStop } from './TourStop';
import { IImage } from './Media';
import { ITourSettings } from './TourSettings';
import { TourVisibility } from './TourVisibility';
import { IAverageRating } from './AverageRating';
import { IUserPreview } from './User';
import ReviewStatus from './ReviewStatus';
import { IAudioFile } from "./AudioFile";

export interface ITour {
  id: number;
  shortLink: string;
  user: IUserPreview;
  valid: boolean;
  visibility: TourVisibility;
  enableDonation: boolean;
  mainImages: IImage[];
  startLocation?: ISimpleLocation;
  startImages: IImage[];
  endLocation?: ISimpleLocation;
  endImages: IImage[];
  language: Language;
  price?: string;
  title?: string;
  description?: string;
  startText?: string;
  endText?: string;
  stops: ITourStop[];
  settings: ITourSettings;
  rating: IAverageRating;
  reviewStatus: ReviewStatus;
  createdAt?: Date;
  updatedAt?: Date;
  audioFiles?: IAudioFile[];
}

export function isTourValid(tour: ITour): boolean {
  // ToDo: Correct?
  return tour.valid;
}

export function getTitleImage(tour: ITour): IImage | undefined {
  return tour.mainImages.sortBy('orderId', 'ASC')[0];
}

export function getStartImages(tour: ITour): IImage[] {
  return tour.startImages.sortBy('orderId', 'ASC');
}

export function getEndImages(tour: ITour): IImage[] {
  return tour.endImages.sortBy('orderId', 'ASC');
}

export function selectImageUrlsFromTour(tour: ITour): string[] {
  const tourImageUrls = [tour.mainImages, tour.startImages, tour.endImages].map(list => list.map(img => img.url));

  const stopImageUrls = tour.stops.map(selectImageUrlsFromTourStop);
  return [...tourImageUrls, ...stopImageUrls].flatten();
}

export function getAudioFile(
  audioFiles: IAudioFile[],
  section: IAudioFile['section'],
  tourStopId?: number
): IAudioFile | undefined {
  return audioFiles.find(it => {
    if(tourStopId !== undefined) {
      return it.section === section && 'tourStopId' in it && it.tourStopId === tourStopId;
    }
    return it.section === section;
  });
}

export function getSortedStops(tour: ITour) {
  return tour.stops.sortBy('orderId', 'ASC');
}

export function countTasks(stops: Array<{ task?: any }>) {
  return stops.filter(stop => stop.task).length;
}
