import { ICacheService } from '@lialo/core/lib/services';
import { AsyncError, ErrorType } from '@lialo/core/lib/common';

export default class CacheService implements ICacheService {
  async cacheNewFiles(name: string, files: RequestInfo[]): Promise<void> {
    const cache = await window.caches.open(name);
    const filesToCache = files.mapNotOptional(async file => {
      const containsFile = Boolean(await cache.match(file));
      if (!containsFile) {
        return this.tryAddToCache(cache, file);
      }
    });
    await Promise.all(filesToCache);
  }

  async tryAddToCache(cache: Cache, file: RequestInfo): Promise<void> {
    try {
      await cache.add(file);
    } catch (e) {
      throw new AsyncError(ErrorType.CACHE_FILE_FAILED, `${(e as Error).message} (${ErrorType.CACHE_FILE_FAILED})`);
    }
  }

  async removeFromCache(name: string, files: RequestInfo[]): Promise<void> {
    const cache = await window.caches.open(name);
    const deletions = files.map(file => cache.delete(file));
    await Promise.all(deletions);
  }

  supportsCache(): boolean {
    return 'caches' in window;
  }
}
