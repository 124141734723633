import { getType } from 'typesafe-actions';
import actions from '../actions';
import { call, takeLatest } from 'redux-saga/effects';
import reauthenticateWithPassword from '../../common/utils/reauthenticateWithPassword';

const showReauthenticationDialogSaga = takeLatest(getType(actions.showReauthenticationDialog), function* ({
  payload: done,
}: ReturnType<typeof actions.showReauthenticationDialog>) {
  try {
    yield reauthenticateWithPassword();
  } catch (e) {
    //user canceled
    yield call(done);
  }
});

export default [showReauthenticationDialogSaga];
