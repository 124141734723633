import { RootState } from '../../store/root';
import { LandingPageType } from './models';
import { createSelector } from 'reselect';
import { commonSelectors } from '../common';
import { ITourListItemVM } from '../../common';
import { TourMarkerType } from "../findTour";

const selectIsLoading = (state: RootState) => state.landingPage.isLoading;
const selectError = (state: RootState) => state.landingPage.error;
const selectTours = (state: RootState) => state.landingPage.tours;
const selectToursByQuery = (type: LandingPageType) =>
  createSelector(
    [selectTours, commonSelectors.selectPurchasedToursMap],
    (tours, purchasedToursMap): ITourListItemVM[] => {
      return (
        tours[type]?.map(tourSummary => {
          return {
            ...tourSummary,
            status: TourMarkerType.DEFAULT, // doesn't matter as these are not displayed on the map
            purchased: purchasedToursMap[tourSummary.shortLink] === true,
          };
        }) ?? []
      );
    }
  );

export default {
  selectIsLoading,
  selectError,
  selectToursByQuery,
};
