import { createAction, createAsyncAction } from 'typesafe-actions';
import { PlayTourParams } from './utils/routerUtils';
import { IPlaybookPage, IStaticMapSize, ITourProgress, TourCode, TourEventType } from '../../models';
import { IAsyncError, ITipReminderRequest, ITourProblemReport } from '../../common';

interface InitTourRequest {
  params: PlayTourParams;
  imageWidth: number;
  staticMapSize: IStaticMapSize;
  restartIfNecessary: boolean;
}

interface OpenPlaybookRequest {
  tourCode: TourCode;
  title: string;
  mainImageUrl: string;
  imageWidth: number;
  staticMapSize: IStaticMapSize;
  purchased: boolean;
}

interface TourProblemReportRequest extends ITourProblemReport {
  onSuccess: () => void;
}

interface TipReminderRequest extends ITipReminderRequest {
  onSuccess: () => void;
}

const initializeTour = createAsyncAction(
  '@playTour/INITIALIZE_TOUR_REQUEST',
  '@playTour/INITIALIZE_TOUR_SUCCESS',
  '@playTour/INITIALIZE_TOUR_FAILURE',
  '@playTour/INITIALIZE_TOUR_CANCEL'
)<InitTourRequest, TourCode, IAsyncError, void>();

const saveTourProgress = createAsyncAction(
  '@playTour/SAVE_TOUR_PROGRESS_REQUEST',
  '@playTour/SAVE_TOUR_PROGRESS_SUCCESS',
  '@playTour/SAVE_TOUR_PROGRESS_FAILURE',
  '@playTour/SAVE_TOUR_PROGRESS_CANCEL'
)<TourCode, ITourProgress, IAsyncError, void>();

const tourEvents = {
  log: createAction('@playTour/LOG_TOUR_EVENT', action => (tourCode: TourCode, type: TourEventType) =>
    action({
      tourCode,
      type,
    })
  ),
  syncSuccess: createAction('@playTour/SYNC_SUCCESS', action => (timestamps: number[]) => action(timestamps)),
};

const openPlaybook = createAction('@playTour/OPEN_PLAYBOOK', action => {
  return (request: OpenPlaybookRequest, reset: boolean) =>
    action(request, {
      reset,
    });
});

const closePlaybook = createAction('@playTour/CLOSE_PLAYBOOK', action => {
  return (tourCode: string) => action(tourCode);
});

// Should be triggered when the user navigates away from the play-tour page
const clearCurrentlyPlayedTour = createAction('@playTour/CLEAR_CURRENTLY_PLAYED_TOUR');

const navigatePage = {
  request: createAction('@playTour/NAVIGATE_PAGE_REQUEST', action => {
    return (params: PlayTourParams, direction: 'previous' | 'next') => action({ params, direction });
  }),
  update: createAction('@playTour/NAVIGATE_PAGE_UPDATE', action => {
    return (tourCode: string, nextPage: IPlaybookPage) => action({ tourCode, nextPage });
  }),
};

const chooseAnswer = createAction('@playTour/CHOOSE_ANSWER', action => {
  return (answer: string, tourCode: string, stopIndex: number) => action({ answer, tourCode, stopIndex });
});

const increaseAttempts = createAction('@playTour/INCREASE_ATTEMPTS', action => {
    return (tourCode: string, stopIndex: number) => action({ tourCode, stopIndex });
});

const completeTour = {
  request: createAction('@common/COMPLETE_TOUR_REQUEST', action => {
    return (tourCode: string) => action(tourCode);
  }),
  success: createAction('@common/COMPLETE_TOUR_SUCCESS', action => {
    return (tourCode: string) => action(tourCode);
  }),
};

const completeOnboarding = createAction('@playTour/COMPLETE_ONBOARDING');

const sendProblemReportAsync = createAsyncAction(
  '@playTour/SEND_PROBLEM_REPORT_REQUEST',
  '@playTour/SEND_PROBLEM_REPORT_SUCCESS',
  '@playTour/SEND_PROBLEM_REPORT_FAILURE',
  '@playTour/SEND_PROBLEM_REPORT_CANCEL'
)<TourProblemReportRequest, void, IAsyncError, void>();

const sendTipReminder = createAsyncAction(
  '@playTour/SEND_TIP_REMINDER_REQUEST',
  '@playTour/SEND_TIP_REMINDER_SUCCESS',
  '@playTour/SEND_TIP_REMINDER_FAILURE',
  '@playTour/SEND_TIP_REMINDER_CANCEL'
)<TipReminderRequest, void, IAsyncError, void>();

const updateScore = createAction('@playTour/UPDATE_SCORE', action => (tourCode: string, score: number) =>
  action({ tourCode, score })
);

const markOnboardingAudioAsPlayed = createAction('@playTour/MARK_ONBOARDING_AUDIO_AS_PLAYED');

export default {
  initializeTour,
  openPlaybook,
  closePlaybook,
  navigatePage,
  chooseAnswer,
  saveTourProgress,
  tourEvents,
  completeTour,
  sendProblemReportAsync,
  sendTipReminder,
  clearCurrentlyPlayedTour,
  completeOnboarding,
  increaseAttempts,
  updateScore,
  markOnboardingAudioAsPlayed,
};
