import { styled } from '@mui/material/styles';
import React from 'react';
import { Box, BoxProps, CircularProgress, CircularProgressProps, Typography, TypographyProps } from '@mui/material';
import classNames from 'classnames';

const PREFIX = 'LoadingMessage';

const classes = {
  box: `${PREFIX}-box`,
  progress: `${PREFIX}-progress`,
};

const StyledBox = styled(Box)((
  {
    theme,
  },
) => ({
  [`&.${classes.box}`]: {
    display: 'flex',
  },

  [`& .${classes.progress}`]: {
    marginRight: theme.spacing(1),
  },
}));

interface IProps {
  message: string;
  className?: string;
  size?: CircularProgressProps['size'];
  variant?: TypographyProps['variant'];
}

export const LoadingMessage = (props: IProps & BoxProps) => {

  const { message, size, variant, className, ...boxProps } = props;

  return (
    <StyledBox className={classNames(classes.box, className)} {...boxProps}>
      <CircularProgress className={classes.progress} size={props.size ?? 16} />
      <Typography variant={props.variant ?? 'body2'}>{props.message}</Typography>
    </StyledBox>
  );
};
