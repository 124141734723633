import { dateReviver } from '@lialo/common/lib/date';
import { Language } from '@lialo/common/lib/language';
import {
  IPrivateUser,
  ISimpleLocation,
  ITour,
  ITourDetail,
  ITourEvent,
  ITourHighlight,
  ITourInsight,
  ITourProgress,
  ITourStatistics,
  ITourSummary,
  IUserProfile,
  TourCode,
  TourEventType,
} from '../../models';
import {
  AsyncError,
  ErrorType,
  IAppFeedback,
  ICreateUpdateTourReviewRequest,
  ICreateUpdateUserRequest,
  IInitialBounds,
  ImageUploadResponse,
  INotificationPreferences,
  ISuccessResponse,
  ITipReminderRequest,
  ITourFilter,
  ITourPrediction,
  ITourProblemReport,
  SearchArea,
  TourRatingListResponse,
  TourStopOfflineStatus,
} from '../../common';
import { IAuthService } from '..';
import { stringify } from 'query-string';
import { ICreateTourModel } from '../../pages/createTour/models/Tour';

export interface IApi {
  createTour(tour: ICreateTourModel): Promise<ICreateTourModel>;

  updateTour(tour: ICreateTourModel): Promise<ICreateTourModel>;

  cloneTour(shortlink: string): Promise<ICreateTourModel>;

  translateTour(shortlink: string, lang: string): Promise<ICreateTourModel>;

  fetchAvailableTranslationLanguages(shortLink: string): Promise<Language[]>;

  fetchCreateTourModel(shortlink: string): Promise<ICreateTourModel>;

  fetchTourByShortLink(shortlink: string, authenticated: boolean): Promise<ITour>;

  filterTours(lang: Language, filter: ITourFilter, area?: SearchArea): Promise<ITourSummary[]>;

  fetchTourSummariesByTourCodes(tourCodes: TourCode[]): Promise<Record<TourCode, ITourSummary | null>>;

  fetchMyTourSummaries(): Promise<ITourSummary[]>;

  fetchTourDetails(shortlink: string): Promise<ITourDetail>;

  fetchTourInsight(shortlink: string): Promise<ITourInsight>;

  fetchTourHighlights(shortlink: string): Promise<ITourHighlight[]>;

  fetchPrivateUser(): Promise<IPrivateUser>;

  createUser(authToken: string, user: ICreateUpdateUserRequest): Promise<IPrivateUser>;

  updateUser(user: ICreateUpdateUserRequest): Promise<IPrivateUser>;

  canDeleteUser(): Promise<boolean>;

  deleteCurrentUser(): Promise<void>;

  uploadImage(file: Blob): Promise<ImageUploadResponse>;

  requestReview(tourCode: string): Promise<void>;

  fetchPublicProfile(userId: string, lang: string): Promise<IUserProfile>;

  canDeleteTour(tourCode: string): Promise<boolean>;

  deleteTour(tourCode: string): Promise<ISuccessResponse>;

  saveTourProgress(tourProgress: ITourProgress): Promise<ITourProgress>;

  fetchTourProgresses(): Promise<ITourProgress[]>;

  sendAppFeedback(appFeedback: IAppFeedback): Promise<void>;

  sendTourProblemReport(report: ITourProblemReport): Promise<void>;

  sendTipReminder(request: ITipReminderRequest): Promise<void>;

  hasUserCreatedData(): Promise<boolean>;

  logTourEvents(events: ITourEvent[]): Promise<void>;

  fetchTourStatistics(tourCode: TourCode): Promise<ITourStatistics>;

  createUpdateTourReview(tourCode: string, review: ICreateUpdateTourReviewRequest): Promise<void>;

  fetchTourRatings(tourCode: string, before?: Date): Promise<TourRatingListResponse>;

  fetchUserLocationByIp(): Promise<ISimpleLocation>;

  fetchInitialBounds(): Promise<IInitialBounds>;

  fetchTourPredictions(title: string): Promise<ITourPrediction[]>;

  followAuthor(authorId: string): Promise<void>;

  unfollowAuthor(authorId: string): Promise<void>;

  subscribeLocation(location: ISimpleLocation): Promise<void>;

  fetchFolloweeIds(): Promise<string[]>;

  upsertPushMessagingToken(token: string, previousToken?: string): Promise<void>;

  deletePushMessagingToken(token: string): Promise<void>;

  fetchNotificationPreferences(): Promise<INotificationPreferences>;

  updateNotificationPreferences(preferences: INotificationPreferences): Promise<INotificationPreferences>;

  saveTourStopOfflineStatus(status: TourStopOfflineStatus[]): Promise<void>;

  makeRequest<T extends object>(request: Request): Promise<T>;
}

export interface IApiCallbacks {
  onVersionError: (error: AsyncError) => void;
}

export class Api implements IApi {
  private callbacks?: IApiCallbacks;

  constructor(
    private readonly baseUrl: string,
    private readonly apiContractVersion: number,
    private readonly getAuthService: () => Promise<IAuthService>
  ) {}

  public init(callbacks: IApiCallbacks) {
    this.callbacks = callbacks;
  }

  public createTour(tour: ICreateTourModel): Promise<ICreateTourModel> {
    return this.authenticatedRequest('POST', '/api/core/protected/create-tour', undefined, tour);
  }

  public updateTour(tour: ICreateTourModel): Promise<ICreateTourModel> {
    return this.authenticatedRequest('PUT', '/api/core/protected/create-tour', undefined, tour);
  }

  public translateTour(shortlink: string, lang: string): Promise<ICreateTourModel> {
    return this.authenticatedRequest('POST', `/api/core/protected/create-tour/${shortlink}/translate`, { lang });
  }

  public fetchAvailableTranslationLanguages(shortLink: string): Promise<Language[]> {
    return this.authenticatedRequest('GET', `/api/core/protected/create-tour/${shortLink}/translation-languages`);
  }

  public cloneTour(shortlink: string): Promise<ICreateTourModel> {
    return this.authenticatedRequest('POST', `/api/core/protected/create-tour/${shortlink}/clone`);
  }

  public fetchCreateTourModel(shortlink: string): Promise<ICreateTourModel> {
    return this.authenticatedRequest('GET', `/api/core/protected/create-tour/${shortlink}`);
  }

  public fetchTourByShortLink(shortlink: string): Promise<ITour> {
    return this.authenticatedRequest('GET', `/api/core/protected/tour/${shortlink}`);
  }

  public filterTours(uiLang: Language, filter: ITourFilter, area?: SearchArea): Promise<ITourSummary[]> {
    const params = {
      ...filter,
      ...area,
      uiLang,
    };

    const search = stringify(params, {
      skipNull: true,
      arrayFormat: 'none',
    });

    return this.request('GET', `/api/core/tour/filter?${search}`);
  }

  public fetchTourSummariesByTourCodes(tourCodes: TourCode[]): Promise<Record<TourCode, ITourSummary | null>> {
    return this.optionalAuthenticatedRequest('GET', '/api/core/tour/list', {
      ids: tourCodes.join(','),
    });
  }

  public fetchMyTourSummaries(): Promise<ITourSummary[]> {
    return this.authenticatedRequest('GET', '/api/core/protected/tour/mine');
  }

  public fetchTourDetails(shortlink: string): Promise<ITourDetail> {
    return this.optionalAuthenticatedRequest('GET', `/api/core/tour/${shortlink}/details`);
  }

  public fetchTourInsight(shortlink: string): Promise<ITourInsight> {
    return this.request('GET', `/api/core/tour/${shortlink}/insight`);
  }

  public fetchTourHighlights(shortlink: string): Promise<ITourHighlight[]> {
    return this.optionalAuthenticatedRequest('GET', `/api/core/tour/${shortlink}/highlights`);
  }

  public fetchPrivateUser(): Promise<IPrivateUser> {
    return this.authenticatedRequest('GET', '/api/core/protected/user');
  }

  public createUser(authToken: string, user: ICreateUpdateUserRequest): Promise<IPrivateUser> {
    return this.request('POST', '/api/core/user', undefined, {
      authToken,
      user,
    });
  }

  public async deleteCurrentUser(): Promise<void> {
    await this.authenticatedRequest('DELETE', '/api/core/protected/user');
  }

  public async canDeleteUser(): Promise<boolean> {
    const response = await this.authenticatedRequest<{ canDelete: boolean }>(
      'GET',
      '/api/core/protected/user/canDelete'
    );
    return response.canDelete;
  }

  public updateUser(user: ICreateUpdateUserRequest): Promise<IPrivateUser> {
    return this.authenticatedRequest('PUT', '/api/core/protected/user', undefined, user);
  }

  public async uploadImage(file: Blob): Promise<ImageUploadResponse> {
    const data = new FormData();
    data.append('file', file);
    return this.authenticatedRequest('POST', '/api/core/protected/image', undefined, data);
  }

  public deleteTour(tourCode: string): Promise<ISuccessResponse> {
    return this.authenticatedRequest('DELETE', '/api/core/protected/create-tour', undefined, { shortLink: tourCode });
  }

  public async canDeleteTour(tourCode: string): Promise<boolean> {
    const result = await this.authenticatedRequest<{ canDelete: boolean }>(
      'GET',
      `/api/core/protected/create-tour/${tourCode}/canDelete`
    );
    return result.canDelete;
  }

  public fetchTourProgresses(): Promise<ITourProgress[]> {
    return this.authenticatedRequest('GET', '/api/core/protected/tourprogress');
  }

  public async hasUserCreatedData(): Promise<boolean> {
    const response: { hasAnyData: boolean } = await this.authenticatedRequest('GET', 'api/core/protected/user/info');
    return response.hasAnyData;
  }

  public saveTourProgress(tourProgress: ITourProgress): Promise<ITourProgress> {
    return this.authenticatedRequest('PUT', '/api/core/protected/tourprogress', undefined, tourProgress);
  }

  public async requestReview(tourCode: string): Promise<void> {
    await this.authenticatedRequest('PUT', `/api/core/protected/create-tour/${tourCode}/review`);
  }

  public fetchPublicProfile(userId: string, lang: string): Promise<IUserProfile> {
    return this.request('GET', `/api/core/profile/${userId}`, { lang });
  }

  public async logTourEvents(events: ITourEvent[]): Promise<void> {
    await this.authenticatedRequest('POST', '/api/core/protected/tourevent', undefined, events);
  }

  public async fetchTourStatistics(tourCode: string): Promise<ITourStatistics> {
    const eventCounts: { eventType: TourEventType; count: number }[] = await this.authenticatedRequest(
      'GET',
      `/api/core/protected/tourevent/${tourCode}`
    );
    return eventCounts.reduce(
      (acc: ITourStatistics, curr) => {
        switch (curr.eventType) {
          case TourEventType.started:
            acc.started = curr.count;
            break;
          case TourEventType.cancelled:
            acc.cancelled = curr.count;
            break;
          case TourEventType.completed:
            acc.completed = curr.count;
            break;
        }
        return acc;
      },
      {
        started: 0,
        cancelled: 0,
        completed: 0,
      }
    );
  }

  public async sendAppFeedback(appFeedback: IAppFeedback): Promise<void> {
    await this.authenticatedRequest('POST', '/api/core/protected/feedback', undefined, appFeedback);
  }

  public async createUpdateTourReview(tourCode: string, review: ICreateUpdateTourReviewRequest): Promise<void> {
    await this.authenticatedRequest('POST', `/api/core/protected/tour/${tourCode}/rating`, undefined, review);
  }

  public fetchTourRatings(tourCode: string, before?: Date): Promise<TourRatingListResponse> {
    return this.optionalAuthenticatedRequest(
      'GET',
      `/api/core/tour/${tourCode}/rating`,
      before
        ? {
            before: before.toISOString(),
          }
        : undefined
    );
  }

  public async sendTourProblemReport(report: ITourProblemReport): Promise<void> {
    await this.authenticatedRequest('POST', '/api/core/protected/tour-problem-report', undefined, report);
  }

  public async sendTipReminder({ language, ...request }: ITipReminderRequest): Promise<void> {
    await this.authenticatedRequest('POST', '/api/core/protected/tip-reminder', undefined, request, {
      'Accept-Language': language,
    });
  }

  public fetchUserLocationByIp(): Promise<ISimpleLocation> {
    return this.request('GET', `/api/core/geolocation/user`);
  }

  public fetchInitialBounds(): Promise<IInitialBounds> {
    return this.request('GET', `/api/core/geolocation/initial-bounds`);
  }

  public fetchTourPredictions(title: string): Promise<ITourPrediction[]> {
    return this.request('GET', `/api/core/tour/predictions`, {
      title,
    });
  }

  public async followAuthor(authorId: string): Promise<void> {
    await this.authenticatedRequest('POST', `/api/core/protected/followees/${authorId}/follow`);
  }

  public async unfollowAuthor(authorId: string): Promise<void> {
    await this.authenticatedRequest('DELETE', `/api/core/protected/followees/${authorId}/unfollow`);
  }

  public async subscribeLocation(location: ISimpleLocation): Promise<void> {
    await this.authenticatedRequest('POST', `/api/core/protected/location-subscription`, undefined, location);
  }

  public async fetchFolloweeIds(): Promise<string[]> {
    return this.authenticatedRequest<string[]>('GET', `/api/core/protected/followees`);
  }

  public async upsertPushMessagingToken(token: string, previousToken?: string): Promise<void> {
    await this.authenticatedRequest('PUT', `/api/core/protected/push-messaging-tokens`, undefined, {
      token,
      previousToken,
    });
  }

  public async deletePushMessagingToken(token: string): Promise<void> {
    await this.authenticatedRequest('DELETE', `/api/core/protected/push-messaging-tokens/${token}`);
  }

  public fetchNotificationPreferences(): Promise<INotificationPreferences> {
    return this.authenticatedRequest('GET', '/api/core/protected/user/notification-preferences');
  }

  public updateNotificationPreferences(preferences: INotificationPreferences): Promise<INotificationPreferences> {
    return this.authenticatedRequest(
      'PUT',
      '/api/core/protected/user/notification-preferences',
      undefined,
      preferences
    );
  }

  public async saveTourStopOfflineStatus(status: TourStopOfflineStatus[]): Promise<void> {
    await this.authenticatedRequest('POST', '/api/core/protected/tour-stop-offline-status', undefined, status);
  }

  public async makeRequest<T extends object>(request: Request): Promise<T> {
    let response: Response;
    try {
      response = await fetch(request);
    } catch (e) {
      throw new AsyncError(ErrorType.NETWORK);
    }

    if (response.ok) {
      try {
        const body = await response.text();
        return JSON.parse(body, dateReviver) as T;
      } catch (e) {
        console.log(e);
        throw new AsyncError(ErrorType.UNKNOWN);
      }
    } else {
      const error = await this.processError(request, response);
      if (error.errorCode === ErrorType.CONTRACT_VERSION_TOO_LOW) {
        this.callbacks?.onVersionError(error);
      }

      throw error;
    }
  }

  private async authenticatedRequest<T extends object>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    path: string,
    queryParams?: any,
    body?: any,
    headers?: Record<string, string>
  ): Promise<T> {
    const authService = await this.getAuthService();
    const idToken = await authService.getIdToken();
    if (!idToken) {
      throw new AsyncError(ErrorType.UNAUTHENTICATED, 'ID Token is undefined');
    }

    const h = headers ?? {};
    h['Authorization'] = `Bearer ${idToken}`;

    return this.request(method, path, queryParams, body, h);
  }

  private async optionalAuthenticatedRequest<T extends object>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    path: string,
    queryParams?: any,
    body?: any,
    headers?: Record<string, string>
  ): Promise<T> {
    const authService = await this.getAuthService();
    const idToken = await authService.getIdToken();
    const h = headers ?? {};
    if (idToken) {
      h['Authorization'] = `Bearer ${idToken}`;
    }

    return this.request(method, path, queryParams, body, h);
  }

  private async request<T extends object>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    path: string,
    queryParams?: any,
    body?: any,
    headers?: Record<string, string>
  ): Promise<T> {
    const url = this.makeUrl(path);
    if (queryParams) {
      url.search = new URLSearchParams(queryParams).toString();
    }

    const isFormDataBody = body instanceof FormData;
    const combinedHeaders = headers ?? {};
    combinedHeaders['x-contract-version'] = `${this.apiContractVersion}`;

    if (!isFormDataBody) {
      combinedHeaders['Content-Type'] = 'application/json';
    }

    const processedBody = isFormDataBody ? body : JSON.stringify(body);
    const request = new Request(url.toString(), {
      headers: combinedHeaders,
      body: processedBody,
      method,
    });

    return await this.makeRequest(request);
  }

  private makeUrl(path: string): URL {
    return new URL(path, this.baseUrl);
  }

  private async processError(request: Request, response: Response): Promise<AsyncError> {
    let json;
    try {
      json = await response.json();
    } catch (e) {
      return new AsyncError(ErrorType.UNKNOWN);
    }

    if (json.hasOwnProperty('errorCode')) {
      return new AsyncError(json.errorCode, json.devDescription);
    } else {
      return new AsyncError(ErrorType.UNKNOWN);
    }
  }
}
