import { RootState } from '../../store/root';
import { createSelector } from 'reselect';
import { convertToRatingVM } from './models';
import { ITourDetail, ITourHighlight, ITourInsight, ITourRating } from "../../models";
import { commonSelectors } from '../common';
import { Authority, hasAuthority, IAsyncError } from '../../common';

const selectIsLoading = (state: RootState) => state.tourDetail.isLoading;
const selectError = (state: RootState) => state.tourDetail.error;
const selectTourDetails = (state: RootState): ITourDetail | undefined => state.tourDetail.tourDetails;

const selectIsLoadingTourRatings = (state: RootState) => state.tourDetail.isLoadingTourRatings;
const selectTourRatingsError = (state: RootState) => state.tourDetail.sendTourRatingError;
const selectNumberOfComments = (state: RootState) => state.tourDetail.numberOfComments;
const selectTourRatings = (state: RootState) => state.tourDetail.ratings;

const selectTourRatingVMs = createSelector([selectTourRatings], ratings => ratings.map(convertToRatingVM));

const selectOldestTourRating = createSelector([selectTourRatings], (ratings: ITourRating[]) =>
  ratings.length > 0 ? ratings[ratings.length - 1] : null
);

const selectHasMoreRatings = createSelector(selectTourRatings, selectNumberOfComments, (ratings, totalCount) =>
  totalCount === undefined ? true : ratings.length < totalCount
);

const selectRequiresPurchase = createSelector(
  selectTourDetails,
  commonSelectors.selectUser,
  commonSelectors.selectAuthorities,
  (tour, user, authorities) => {
    return (
      !hasAuthority(authorities, Authority.PLAY_FOR_FREE) &&
      tour?.user?.id !== user?.id &&
      !tour?.purchased &&
      tour?.price !== undefined
    );
  }
);

const selectIsLoadingTourInsight = (state: RootState): boolean => state.tourDetail.isLoadingInsight;
const selectTourInsightError = (state: RootState): IAsyncError | undefined => state.tourDetail.insightError;
const selectTourInsight = (state: RootState): ITourInsight | undefined => state.tourDetail.tourInsight;

const selectIsLoadingTourHighlights = (state: RootState): boolean => state.tourDetail.isLoadingHighlights;
const selectTourHighlights = (state: RootState): ITourHighlight[] | undefined => state.tourDetail.tourHighlights;

export default {
  selectIsLoading,
  selectError,
  selectTourDetails,
  selectIsLoadingTourRatings,
  selectTourRatingsError,
  selectTourRatingVMs,
  selectOldestTourRating,
  selectNumberOfComments,
  selectHasMoreRatings,
  selectRequiresPurchase,
  selectIsLoadingTourInsight,
  selectTourInsightError,
  selectTourInsight,
  selectIsLoadingTourHighlights,
  selectTourHighlights,
};
