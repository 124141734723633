import { RootState } from '../../store/root';
import { createSelector } from 'reselect';
import { convertToProfileTourCardVM, ProfileTourCardType } from './models';
import { IUserProfile } from '../../models';

const selectIsLoading = (state: RootState) => state.publicProfile.isLoading;
const selectError = (state: RootState) => state.publicProfile.error;
const selectProfile = (state: RootState): IUserProfile | undefined => state.publicProfile.profile;

const selectUser = createSelector(selectProfile, profile => profile?.user);
const selectCompletedTours = createSelector(selectProfile, profile => {
  if (profile) {
    return profile.completedTours
      .sortBy('startedAt', 'DESC')
      .map(t => convertToProfileTourCardVM(t, ProfileTourCardType.COMPLETED));
  }
});

const selectCreatedTours = createSelector(selectProfile, profile => {
  if (profile) {
    return profile.createdTours
      .sortBy('createdAt', 'DESC')
      .map(t => convertToProfileTourCardVM(t, ProfileTourCardType.CREATED));
  }
});

export default {
  selectIsLoading,
  selectError,
  selectUser,
  selectCompletedTours,
  selectCreatedTours,
};
