import { IAppState } from './reducer';
import { filterRecord, mapObjectValues } from '../../utils/helper/object';
import { IImage, ITask, ITourStop } from '../../models';
import { convertToSimpleLocation } from '../createTour/utils/convert';

export const blacklist: (keyof IAppState)[] = ['isLoading', 'error', 'showUpdateDialog', 'isAppUpdateAvailable'];

export const migrations: any = {
  '0': (state: IAppState) => {
    const transformedTours = mapObjectValues(state.offlineTours, migrateTour);
    return {
      ...state,
      offlineTours: filterRecord(transformedTours, tour => !!tour),
    };
  },
  '1': (state: IAppState) => {
    return {
      ...state,
      offlineTours: mapObjectValues(state.offlineTours as any, ({ monetization, ...tour }) => {
        return {
          ...tour,
          enableDonation: monetization == 'TIP',
        };
      }),
    };
  },
};

function migrateTour(oldTour: any): any | null {
  const translation = oldTour.translations.find(it => it.language === 'de');
  if (!translation) return null;
  return {
    id: oldTour.id,
    shortLink: oldTour.shortLink!!,
    user: oldTour.user,
    valid: oldTour.valid,
    mainImages: migrateImageList(oldTour.media?.mainImageList),
    startImages: migrateImageList(oldTour.media?.startImageList),
    endImages: migrateImageList(oldTour.media?.endImageList),
    visibility: oldTour.visibility,
    monetization: oldTour.monetization,
    createdAt: oldTour.createdAt,
    startLocation: convertToSimpleLocation(oldTour.startLocation),
    endLocation: convertToSimpleLocation(oldTour.endLocation),
    title: translation.title,
    description: translation.description,
    startText: translation.startText,
    endText: translation.endText,
    language: translation.language,
    settings: oldTour.settings,
    rating: oldTour.rating,
    reviewStatus: oldTour.reviewStatus,
    updatedAt: oldTour.updatedAt,
    stops: oldTour.stops.mapNotOptional(migrateTourStop),
  };
}

function migrateTourStop(oldStop: any): ITourStop | null {
  const translation = oldStop.translations.find(it => it.language === 'de');
  if (!translation) return null;
  return {
    id: oldStop.id,
    orderId: oldStop.orderId,
    title: translation.title,
    information: translation.information,
    directions: translation.directions,
    directionImages: migrateImageList(oldStop.media?.directionsImageList),
    informationImages: migrateImageList(oldStop.media?.informationImageList),
    task: oldStop.task ? migrateTask(oldStop.task) ?? undefined : undefined,
    lookIntoTour: false,
  };
}

function migrateTask(oldTask: any): ITask | null {
  const translation = oldTask.translations.find(it => it.language === 'de');
  if (!translation) return null;
  return {
    taskImages: migrateImageList(oldTask.imageList),
    question: translation.question,
    answer: translation.answer,
    wrongAnswer1: translation.wrongAnswer1,
    wrongAnswer2: translation.wrongAnswer2,
    wrongAnswer3: translation.wrongAnswer3,
    hint: translation.hint,
  };
}

function migrateImageList(imageList: any): IImage[] {
  return (
    imageList?.images?.map(img => ({
      orderId: img.orderId,
      url: img.url,
      caption: img.translations[0]?.caption,
    })) ?? []
  );
}
