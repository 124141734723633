import { getType } from 'typesafe-actions';
import commonActions from '../actions';
import { put, takeLatest } from 'redux-saga/effects';
import { getServiceProvider, IAuthService, IServiceProvider } from '../../../services';
import { AsyncError } from "../../../common";

const resetPasswordSaga = takeLatest(getType(commonActions.resetPassword.request), function* () {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService: IAuthService = yield serviceProvider.getAuthService();

  try {
    yield authService.sendPasswordResetEmail();
    yield put(commonActions.resetPassword.success());
  } catch (e) {
    yield put(commonActions.resetPassword.failure(e as AsyncError));
    serviceProvider.analyticsService.logError(e as AsyncError);
  }
});

export default [resetPasswordSaga];
