import { LOCATION_CHANGE, LocationChangePayload } from 'connected-react-router';
import { Reducer } from 'typesafe-actions';
import { Location, LocationDescriptorObject } from 'history';
import { RootAction } from '../../../store/root';

export type PreviousLocationState = {
  prevLocation: Location | null;
  currentLocation: Location | null;
  returnToLocation: LocationDescriptorObject | null;
};
const defaultState: PreviousLocationState = {
  prevLocation: null,
  currentLocation: null,
  returnToLocation: null,
};

const reducer: Reducer<PreviousLocationState, RootAction> = (state = defaultState, incomingAction) => {
  if (incomingAction.type === LOCATION_CHANGE) {
    // type issues, location is not recognized correctly
    const { action, location, isFirstRendering } = incomingAction.payload as LocationChangePayload & {
      location: Location;
    };

    const locationState: any | undefined = location?.state;

    if (isFirstRendering) {
      return {
        prevLocation: null,
        currentLocation: location,
        returnToLocation: null,
      };
    } else if (action === 'PUSH')
      return {
        prevLocation: state.currentLocation,
        currentLocation: location,
        returnToLocation: locationState?.returnTo ? locationState.returnTo : state.returnToLocation,
      };
  }

  return state;
};

export default reducer;
