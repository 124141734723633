import React from 'react';
import { CloseableSnackbar } from "../ClosableSnackbar";

interface IProps {
  open: boolean;
  translation?: string;
  handleClose: () => void;
}

export const MessageSnackbar = (props: IProps) => {
  return (
    <CloseableSnackbar
      ClickAwayListenerProps={{
        touchEvent: false,
        mouseEvent: false,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={props.open}
      autoHideDuration={5000}
      onClose={props.handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={props.translation && <span id="message-id">{props.translation}</span>}
    />
  );
};

