import { call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import actions from '../actions';
import { getType } from 'typesafe-actions';
import { commonActions, commonSelectors } from '../../common';
import { IFirebaseUser } from '../../../models';
import { getServiceProvider, IAuthService, IServiceProvider, LoginResult } from '../../../services';
import { AsyncError, ErrorType, IAsyncError, ICreateUpdateUserRequest } from '../../../common';
import { AsyncActionResult, takeAsyncActionResult, takeConfirmation } from '../../utils/sagas';
import routes from '../../utils/routes';
import { showErrorSnackbar } from '../../common/utils/showSnackbar';
import { notificationActions } from '../../notifications/';

const upgradeOrCreateAccount = takeEvery(getType(actions.upgradeAccount), function* (
  action: ReturnType<typeof actions.upgradeAccount>
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService: IAuthService = yield serviceProvider.getAuthService();

  try {
    if (!action.payload.user.anonymous) {
      yield put(commonActions.signIn.request());
      const populatedUser = yield authService.populateProfileDataFromProvider();
      yield createOrUpdateUserOnServer(populatedUser, action.payload.user.isNewUser);

      yield call(action.payload.done, null);
      yield call(completeSignIn, populatedUser);
      yield showSignInSnackbar(populatedUser);
    } else {
      yield call(action.payload.done, null);
    }
  } catch (e) {
    yield failSignIn(e);
    yield call(action.payload.done, e);
    serviceProvider.analyticsService.logError(e);
  }
});

function* createOrUpdateUserOnServer(user: IFirebaseUser, isNewUser?: boolean) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService: IAuthService = yield serviceProvider.getAuthService();

  const previousUser = commonSelectors.selectUser(yield select());

  const createUpdateUserRequest: ICreateUpdateUserRequest = {
    anonymous: user.anonymous,
    imageUrl: user.imageUrl,
    username: user.username,
    translations: [], // user cant have translations at this moment
  };

  if (!previousUser && isNewUser) {
    // signup without user having anonymous profile before
    try {
      const idToken = yield authService.getIdToken();
      yield serviceProvider.api.createUser(idToken, createUpdateUserRequest);
    } catch (e) {
      serviceProvider.errorReporter.reportError(
        new Error(`Failed to signup new user ${user.id} on server :: ${e.toString()}`)
      );
      throw e;
    }
    serviceProvider.analyticsService.logSignUp(user.providerId);
  } else if (!previousUser && !isNewUser) {
    // signin without user having anonymous profile before
    serviceProvider.analyticsService.logSignIn(user.providerId);
  } else {
    // signup user having anonymous profile before
    try {
      yield serviceProvider.api.updateUser(createUpdateUserRequest);
    } catch (e) {
      serviceProvider.errorReporter.reportError(
        new Error(`Failed to upgrade anonymous user ${user.id} on server :: ${e.toString()}`)
      );
      throw e;
    }
    serviceProvider.analyticsService.logSignUp(user.providerId);
  }
}

const switchAccount = takeEvery(getType(actions.switchAccount), function* (
  action: ReturnType<typeof actions.switchAccount>
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService: IAuthService = yield serviceProvider.getAuthService();

  const dataLossConfirmed = yield confirmDataLoss();
  if (!dataLossConfirmed) {
    yield call(
      action.payload.done,
      new AsyncError(ErrorType.USER_CANCELED, 'User canceled login to prevent data loss.')
    );
    return;
  }

  try {
    yield put(commonActions.signIn.request());
    const currentUser = authService.currentUser;
    if (currentUser) {
      yield serviceProvider.api.deleteCurrentUser();
      yield authService.deleteCurrentUser();
    }
    yield put(commonActions.signOut.success());
    const user = yield authService.signInWithCredential(action.payload.credential);

    yield call(action.payload.done, null);
    yield call(completeSignIn, user);

    serviceProvider.analyticsService.logSignIn(user.providerId);
    yield showSignInSnackbar(user);
  } catch (e) {
    yield failSignIn(e);
    yield call(action.payload.done, e);
    serviceProvider.analyticsService.logError(e);
  }
});

const clearUser = takeEvery(getType(commonActions.clearUser), function* () {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService: IAuthService = yield serviceProvider.getAuthService();

  try {
    yield authService.signOut();
  } catch (e) {
    console.log('Failed to sign out user when clearing', e);
  }
});

const signOut = takeLatest(getType(commonActions.signOut.request), function* () {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService: IAuthService = yield serviceProvider.getAuthService();

  const confirmed: boolean = yield takeConfirmation({
    content: {
      key: 'drawer.sign_out_confirmation',
    },
    positiveKey: 'actions.sign_out',
  });

  if (!confirmed) return;

  try {
    yield call(deleteFcmToken);
    yield authService.signOut();
    yield put(commonActions.signOut.success());
    yield put(
      commonActions.showSnackbar({
        key: 'common.sign_out_completed',
      })
    );
    serviceProvider.navigationService.navigateI18n(routes.ROOT, { replace: true });
    serviceProvider.analyticsService.logSignOut(authService.currentUser?.providerId);
  } catch (e) {
    const error = new AsyncError(ErrorType.SIGN_OUT, e.message);
    yield put(commonActions.signOut.failure(error));
    yield showErrorSnackbar(error);
    serviceProvider.analyticsService.logError(e);
  }
});

function* deleteFcmToken() {
  yield put(notificationActions.deleteFcmToken.request());
  const result = yield takeAsyncActionResult(notificationActions.deleteFcmToken);
  if (result !== AsyncActionResult.SUCCESS) {
    throw new AsyncError(ErrorType.UNKNOWN);
  }
}

const createAnonymousUser = takeLeading(getType(commonActions.signInAnonymously), function* () {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService: IAuthService = yield serviceProvider.getAuthService();

  try {
    const loginResult: LoginResult = yield authService.signInAnonymously();
    serviceProvider.logger.setUser(loginResult.user.id);
    yield call(signUpUser, loginResult.idToken, loginResult.user);
  } catch (e) {
    serviceProvider.logger.logMessage(`Create new anonymous user failed: ${e.message}`);
    serviceProvider.logger.logError(e);
    yield authService.deleteCurrentUser();
    yield put(commonActions.signIn.failure(e));
  }
});

function* confirmDataLoss() {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  const hasUserCreatedData = yield serviceProvider.api.hasUserCreatedData();
  if (hasUserCreatedData) {
    return yield takeConfirmation({
      content: { key: 'sign_in.merge_confirmation' },
      positiveKey: 'actions.continue',
    });
  }
  return true;
}

function* signUpUser(authToken: string, user: IFirebaseUser) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    yield serviceProvider.api.createUser(authToken, {
      username: user.username,
      anonymous: user.anonymous,
      imageUrl: user.imageUrl,
      translations: [],
    });
    yield call(completeSignIn, user);
  } catch (e) {
    serviceProvider.logger.logMessage(`Failed signup user on server: ${e.message}`);
    throw e;
  }
}

function* showSignInSnackbar(user: IFirebaseUser) {
  if (!user.anonymous) {
    yield put(
      commonActions.showSnackbar({
        key: 'common.sign_in_completed',
        params: { name: user.username },
      })
    );
  }
}

function* failSignIn(e: IAsyncError) {
  yield put(commonActions.signIn.failure(e));
  yield showErrorSnackbar(e);
}

function* completeSignIn(user: IFirebaseUser) {
  yield put(commonActions.signIn.success(user));
}

export default [
  switchAccount,
  upgradeOrCreateAccount,
  signOut,
  clearUser,
  createAnonymousUser,
  // call(authSaga),
];
