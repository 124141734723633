import { FirebaseError } from 'firebase/app';
import { IPushMessagingService, NotificationOptions, NotificationPermission } from '@lialo/core/lib/services';
import * as serviceWorker from '../serviceWorkerRegistration';
import { AsyncError, ErrorType } from '@lialo/core/lib/common';
import config from '../config';

export default class PushMessagingService implements IPushMessagingService {
  private _lazyImport: Promise<typeof import('firebase/messaging')> | undefined;

  private async importFirebaseMessaging() {
    return await (this._lazyImport ?? (this._lazyImport = import('firebase/messaging')));
  }

  async isSupported(): Promise<boolean> {
    const { isSupported } = await this.importFirebaseMessaging();
    return isSupported();
  }

  getNotificationPermission(): NotificationPermission {
    switch (window.Notification.permission) {
      case 'default':
        return NotificationPermission.DEFAULT;
      case 'denied':
        return NotificationPermission.DENIED;
      case 'granted':
        return NotificationPermission.GRANTED;
    }
  }

  async getToken(): Promise<string> {
    const serviceWorkerRegistration = await serviceWorker.getRegistration();
    return this.runCatching(async () => {
      const { getMessaging, getToken } = await this.importFirebaseMessaging();
      return getToken(getMessaging(), { vapidKey: config.vapidKey, serviceWorkerRegistration });
    });
  }

  async registerMessageListener(handler: (payload: any) => void): Promise<VoidFunction> {
    return this.runCatching(async () => {
      const { getMessaging, onMessage } = await this.importFirebaseMessaging();
      return onMessage(getMessaging(), handler);
    });
  }

  async showNotification(title: string, options: NotificationOptions) {
    const serviceWorkerRegistration = await serviceWorker.getRegistration();
    return serviceWorkerRegistration?.showNotification(title, options);
  }

  private async runCatching<T>(fun: () => Promise<T>): Promise<T> {
    try {
      return await fun();
    } catch (e) {
      throw this.parseError(e);
    }
  }

  private parseError(e: Error): AsyncError {
    if (!(e instanceof FirebaseError))
      return new AsyncError(ErrorType.UNKNOWN, `Non-Firebase Error occured: ${e.message}`);

    switch (e.code) {
      case 'messaging/permission-default':
      case 'messaging/permission-blocked':
        return new AsyncError(ErrorType.FCM_PERMISSION_NOT_GIVEN, e.message);
      case 'messaging/unsupported-browser':
      case 'messaging/indexed-db-unsupported':
        return new AsyncError(ErrorType.FCM_UNSUPPORTED, e.message);
      case 'messaging/token-subscribe-failed':
      case 'messaging/token-subscribe-no-token':
      case 'messaging/token-update-failed':
      case 'messaging/token-update-no-token':
        return new AsyncError(ErrorType.FCM_TOKEN_SUBSCRIBE_FAILED, e.message);

      default:
        return new AsyncError(ErrorType.FCM_OTHER, e.message);
    }
  }
}
