import { createAction, createAsyncAction } from 'typesafe-actions';
import { AsyncError, IAppFeedback } from '../../common';

const sendFeedbackAsync = createAsyncAction(
  '@appFeedback/SEND_APPFEEDBACK_REQUEST',
  '@appFeedback/SEND_APPFEEDBACK_SUCCESS',
  '@appFeedback/SEND_APPFEEDBACK_FAILURE'
)<IAppFeedback, void, AsyncError>();

const reset = createAction('@appFeedback/RESET');

export default {
  sendFeedbackAsync,
  reset,
};
