import { ICreateTourState } from './models/state';
import { emptySettings, TourVisibility } from '../../models';
import ReviewStatus from '../../models/ReviewStatus';

export const whitelist: (keyof ICreateTourState)[] = ['tourState', 'tour'];

export const migrations: any = {
  '0': (state: ICreateTourState) => {
    return {
      ...state,
      tourState: {
        id: 0,
        stops: [],
        settings: emptySettings,
        isRoundTrip: true,
        images: [],
        visibility: TourVisibility.PRIVATE,
        reviewStatus: ReviewStatus.NONE,
      },
    };
  },
};
