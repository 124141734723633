import { getType } from 'typesafe-actions';
import actions from '../actions';
import { put, select, takeLatest } from 'redux-saga/effects';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { commonSelectors } from '../../common';
import { showErrorSnackbar } from '../../common/utils/showSnackbar';

const fetchCreatedToursSaga = takeLatest(getType(actions.fetchCreatedTours.request), function* () {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  const user = commonSelectors.selectUser(yield select());
  if (!user) {
    yield put(actions.fetchCreatedTours.cancel());
    return;
  }

  try {
    const tourSummaries = yield serviceProvider.api.fetchMyTourSummaries();
    yield put(actions.fetchCreatedTours.success(tourSummaries));
  } catch (e) {
    yield put(actions.fetchCreatedTours.failure(e));
    yield showErrorSnackbar(e);
    serviceProvider.analyticsService.logError(e);
  }
});

export default [fetchCreatedToursSaga];
