import { put, select, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { commonActions, commonSelectors } from '../../common';
import { takeConfirmation } from '../../utils/sagas';

const cancelTourSaga = takeLatest(getType(commonActions.cancelTour.request), function* (
  action: ReturnType<typeof commonActions.cancelTour.request>
) {
  const { tourCode, onConfirmed } = action.payload;

  const confirmed: boolean = yield takeConfirmation({
    content: {
      key: 'my_tours.cancel_confirmation_description',
    },
    positiveKey: 'my_tours.cancel_confirmation_confirm',
    negativeKey: 'my_tours.cancel_confirmation_reject',
  });

  if (!confirmed) return;

  yield put(commonActions.clearAssetCache(tourCode));

  onConfirmed && onConfirmed();

  const tourProgresses = commonSelectors.selectRunningTourProgresses(yield select());
  const stopIndex = tourProgresses[tourCode]?.playbook?.currentPage?.stopIndex ?? -1;
  yield put(commonActions.cancelTour.confirmed(tourCode, stopIndex + 1));
});

export default [cancelTourSaga];
