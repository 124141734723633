import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import selectors from '../selectors';
import { AsyncError } from '../../../common';
import routes from '../../utils/routes';
import { ICreateTourModel } from '../models/Tour';
import { commonActions } from '../../common';
import { showErrorSnackbar } from '../../common/utils/showSnackbar';

const translateTourSaga = takeLatest(getType(actions.translateTour.request), function* (
  action: ReturnType<typeof actions.translateTour.request>
) {
  const tourCode: string = yield select(selectors.selectTourCode);
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const api = serviceProvider.api;

  try {
    const tour: ICreateTourModel = yield call(api.translateTour.bind(api), tourCode, action.payload);
    yield put(actions.translateTour.success(tour));
    serviceProvider.navigationService.navigate(serviceProvider.navigationService.generateEditTourUrl(routes.CREATE_TOUR, tour.shortLink));
    yield put(
      commonActions.showSnackbar({
        key: 'create_tour.translate_tour.success_snackbar',
        params: { title: tour.title },
      })
    );
  } catch (e) {
    yield showErrorSnackbar(e);
    yield put(actions.translateTour.failure(e as AsyncError));
  }
});

const sagas = [translateTourSaga];
export default sagas;
