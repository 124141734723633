import { RootState } from '../../store/root';
import { createSelector } from 'reselect';
import { commonSelectors } from '../common';
import {
  getDirectionImages,
  getEndImages,
  getInformationImages,
  getStartImages,
  getTaskImages,
  ITour,
  ITourStop,
} from '../../models';
import { createQuizResponseVMsFromTourStop, IQuizResponseVM } from '../../common';

const selectIsLoading = (state: RootState) => state.tourContent.isLoading;
const selectError = (state: RootState) => state.tourContent.error;

const selectTourCode = (state: RootState, props: { tourCode: string }) => props.tourCode;
const selectTour = createSelector([commonSelectors.selectTours, selectTourCode], (tours, tourCode):
  | ITour
  | undefined => {
  return tours[tourCode];
});

const selectStopIndex = (state: RootState, props: { stopIndex: number }) => props.stopIndex;
const selectTourStop = createSelector(selectTour, selectStopIndex, (tour, stopIndex): ITourStop | undefined => {
  if (tour) {
    return tour.stops.sortBy('orderId', 'ASC')[stopIndex];
  }
});

const selectStartImages = createSelector(selectTour, tour => {
  return tour ? getStartImages(tour) : undefined;
});

const selectDirectionImages = createSelector(selectTourStop, stop => {
  return stop ? getDirectionImages(stop) : undefined;
});

const selectInformationImages = createSelector(selectTourStop, stop => {
  return stop ? getInformationImages(stop) : undefined;
});

const selectTaskImages = createSelector(selectTourStop, stop => {
  return stop ? getTaskImages(stop) : undefined;
});

const selectEndImages = createSelector(selectTour, tour => {
  return tour ? getEndImages(tour) : undefined;
});

const selectAnswers = createSelector(selectTourStop, (tourStop): IQuizResponseVM[] => {
  return tourStop ? createQuizResponseVMsFromTourStop(tourStop, true) : [];
});

export default {
  selectIsLoading,
  selectError,
  selectTour,
  selectTourStop,
  selectStartImages,
  selectDirectionImages,
  selectInformationImages,
  selectTaskImages,
  selectEndImages,
  selectAnswers,
};
