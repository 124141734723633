import firebaseConfig from './firebase-config.json';

const config = {
  appVersion: process.env.REACT_APP_VERSION ?? '',
  apiContractVersion: 13,
  projectId: process.env.REACT_APP_PROJECT_ID,
  stackdriverKey: process.env.REACT_APP_STACKDRIVER_KEY,
  googleApiKey: process.env.REACT_APP_MAPS_KEY,
  paypalBusinessId: process.env.REACT_APP_PAYPAL_BUSINESS_ID,
  vapidKey: process.env.REACT_APP_VAPID_KEY,
  usePaypalSandbox: process.env.REACT_APP_USE_PAYPAL_SANDBOX === 'true',
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:8080',
  adminUserId: process.env.REACT_APP_ADMIN_USER_ID,
  voucherGeneratorUrl: process.env.REACT_APP_VOUCHER_GENERATOR_URL,
  fallbackLocation: { lat: 51.3546428, lng: 10.2977335 },
  initialZoom: 6,
  cookiebotDeclarationScript: 'https://consent.cookiebot.com/11800066-f9a1-4df9-b5a1-3c359108dc58/cd.js',

  firebaseConfig,
};

export default config;
