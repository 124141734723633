import { call, put, race, take, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { TourCode } from '../../../models';
import { requireUserOrThrow } from '../../utils/sagas';

const fetchStatisticsSaga = takeLeading(getType(actions.fetchStatistics.request), function* (
  action: ReturnType<typeof actions.fetchStatistics.request>
) {
  yield race({
    statistics: call(fetchStatistics, action.payload),
    cancelled: take(actions.fetchStatistics.cancel),
  });
});

function* fetchStatistics(tourCode: TourCode) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    yield requireUserOrThrow();
    const statistics = yield serviceProvider.api.fetchTourStatistics(tourCode);
    yield put(actions.fetchStatistics.success(statistics));
  } catch (e) {
    yield put(actions.fetchStatistics.failure(e));
    serviceProvider.analyticsService.logError(e);
  }
}

export default [fetchStatisticsSaga];
