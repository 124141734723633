import { getType } from 'typesafe-actions';
import { put, select, takeEvery } from 'redux-saga/effects';
import actions from '../actions';
import selectors from '../selectors';
import { comparePlayProgress, getNextPlaybookPage, getPreviousPlaybookPage } from '../models';
import { generatePathFromPlaybookPage } from '../utils/routerUtils';
import { IPlaybookPage, ITour, ITourProgress } from '../../../models';
import { getServiceProvider, IServiceProvider } from '../../../services';

const navigateRequestSaga = takeEvery(getType(actions.navigatePage.request), function* (
  action: ReturnType<typeof actions.navigatePage.request>
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const { params, direction } = action.payload;

  const [tour, runningTour]: [ITour, ITourProgress] = yield select(selectors.selectTourAndTourProgress, params);
  const requestedPage: IPlaybookPage = yield select(selectors.selectPlaybookPage, params);

  const page =
    direction === 'next'
      ? getNextPlaybookPage(tour.stops, requestedPage)
      : getPreviousPlaybookPage(tour.stops, requestedPage);
  if (page && runningTour?.playbook) {
    if (comparePlayProgress(page, runningTour.playbook.currentPage) > 0) {
      yield put(actions.navigatePage.update(tour.shortLink!, page));
    }
    const path = generatePathFromPlaybookPage(serviceProvider.navigationService, tour.shortLink!, page);
    serviceProvider.navigationService.navigate(path);
  }
});

export default [navigateRequestSaga];
