import { actionChannel, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import selectors from '../selectors';
import { commonActions, commonSelectors } from '../../common';
import { ITourEvent, TourEventType } from '../../../models';
import { buffers } from 'redux-saga';
import { getServiceProvider, IServiceProvider } from '../../../services';

const logTourStart = takeEvery(getType(actions.openPlaybook), function* (
  action: ReturnType<typeof actions.openPlaybook>
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  const tour = commonSelectors.selectTourWithTourCode(yield select(), action.payload.tourCode);
  const tourProgress = selectors.selectRunningTourProgress(yield select(), { tour: action.payload.tourCode });
  // ignore preview sessions
  if (tourProgress) {
    serviceProvider.analyticsService.logPlayStart(
      action.payload.tourCode,
      tour?.user?.id,
      tour?.startLocation?.city,
      tour?.price
    );
    yield put(actions.tourEvents.log(action.payload.tourCode, TourEventType.started));
  }
});

const logTourCompletion = takeEvery(getType(actions.completeTour.success), function* (
  action: ReturnType<typeof actions.completeTour.success>
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  const tourProgress = selectors.selectTourProgress(yield select(), { tour: action.payload });
  const tour = commonSelectors.selectTourWithTourCode(yield select(), action.payload);

  let playDuration: number | undefined = undefined;
  if (tourProgress?.startedAt && tourProgress?.completedAt) {
    playDuration = Math.round(
      (tourProgress!.completedAt!.getTime() - tourProgress!.startedAt!.getTime()) / (60 * 1000)
    );
  }

  serviceProvider.analyticsService.logPlayComplete(
    action.payload,
    playDuration,
    tour?.settings?.duration ?? undefined,
    tour?.user?.id,
    tour?.startLocation?.city,
    tour?.price,
    tour?.enableDonation
  );
  yield put(actions.tourEvents.log(action.payload, TourEventType.completed));
});

const logTourCancellation = takeEvery(getType(commonActions.cancelTour.confirmed), function* (
  action: ReturnType<typeof commonActions.cancelTour.confirmed>
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const tour = commonSelectors.selectTourWithTourCode(yield select(), action.payload);
  serviceProvider.analyticsService.logPlayCancel(
    action.payload,
    action.meta?.tourStop,
    tour?.user?.id,
    tour?.startLocation?.city,
    tour?.price
  );
  yield put(actions.tourEvents.log(action.payload, TourEventType.cancelled));
});

const syncTourEvents = function* () {
  const requestChannel = yield actionChannel(
    [getType(actions.tourEvents.log), getType(commonActions.connection.online), getType(commonActions.signIn.success)],
    buffers.sliding(1)
  ); // discard duplicate actions, because all events are synced at once
  while (true) {
    yield take(requestChannel);
    const serviceProvider: IServiceProvider = yield getServiceProvider();

    const tourEvents: Record<number, ITourEvent> = yield select(selectors.selectTourEvents);
    const eventList = Object.values(tourEvents);

    if (eventList.length > 0) {
      try {
        // don't capture events for non-paying users
        if (!commonSelectors.selectCanPlayForFree(yield select())) {
          yield serviceProvider.api.logTourEvents(Object.values(eventList));
        }
        yield put(actions.tourEvents.syncSuccess(Object.keys(tourEvents).map(k => parseInt(k, 10))));
      } catch (e) {
        // ignore errors, will retry later
      }
    }
  }
};

export default [logTourStart, logTourCompletion, logTourCancellation, call(syncTourEvents)];
