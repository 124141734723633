import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { IAsyncActionState, reduceFailureAction, reduceRequestAction } from '../utils';
import { RootAction } from '../../store/root';
import actions from './actions';
import { commonActions } from '../common';
import { ITourSummary } from '../../models';
import { LandingPageType } from './models';

export interface ILandingPageState extends IAsyncActionState {
  tours: Partial<Record<LandingPageType, ITourSummary[]>>;
}

const defaultState: ILandingPageState = {
  isLoading: false,
  tours: {},
};

const landingPageReducer = createReducer<ILandingPageState, RootAction>(defaultState)
  .handleAction(actions.loadTours.request, reduceRequestAction)
  .handleAction(actions.loadTours.failure, reduceFailureAction)
  .handleAction(actions.loadTours.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.tours[action.payload.query] = action.payload.tours;
    })
  )
  .handleAction(commonActions.signOut.success, (state, action) => ({
    ...defaultState,
  }));

export default landingPageReducer;
