import React from 'react';
import { AddToHomeScreenOutlined } from '@mui/icons-material';
import { InstallationPrompt } from '../InstallationPrompt';
import DrawerListItem from './DrawerListItem';
import { useTranslation } from 'react-i18next';

interface IProps {
  onItemClicked: () => void;
}

const InstallationListItem = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <InstallationPrompt>
      <DrawerListItem
        component={{ type: 'button' }}
        key={'install'}
        icon={AddToHomeScreenOutlined}
        title={t('drawer.install_pwa_list_item')}
        onClick={props.onItemClicked}
      />
    </InstallationPrompt>
  );
};

export default InstallationListItem;
