import { ILogger } from '@lialo/core/lib/services/analytics/logger';
import * as Sentry from '@sentry/react';
import config from '../config';

const enabled = config.projectId === 'lialo-com';
export default class SentryLogger implements ILogger {
  constructor() {
    Sentry.init({
      dsn: enabled ? 'https://112f14050ed5440bb56cf65b4ff0ccd6@o511146.ingest.sentry.io/5607791' : '',
      release: config.appVersion,
    });

    Sentry.setUser({
      ip_address: '{{auto}}',
    });
  }

  logMessage(message: string) {
    Sentry.captureMessage(message);
  }

  setUser(userId: string) {
    Sentry.setUser({
      id: userId,
    });
  }

  logError(error: Error) {
    Sentry.captureException(error);
  }
}
