import routes from '../../utils/routes';
import { matchPath } from 'react-router';
import { Chapter, IPlaybookPage, StopSection } from '../../../models';
import { getPathFromUrl } from '../../../utils/helper/url';
import { INavigationService, To } from '../../../services';

type TourAndPlaybookPage = {
  tourCode: string;
  playbookPage: IPlaybookPage;
};

type PlayTourPathParams = {
  tour: string;
  tourSection?: string;
  stopIndex?: string;
  stopSection?: string;
};

export type PlayTourParams = PlayTourPathParams;

export function matchPlaybookPath(path: string): PlayTourParams | undefined {
  const match = matchPath<PlayTourPathParams>(getPathFromUrl(path), routes.PLAYBOOK);
  if (match) {
    return match.params;
  }
}

export function parsePlayTourUrl(params: PlayTourParams): TourAndPlaybookPage {
  return {
    tourCode: params.tour.toLowerCase(),
    playbookPage: {
      chapter: params.tourSection,
      stopIndex: params.stopIndex ? parseInt(params.stopIndex) - 1 : undefined, //TODO: What if non-numberic is passed?
      stopSection: params.stopSection,
    } as IPlaybookPage,
  };
}

export function generatePathFromPlaybookPage(
  navigationService: INavigationService,
  tourCode: string,
  page: IPlaybookPage
): To {
  switch (page.chapter) {
    case Chapter.start:
      return navigationService.generatePlayTourUrl(routes.PLAY_TOUR_START, tourCode);
    case Chapter.end:
      return navigationService.generatePlayTourUrl(routes.PLAY_TOUR_END, tourCode);
    case Chapter.stop:
      const stopIndex = page.stopIndex + 1;
      switch (page.stopSection) {
        case StopSection.directions:
          return navigationService.generatePlayTourStopUrl(routes.PLAY_TOUR_DIRECTIONS, tourCode, stopIndex);
        case StopSection.task:
          return navigationService.generatePlayTourStopUrl(routes.PLAY_TOUR_TASK, tourCode, stopIndex);
        case StopSection.information:
          return navigationService.generatePlayTourStopUrl(routes.PLAY_TOUR_INFORMATION, tourCode, stopIndex);
      }

      return navigationService.generatePlayTourUrl(routes.PLAY_TOUR_START, tourCode);
  }
}
