import {
  Distance,
  Duration,
  IAverageRating,
  IFirebaseUser,
  IProfileTourSummary,
  TourStopProgress,
  TourVisibility,
} from '../../models';
import { formatDate } from '../../utils/formatter/dateFormatter';
import { Language } from '@lialo/common/lib/language';

export enum ProfileTourCardType {
  COMPLETED,
  CREATED,
}

export interface IProfileTourCardVM {
  tourCode: string;
  date: string;
  title: string;
  imageUrl?: string;
  description: string;
  duration?: Duration;
  distance?: Distance;
  isUnavailable: boolean;
  playAtHome: boolean;
  visibility: TourVisibility;
  showVisibilityIcon: boolean;
  user: IFirebaseUser;
  averageRating?: IAverageRating;
  price?: string;
  purchased: boolean;
  language: Language;
}

export function convertToProfileTourCardVM(
  profileTourSummary: IProfileTourSummary,
  type: ProfileTourCardType
): IProfileTourCardVM {
  function displayDate(): string {
    const date = type === ProfileTourCardType.COMPLETED ? profileTourSummary.startedAt : profileTourSummary.createdAt;
    return date ? formatDate(date) : '';
  }

  return {
    tourCode: profileTourSummary.shortLink,
    date: displayDate(),
    title: profileTourSummary.title,
    imageUrl: profileTourSummary.imageUrl,
    description: profileTourSummary.description,
    duration: profileTourSummary.duration,
    distance: profileTourSummary.distance,
    isUnavailable: false,
    playAtHome: profileTourSummary.playAtHome,
    visibility: TourVisibility.PUBLIC,
    showVisibilityIcon: false,
    averageRating: profileTourSummary.averageRating,
    user: profileTourSummary.user,
    price: profileTourSummary.price,
    purchased: false,
    language: profileTourSummary.language,
  };
}
