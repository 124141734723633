import { createAction, createAsyncAction } from 'typesafe-actions';
import { ISimpleLocation, ITourSummary } from '../../models';
import { IAsyncError, IInitialBounds } from '../../common';
import { NearbyToursRequest } from './utils/requests';

type FetchInitialBoundsRequest = {
  onSuccess: (initialBounds: IInitialBounds) => void;
};

const fetchInitialBounds = createAsyncAction(
  '@findTour/FETCH_INITIAL_BOUNDS_REQUEST',
  '@findTour/FETCH_INITIAL_BOUNDS_SUCCESS',
  '@findTour/FETCH_INITIAL_BOUNDS_FAILURE'
)<FetchInitialBoundsRequest, void, IAsyncError>();

const selectTour = createAction('@findTour/selectTour', action => {
  return (tourCode?: string) => action(tourCode);
});

const fetchNearbyTours = {
  request: createAction('@findTour/FETCH_NEARBY_TOURS_REQUEST', action => {
    return (request: NearbyToursRequest) => action(request);
  }),
  start: createAction('@findTour/FETCH_NEARBY_TOURS_START', action => () => action({})),
  success: createAction('@findTour/FETCH_NEARBY_TOURS_SUCCESS', action => {
    return (tours: ITourSummary[]) => action(tours);
  }),
  failure: createAction('@findTour/FETCH_NEARBY_TOURS_FAILURE', action => {
    return (error: IAsyncError) => action(error);
  }),
};

const toggleFilter = createAction('@findTour/TOGGLE_FILTER', action => {
  return (key: string, value: any) => action({ key, value });
});

const dismissDialog = createAction('@findTour/DISMISS_DIALOG');
const applyFilters = createAction('@findTour/APPLY_FILTERS');
const resetFilters = createAction('@findTour/RESET_FILTERS', action => (apply = false) => action(undefined, { apply }));

const hydrateFilters = createAction('@findTour/HYDRATE_FILTERS', action => {
  return (params: Record<string, any>) => action(params);
});

const toggleList = createAction('@findTour/TOGGLE_LIST');

const subscribeLocation = createAction(
  '@findTour/SUBSCRIBE_LOCATION',
  action => (location: ISimpleLocation, showDialog: boolean) => action({ location, showDialog })
);

export default {
  fetchNearbyTours,
  selectTour,
  toggleFilter,
  dismissDialog,
  applyFilters,
  resetFilters,
  hydrateFilters,
  fetchInitialBounds,
  toggleList,
  subscribeLocation,
};
