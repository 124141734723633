import React from 'react';

export function isMobileSafari(): boolean {
  const userAgent = window.navigator.userAgent;
  const iOS =
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (userAgent.includes('Mac') && 'ontouchend' in document);
  const webkit = !!userAgent.match(/WebKit/i);
  return iOS && webkit && !userAgent.match(/CriOS/i);
}

export function isIOSApp(): boolean {
  const userAgent = window.navigator.userAgent;
  const iOSApp = userAgent.includes('lialo-iOS');
  return iOSApp;
}

export function isAndroidTWA(): boolean {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const utm_source = urlParams.get('utm_source');
  return utm_source === 'trusted-web-activity';
}

export function isInstalled(): boolean {
  return window.matchMedia('(display-mode: standalone)').matches;
}

export function useIsNotIOSApp(): boolean {
  const ref = React.useRef(!isIOSApp());
  return ref.current;
}
