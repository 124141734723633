export function cropAvatarImage(link: string, width: number) {
  if (link.includes('googleusercontent.com')) {
    // link might be already a cropped link
    if (link.includes('=')) return link;
    //https://issuetracker.google.com/issues/35888709#c18
    return link + `=w${width}-rw`;
  }
  return link;
}

const imageSizes = [208, 384, 624, 854, 1024, 1920];

const CEIL_THRESHOLD = 0.65;

export function chooseTourImageLink(url: string, width: number): string {
  if (!url.startsWith('https://storage.googleapis.com/')) {
    return url;
  }

  const idxLarger = imageSizes.findIndex(it => it >= width) ?? imageSizes.length - 1;
  const idxSmaller = idxLarger > 0 ? idxLarger - 1 : idxLarger;

  const threshold = (imageSizes[idxLarger] - imageSizes[idxSmaller]) * CEIL_THRESHOLD + imageSizes[idxSmaller];
  const w = width >= threshold ? imageSizes[idxLarger] : imageSizes[idxSmaller];

  const path = url.substring(0, url.lastIndexOf('.'));
  return `${path}@w${w}.webp`;
}
