import { all, call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { getType, PayloadMetaAction, TypeConstant } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import selectors from '../selectors';
import { ITourProgress, TourCode } from '../../../models';
import { commonActions } from '../../common';
import { requireUserOrThrow } from '../../utils/sagas';

const saveTourProgressSaga = takeLatest(
  [
    getType(actions.saveTourProgress.request),
    getType(commonActions.cancelTour.confirmed),
    getType(actions.completeTour.success),
  ],
  function* (action: PayloadMetaAction<TypeConstant, TourCode, void>) {
    const tourCode = action.payload;

    const tourProgress: ITourProgress | undefined = yield select(selectors.selectTourProgressByTourCode, {
      tour: tourCode,
    });
    if (tourProgress) {
      const serviceProvider: IServiceProvider = yield getServiceProvider();
      try {
        yield requireUserOrThrow();
        const savedTourProgress = yield serviceProvider.api.saveTourProgress(tourProgress);
        yield put(actions.saveTourProgress.success(savedTourProgress));
      } catch (e) {
        yield put(actions.saveTourProgress.failure(e));
      }
    } else {
      yield put(actions.saveTourProgress.cancel());
    }
  }
);

const triggerTourProgressSync = takeEvery(
  [getType(commonActions.connection.online), getType(commonActions.signIn.success)],
  function* () {
    yield put(commonActions.synchronizeTourProgress.request());
  }
);

const synchronizeTourProgressesSaga = takeLeading(
  [getType(commonActions.synchronizeTourProgress.request)],
  function* () {
    const serviceProvider: IServiceProvider = yield getServiceProvider();

    function* saveTourProgress(tp: ITourProgress) {
      yield serviceProvider.api.saveTourProgress(tp);
      yield put(actions.saveTourProgress.success(tp));
    }

    const unsynchronizedTourProgresses: ITourProgress[] = yield select(selectors.selectUnsynchronizedTourProgresses);
    const pendingSaves = unsynchronizedTourProgresses.map(tourProcess => call(saveTourProgress, tourProcess));
    try {
      yield all(pendingSaves);
      const tourProgresses = yield serviceProvider.api.fetchTourProgresses();
      yield put(commonActions.synchronizeTourProgress.success(tourProgresses));
    } catch (e) {
      yield put(commonActions.synchronizeTourProgress.failure(e));
    }
  }
);

export default [triggerTourProgressSync, saveTourProgressSaga, synchronizeTourProgressesSaga];
