import { RootState } from '../../store/root';
import { Language } from '@lialo/common/lib/language';
import {
  IFirebaseUser,
  ITour,
  ITourProgress,
  selectImageUrlsFromTour,
  TourCode,
  TourProgressStatus,
} from '../../models';
import { createSelector } from 'reselect';
import { filterRecord, mapObjectValues } from '../../utils/helper/object';
import { Authority, hasAuthority } from '../../common';

const EMAIL_PROVIDER_ID = 'password';
const selectDialogConfig = (state: RootState) => state.common.dialogs[0];
const selectSnackbarConfig = (state: RootState) => state.common.snackbars[0];
const selectLanguage = (state: RootState): Language => Language.de;
const selectUser = (state: RootState): IFirebaseUser | undefined => state.app.user;
const selectAuthorities = (state: RootState): string[] => state.app.authorities;
const selectCanPlayForFree = createSelector(selectAuthorities, authorities =>
  hasAuthority(authorities, Authority.PLAY_FOR_FREE)
);
const selectAllowedLocationTracking = (state: RootState) => state.app.allowedLocationTracking;
const selectTours = (state: RootState): Record<TourCode, ITour> => state.app.offlineTours ?? {};
const selectTourWithId = (state: RootState, id: number) => Object.values(state.app.offlineTours).find(t => t.id === id);
const selectTourWithTourCode = (state: RootState, tourCode: string): ITour | undefined =>
  state.app.offlineTours[tourCode];
const selectHasCompletedCreateTourOnboarding = (state: RootState) => state.app.hasCompletedCreateTourOnboarding;

const selectIsRegisteredWithEmail = createSelector(selectUser, user => user?.providerId === EMAIL_PROVIDER_ID ?? false);
const selectIsRegisteredWithApple = createSelector(selectUser, user => user?.providerId === 'apple.com' ?? false);

const selectTourImageUrls = createSelector(selectTourWithTourCode, (tour): string[] => {
  if (tour) {
    return selectImageUrlsFromTour(tour);
  }
  return [];
});

const selectLastSynchronizationAt = (state: RootState) => state.playTour.lastSynchronizationAt;

const selectTourProgresses = (state: RootState): Record<TourCode, ITourProgress> => state.playTour.tourProgresses;

const selectPurchasedToursMap = createSelector(selectTourProgresses, progresses =>
  mapObjectValues(progresses, progress => progress.purchased === true)
);

const selectRunningTourProgresses = createSelector(selectTourProgresses, progresses =>
  filterRecord(progresses, tour => tour.status === TourProgressStatus.running)
);

const selectCompletedTourProgresses = createSelector(selectTourProgresses, tours =>
  filterRecord(tours, tour => tour.completedOnce === true && tour.status !== TourProgressStatus.running)
);

const selectIsRegisteredUser = createSelector(selectUser, user => user?.anonymous === false);

const selectIsReauthenticationRequested = (state: RootState) => state.common.reauthenticationRequested;

const selectIsReauthenticating = (state: RootState) => state.common.isReauthenticating;

const selectReauthenticationError = (state: RootState) => state.common.reauthenticationError;

const selectIsSendingPasswordEmail = (state: RootState) => state.common.isSendingPasswordEmail;

const selectHasSentPasswordEmail = (state: RootState) => state.common.hasSentPasswordEmail;

const selectPasswordEmailError = (state: RootState) => state.common.passwordEmailError;

const selectIsSendingReview = (state: RootState) => state.playTour.isSendingReview;

const selectSendReviewError = (state: RootState) => state.playTour.sendReviewError;

export default {
  selectDialogConfig,
  selectSnackbarConfig,
  selectLanguage,
  selectUser,
  selectAuthorities,
  selectAllowedLocationTracking,
  selectCanPlayForFree,
  selectTours,
  selectTourWithId,
  selectTourWithTourCode,
  selectTourImageUrls,
  selectTourProgresses,
  selectLastSynchronizationAt,
  selectRunningTourProgresses,
  selectCompletedTourProgresses,
  selectPurchasedToursMap,
  selectIsRegisteredUser,
  selectIsReauthenticationRequested,
  selectIsReauthenticating,
  selectReauthenticationError,
  selectIsSendingPasswordEmail,
  selectHasSentPasswordEmail,
  selectPasswordEmailError,
  selectIsRegisteredWithEmail,
  selectIsRegisteredWithApple,
  selectHasCompletedCreateTourOnboarding,
  selectIsSendingReview,
  selectSendReviewError,
};
