import { Task } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { cancel, fork, put, select, take, takeLatest, takeLeading } from 'redux-saga/effects';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { showErrorSnackbar } from '../../common/utils/showSnackbar';
import selectors from '../selectors';
import { hasSignificantlyChanged, NearbyToursRequest } from '../utils/requests';
import { AsyncError, ErrorType, IInitialBounds } from '../../../common';
import { commonActions } from '../../common';
import { notificationSelectors } from '../../notifications';
import { Language } from '@lialo/common/lib/language';

const findToursSaga = takeLatest(
  [
    // reset saga when user changes
    getType(commonActions.initApp),
    getType(commonActions.signIn.success),
    getType(commonActions.signOut.success),
  ],
  dispatchTourSearchSaga
);

function* dispatchTourSearchSaga() {
  let lastRequest: NearbyToursRequest | undefined = undefined;
  let lastTask: Task | undefined = undefined;
  while (true) {
    const action: ReturnType<typeof actions.fetchNearbyTours.request> = yield take(
      getType(actions.fetchNearbyTours.request)
    );
    // cancel if notification-preference-dialog is open
    if (notificationSelectors.selectEditNotificationPreferences(yield select()).isDialogOpen) {
      continue;
    }

    const filter = yield select(selectors.selectAppliedFilters);
    const request: NearbyToursRequest = {
      ...action.payload,
      filter,
    };

    if (lastRequest === undefined || hasSignificantlyChanged(request, lastRequest)) {
      if (lastTask) {
        yield cancel(lastTask);
      }
      lastTask = yield fork(fetchNearbyTours, request);
      lastRequest = request;
    }
  }
}

function* fetchNearbyTours(request: NearbyToursRequest) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    yield put(actions.fetchNearbyTours.start());
    const data = yield serviceProvider.api.filterTours(request.uiLang, request.filter, request.searchArea);

    if (data.length === 0) {
      throw new AsyncError(ErrorType.ZERO_RESULTS);
    }

    yield put(actions.fetchNearbyTours.success(data));
  } catch (e) {
    yield put(actions.fetchNearbyTours.failure(e));
    if (e.errorCode !== ErrorType.ZERO_RESULTS) {
      yield showErrorSnackbar(e);
    }
    serviceProvider.analyticsService.logError(e);
  }
}

const fetchInitialBoundsSaga = takeLeading(getType(actions.fetchInitialBounds.request), function* (
  action: ReturnType<typeof actions.fetchInitialBounds.request>
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  try {
    const initialBounds: IInitialBounds = yield serviceProvider.api.fetchInitialBounds();
    yield put(actions.fetchInitialBounds.success());
    action.payload.onSuccess(initialBounds);
  } catch (e) {
    yield put(actions.fetchInitialBounds.failure(e));
  }
});

export default [findToursSaga, fetchInitialBoundsSaga];
