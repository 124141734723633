import React from 'react';
import { styled } from '@mui/material/styles';
import { Avatar, InputAdornment, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { Check } from '@mui/icons-material';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.valid,
  width: 20,
  height: 20,
}));

const StyledCheck = styled(Check)(() => ({
  fontSize: '0.9em',
}));

interface IProps {
  isValid?: boolean;
}

export type TextFieldWithCheckmarkProps = IProps & TextFieldProps;

export const TextFieldWithCheckmark = (props: TextFieldWithCheckmarkProps) => {
  const { InputProps, isValid, ...other } = props;

  return (
    <TextField
      InputProps={{
        endAdornment: isValid && (
          <InputAdornment position="end">
            <StyledAvatar>
              <StyledCheck />
            </StyledAvatar>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      autoFocus={!props.value}
      {...other}
    />
  );
};
