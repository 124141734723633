import { countTasks } from './Tour';
import { Duration } from './TourSettings';

export interface ITourProgress {
  status: TourProgressStatus;
  startedAt: Date;
  completedAt?: Date;
  shortLink: TourCode;
  title: string;
  mainImageUrl: string;
  playbook?: IPlaybook;
  completedOnce?: boolean;
  isSyncPending?: boolean;
  didRateTour?: boolean;
  purchased?: boolean;
  score?: number;
}

export type TourCode = string;

export enum TourProgressStatus {
  completed = 'COMPLETED',
  cancelled = 'CANCELLED',
  running = 'RUNNING',
}

export enum Chapter {
  start = 'start',
  stop = 'stop',
  end = 'end',
}

export enum StopSection {
  directions = 'directions',
  task = 'task',
  information = 'information',
}

export type IPlaybookPage =
  | {
      chapter: Chapter.start | Chapter.end;
      stopIndex?: never;
      stopSection?: never;
    }
  | {
      chapter: Chapter.stop;
      stopIndex: number;
      stopSection: StopSection.directions | StopSection.task | StopSection.information;
    };

export interface IQuizResponse {
  answer?: string | boolean | number;
  attempts: number;
}

export interface IStaticMapSize {
  width: number;
  devicePixelRatio: number;
}

export interface IPlaybook {
  imageWidth?: number;
  staticMapSize?: IStaticMapSize;
  currentPage: IPlaybookPage;
  quizResponses: Record<number, IQuizResponse>;
}

export type TourStopProgress = {
  current?: number;
  total: number;
};

export function calculateTourStopProgress(totalNumberOfStops: number, page?: IPlaybookPage): TourStopProgress {
  if (!page) {
    return {
      total: totalNumberOfStops,
    };
  }
  return {
    current: calculateCurrentStopIndex(page, totalNumberOfStops),
    total: totalNumberOfStops,
  };
}

export function calculateTaskProgress(stops: Array<{ task?: any }>, page?: IPlaybookPage): TourStopProgress {
  const numberOfTasks = countTasks(stops);

  if (!page) {
    return {
      total: numberOfTasks,
    };
  }

  const currentStopIndex = calculateCurrentStopIndex(page, stops.length);
  return {
    current: countTasks(stops.slice(0, currentStopIndex)),
    total: numberOfTasks,
  };
}

export function calculateCurrentStopIndex(page: IPlaybookPage, totalNumberOfStops: number) {
  return page.chapter === Chapter.start ? 0 : page.chapter === Chapter.end ? totalNumberOfStops : page.stopIndex! + 1;
}

export function isMinPlayDurationFulfilled(tourProgress: ITourProgress, duration?: Duration): boolean {
  const startDate = tourProgress.startedAt;
  const completedAt = tourProgress?.completedAt;
  if (!completedAt) return false;

  const minDuration = calculateMinRequiredDurationInMillis(duration);
  const actualDuration = completedAt.getTime() - startDate.getTime();

  return actualDuration >= minDuration;
}

function calculateMinRequiredDurationInMillis(tourDuration: Duration | null | undefined): number {
  const minutesToMillis = (min: number) => min * 60 * 1000;

  switch (tourDuration) {
    case 'LONG':
      return minutesToMillis(180);
    case 'MEDIUM':
      return minutesToMillis(90);
    default:
      return minutesToMillis(30);
  }
}
