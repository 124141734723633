import { useLocation, useRouteMatch } from 'react-router-dom';
import routes from '@lialo/core/lib/pages/utils/routes';
import React from 'react';
import { useServiceProvider } from '../../common/Providers';
import { useDispatch, useSelector } from 'react-redux';
import { playTourActions, playTourSelectors } from '@lialo/core/lib/pages/playTour';

/**
 * Resumes the latest played tour, when user enters the app without through root page and without any query params.
 * Otherwise, clears the latest played tour.
 */
export function useResumeTour() {
  const dispatch = useDispatch();
  const { navigationService } = useServiceProvider();
  const location = useLocation();

  const currentlyPlayedTour = useSelector(playTourSelectors.selectCurrentlyPlayedTour);
  const i18nRootMatch = useRouteMatch(routes.ROOT);

  const isOnRoot =
    (location.pathname === '/' || i18nRootMatch?.isExact) &&
    (location.search === '' || location.search === '?utm_source=trusted-web-activity');

  React.useEffect(() => {
    if (currentlyPlayedTour && isOnRoot) {
      console.log('navigate to tour');
      navigationService.navigate(navigationService.generatePlayTourUrl(routes.PLAY_TOUR, currentlyPlayedTour));
    } else {
      dispatch(playTourActions.clearCurrentlyPlayedTour());
    }
    // we just want the effect to be executed once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationService]);
}
