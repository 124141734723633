export const theme = {
  palette: {
    primary: {
      main: '#427996',
      dark: '#2B4D5F',
    },
    secondary: {
      main: '#ffcf02',
      dark: '#fec351',
    },
    valid: '#5FCC12',
    white: {
      main: '#fff',
    },
    other: {
      mint: '#50E3AC',
      orange: '#E37350',
      blue: '#1B73E9',
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Segoe UI"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
};
