import { RootState } from '../../store/root';
import { createSelector } from 'reselect';
import {
  AvailableFilters,
  countAppliedFilter,
  hasAppliedFilters,
  ITourSummaryVM,
  TourMarkerType,
  ViewMode,
} from './models';
import { ITourSummary } from '../../models';
import { commonSelectors } from '../common';
import { ITourFilter } from '../../common';
import { BooleanPropertyKeys, NonBooleanPropertyKeys } from '../../utils/types/utilTypes';

const selectIsLoading = (state: RootState) => state.findTour.isLoading;
const selectError = (state: RootState) => state.findTour.error;

const selectTours = (state: RootState): Record<string, ITourSummary> => state.findTour.tours;

const selectSelectedTourCode = (state: RootState) => state.findTour.selectedTour;

const selectTourList = createSelector(
  [
    selectTours,
    selectSelectedTourCode,
    commonSelectors.selectRunningTourProgresses,
    commonSelectors.selectCompletedTourProgresses,
    commonSelectors.selectPurchasedToursMap,
  ],
  (toursPreview, tourCode, runningTours, completedTours, purchasedToursMap): ITourSummaryVM[] => {
    return Object.values(toursPreview).map(tourSummary => {
      const runningTour = runningTours[tourSummary.shortLink];
      const currentPage = runningTour?.playbook?.currentPage;
      const isCompleted = Object.keys(completedTours).includes(tourSummary.shortLink);
      const status = currentPage
        ? TourMarkerType.RUNNING
        : isCompleted
        ? TourMarkerType.COMPLETED
        : TourMarkerType.DEFAULT;
      return {
        ...tourSummary,
        status,
        isSelected: tourSummary.shortLink === tourCode,
        purchased: tourCode ? !!purchasedToursMap[tourCode] : false,
      };
    });
  }
);

const selectSelectedTour = createSelector(selectTours, selectSelectedTourCode, (tours, code) =>
  code ? tours[code] : undefined
);

const selectCurrentFilters = (state: RootState) => state.findTour.currentFilters;

const selectAppliedFilters = (state: RootState) => state.findTour.appliedFilters;

const selectFilterCount = createSelector(selectAppliedFilters, selectTours, (filters, tours) => {
  if (hasAppliedFilters(filters)) {
    return countAppliedFilter(filters);
  }
  return 0;
});

const selectChipFilter = (key: NonBooleanPropertyKeys<ITourFilter>) =>
  createSelector(selectCurrentFilters, (filter): { all: string[]; selected: string | string[] } => {
    const selected = filter[key];
    return {
      all: AvailableFilters[key as keyof typeof AvailableFilters],
      selected: selected && typeof selected !== 'boolean' ? selected : [],
    };
  });

const selectBooleanFilter = (key: BooleanPropertyKeys<ITourFilter>) =>
  createSelector(selectCurrentFilters, filter => filter[key]);

const selectViewMode = (state: RootState) => {
  if (state.findTour.isListOpen) {
    return ViewMode.LIST;
  } else if (state.findTour.selectedTour) {
    return ViewMode.SLIDER;
  } else {
    return undefined;
  }
};

export default {
  selectIsLoading,
  selectError,
  selectTourList,
  selectSelectedTourCode,
  selectSelectedTour,
  selectAppliedFilters,
  selectChipFilter,
  selectBooleanFilter,
  selectFilterCount,
  selectViewMode,
};
