import { IAnalyticsService } from '@lialo/core/lib/services';
import {
  Analytics,
  AnalyticsCallOptions,
  getAnalytics,
  isSupported,
  logEvent,
  setAnalyticsCollectionEnabled,
  setCurrentScreen,
  setUserProperties,
} from 'firebase/analytics';
import { AsyncError } from '@lialo/core/lib/common';
import config from '../config';
import { Duration } from '@lialo/core/lib/models';
import { isAndroidTWA, isIOSApp } from './utils';

const APP_NAME = isIOSApp() ? 'lialo_ios' : isAndroidTWA() ? 'lialo_android' : 'lialo';

export default class AnalyticsService implements IAnalyticsService {
  private analytics: Promise<Analytics | null>;

  constructor() {
    this.analytics =
      APP_NAME === 'lialo_ios'
        ? Promise.resolve(null) // no tracking on iOS-app
        : isSupported()
            .then(isSupported => {
              return isSupported ? getAnalytics() : null;
            })
            .catch(error => {
              return null;
            });
  }

  setUserSignedIn(signedIn: boolean): void {
    this.analytics.then(analytics => {
      analytics &&
        setUserProperties(analytics, {
          signedIn,
        });
    });
  }

  setLoggingEnabled(enabled: boolean) {
    this.analytics.then(analytics => {
      analytics && setAnalyticsCollectionEnabled(analytics, enabled);
    });
  }

  logScreenView(name: string): void {
    this.analytics.then(analytics => {
      analytics && setCurrentScreen(analytics, name);
      this.logEvent('screen_view');
    });
  }

  logAddToHomescreen(): void {
    this.logEvent('app_AddToHomeScreen');
  }

  logDeleteProfile(): void {
    this.logEvent('profile_delete');
  }

  logLocatePosition(): void {
    this.logEvent('search_locatePosition');
  }

  logPlayCancel(
    tourCode: string,
    tourStop: number | undefined,
    authorId?: string,
    tourCity?: string,
    price?: string
  ): void {
    this.logEvent('play_cancel', {
      tourId: tourCode,
      tourStop,
      authorUserId: authorId,
      tourCity,
      price: this.handlePrice(price),
    });
  }

  logPlayComplete(
    tourCode: string,
    progressTime?: number,
    duration?: Duration,
    authorId?: string,
    tourCity?: string,
    price?: string,
    donationable?: boolean
  ): void {
    this.logEvent('play_complete', {
      tourId: tourCode,
      authorUserId: authorId,
      tourCity,
      progressTime,
      duration,
      price: this.handlePrice(price),
      donationable,
    });
  }

  logPlayGiveAnswer(
    tourCode: string,
    tourStop: number,
    answer: 'correct' | 'wrong' | 'skipped',
    authorId?: string,
    tourCity?: string,
    price?: string
  ): void {
    this.logEvent('play_answer', {
      tourId: tourCode,
      tourStop,
      answer,
      authorUserId: authorId,
      tourCity,
      price: this.handlePrice(price),
    });
  }

  logPlayRating(
    tourCode: string,
    stars: number,
    withText: boolean,
    authorId?: string,
    tourCity?: string,
    price?: string
  ): void {
    this.logEvent('play_rating', {
      tourId: tourCode,
      stars,
      withText,
      authorId,
      tourCity,
      price: this.handlePrice(price),
    });
  }

  logPlayShowHint(tourCode: string, tourStop: number, authorId?: string, tourCity?: string, price?: string): void {
    this.logEvent('play_hint', {
      tourId: tourCode,
      tourStop,
      authorUserId: authorId,
      tourCity,
      price: this.handlePrice(price),
    });
  }

  logPlayStart(tourCode: string, authorId?: string, tourCity?: string, price?: string): void {
    this.logEvent('play_start', { tourId: tourCode, authorUserId: authorId, tourCity, price: this.handlePrice(price) });
  }

  logPlayShowCheckout(tourCode: string, price?: string, authorId?: string, tourCity?: string) {
    this.logEvent('play_showCheckout', {
      tourId: tourCode,
      authorUserId: authorId,
      tourCity,
      price: this.handlePrice(price),
    });
  }

  logPlayShowDonation(
    amount: string,
    tourCode?: string,
    authorUserId?: string,
    tourCity?: string,
    price?: string
  ): void {
    this.logEvent('play_showDonation', {
      tourId: tourCode,
      amount,
      authorUserId,
      tourCity,
      price: this.handlePrice(price),
    });
  }

  logPlayShowMap(tourCode: string, screen: 'detail' | 'start' | 'stop', tourStop?: number): void {
    this.logEvent('play_showMap', {
      tourId: tourCode,
      screen,
      tourStop,
    });
  }

  logPlayUseMap(tourCode: string, screen: 'detail' | 'start' | 'stop', tourStop?: number): void {
    this.logEvent('play_useMap', {
      tourId: tourCode,
      screen,
      tourStop,
    });
  }

  logPlayAudio(tourCode: string, tourStop: number | undefined): void {
    this.logEvent('play_playAudio', {
      tourId: tourCode,
      tourStop,
    });
  }

  logShowAudio(tourCode: string, tourStop: number | undefined): void {
    this.logEvent('play_showAudio', {
      tourId: tourCode,
      tourStop,
    });
  }

  logSearchApplyFilter(): void {
    this.logEvent('search_applyFilter');
  }

  logSearch(type: 'id' | 'location' | 'title', q: string) {
    this.logEvent('search', { type, q: q.toLowerCase() });
  }

  logShareTour(tourCode: string, authorId?: string, tourCity?: string, price?: string): void {
    this.logEvent('play_share', { tourId: tourCode, authorUserId: authorId, tourCity, price: this.handlePrice(price) });
  }

  logShowTourCard(tourCode: string, authorId?: string, tourCity?: string, price?: string): void {
    this.logEvent('play_showCard', {
      tourId: tourCode,
      authorUserId: authorId,
      tourCity,
      price: this.handlePrice(price),
    });
  }

  logShowTourDetail(tourCode: string, authorId?: string, tourCity?: string, price?: string): void {
    this.logEvent('play_showDetail', {
      tourId: tourCode,
      authorUserId: authorId,
      tourCity,
      price: this.handlePrice(price),
    });
  }

  logShowTourInsight(tourCode: string): void {
    this.logEvent('play_showPreview', {
      tourId: tourCode,
    });
  }

  logSignIn(method?: string): void {
    this.logEvent('sign_in', { type: method ?? 'unknown' });
  }

  logSignUp(method?: string): void {
    this.logEvent('sign_up', { type: method ?? 'unknown' });
  }

  logSignOut(method?: string): void {
    this.logEvent('sign_out', { type: method ?? 'unknown' });
  }

  logTourContent(tourCode: string, authorId?: string, tourCity?: string, price?: string): void {
    this.logEvent('play_showContent', {
      tourId: tourCode,
      authorUserId: authorId,
      tourCity,
      price: this.handlePrice(price),
    });
  }

  logPaymentCompleted(tourCode: string, method: string, price?: string, authorId?: string, tourCity?: string) {
    this.logEvent('payment_completed', {
      tourId: tourCode,
      method,
      authorUserId: authorId,
      tourCity,
      price: this.handlePrice(price),
    });
  }

  logPurchase(
    tourCode: string,
    tourTitle?: string,
    value?: string,
    coupon?: string,
    price?: string,
    orderId?: string,
    authorId?: string,
    tourCity?: string
  ) {
    const convertedValue = this.handlePrice(value);
    const convertedPrice = this.handlePrice(price);
    const discount = convertedValue && convertedPrice ? convertedPrice - convertedValue : undefined;

    const purchaseEvent = {
      currency: 'EUR',
      transaction_id: orderId,
      value: convertedValue,
      coupon,
      items: [
        {
          item_id: tourCode,
          item_category: 'Germany',
          item_category2: tourCity,
          item_name: tourTitle,
          item_brand: authorId,
          location_id: tourCity,
          discount,
          price: convertedPrice,
        },
      ],
    };
    this.logEvent('purchase', purchaseEvent);

    window.zaraz?.track('purchase', {
      value: convertedValue,
      currency: 'EUR',
      tourCode: tourCode,
      tourTitle: tourTitle,
      tourCity: tourCity,
      client_user_agent: window.navigator.userAgent,
      fb_content_ids: `[${tourCode}]`,
    });
  }

  logPaymentUnlock(tourCode: string, issuanceInfo?: string) {
    this.logEvent('payment_unlock', {
      tourId: tourCode,
      info: issuanceInfo,
    });
  }

  logPaymentMethodSelected(tourCode: string, method: string, price?: string, authorId?: string, tourCity?: string) {
    this.logEvent('payment_methodSelected', {
      tourId: tourCode,
      method,
      authorUserId: authorId,
      tourCity,
      price: this.handlePrice(price),
    });
  }

  logWeWantYou(): void {
    this.logEvent('app_weWantYou');
  }

  logSocialMedia(type: string): void {
    this.logEvent('app_followUs', { type });
  }

  logDonation(amount: number, authorUserId?: string, tourCity?: string): void {
    this.logEvent('payment_donation', { amount, authorUserId, tourCity });
  }

  logDonationReminder(authorUserId?: string, tourCity?: string): void {
    this.logEvent('payment_donationReminder', { authorUserId, tourCity });
  }

  logAutocompleteRequests(requestCount: number): void {
    this.logEvent('autocomplete_session', { requestCount });
  }

  logFollow(authorUserId: string): void {
    this.logEvent('com_follow', { authorUserId });
  }

  logUnfollow(authorUserId: string): void {
    this.logEvent('com_unfollow', { authorUserId });
  }

  logError(e: Error | AsyncError): void {
    if (e instanceof AsyncError) {
      this.logException(e.errorCode, false);
    } else {
      this.logException(e.message, false);
    }
  }

  logException(description: string, fatal: boolean): void {
    this.logEvent('exception', { description, fatal });
  }

  private logEvent(eventName: string, params?: { [key: string]: any }, options?: AnalyticsCallOptions) {
    const commonParams = {
      app_version: config.appVersion,
      app_name: APP_NAME,
    };
    const eventParameters = params ? { ...commonParams, ...params } : commonParams;
    this.analytics.then(analytics => {
      analytics && logEvent(analytics, eventName, eventParameters, options);
    });
  }

  private handlePrice(price?: string): number | undefined {
    if (price === undefined || price === null) return 0;
    if (!Number.isNaN(price)) return parseFloat(price);
    return undefined;
  }
}
