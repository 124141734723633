export enum Language {
  bg = 'bg', // Bulgarian
  ca = 'ca', // Catalan
  cs = 'cs', // Czech
  da = 'da', // Danish
  de = 'de', // German
  el = 'el', // Greek
  en = 'en', // English
  es = 'es', // Spanish
  et = 'et', // Estonian
  fi = 'fi', // Finnish
  fr = 'fr', // Frech
  hu = 'hu', // Hungarian
  hr = 'hr', // Croatian
  id = 'id', // Indonesian
  it = 'it', // Italian
  ja = 'ja', // Japanese
  lt = 'lt', // Lithuanian
  lv = 'lv', // Latvian
  mk = 'mk', // Macedonian
  mt = 'mt', // Maltese
  nl = 'nl', // Dutch
  nn = 'nn', // Norwegian
  pl = 'pl', // Polish
  pt = 'pt', // Portuguese
  ro = 'ro', // Romanian
  ru = 'ru', // Russian
  sk = 'sk', // Slovak
  sl = 'sl', // Slovenian
  sr = 'sr', // Serbian
  sq = 'sq', // Albanian
  sv = 'sv', // Swedish
  tr = 'tr', // Turkish
  uk = 'uk', // Ukrainian
  zh = 'zh', // Chinese
}

export const territories = {
  [Language.de]: 'de_DE',
  [Language.en]: 'en_GB',
};
