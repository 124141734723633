import { getServiceProvider, IServiceProvider } from '../../../services';
import actions from '../actions';
import { getType } from 'typesafe-actions';
import { put, takeLatest } from 'redux-saga/effects';

function* fetchData(action: ReturnType<typeof actions.fetchTourDetailsAsync.request>) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    const tourDetails = yield serviceProvider.api.fetchTourDetails(action.payload);
    yield put(actions.fetchTourDetailsAsync.success(tourDetails));
  } catch (e) {
    yield put(actions.fetchTourDetailsAsync.failure(e));
  }
}

const fetchTourDetails = takeLatest(getType(actions.fetchTourDetailsAsync.request), fetchData);

export default [fetchTourDetails];
