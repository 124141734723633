import React from 'react';
import DrawerListItem from './DrawerListItem';
import { useTranslation } from 'react-i18next';
import { TranslateOutlined } from '@mui/icons-material';
import { useChangeLanguageUrl } from '../hooks/useChangeLanguageUrl';

interface Props {
  onClick: () => void;
}

export const ChangeLanguageListItem = (props: Props) => {
  const { t } = useTranslation();

  const { url: targetUrl } = useChangeLanguageUrl();

  return (
    <DrawerListItem
      title={t('drawer.change_language')}
      icon={TranslateOutlined}
      component={{ type: 'link', href: targetUrl }}
      onClick={() => {
        props.onClick();
      }}
    />
  );
};
