import React from 'react';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { ExternalLink } from '@lialo/pwa-ui/lib/ExternalLink';
import { FacebookIcon, InstagramIcon, YoutubeIcon } from '../Icons';

import routes from '@lialo/core/lib/pages/utils/routes';
import { useServiceProvider } from '../Providers';

const PREFIX = 'SocialMediaIconGroup';

const classes = {
  socialMedia: `${PREFIX}-socialMedia`,
  iconButtons: `${PREFIX}-iconButtons`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.socialMedia}`]: {
    marginTop: 'auto',
    padding: theme.spacing(1),
    alignSelf: 'flex-end',
  },

  [`& .${classes.iconButtons}`]: {
    padding: 4,
    margin: 4,
  },
}));

const SocialMediaIconGroup = () => {
  const { analyticsService } = useServiceProvider();

  return (
    <Root className={classes.socialMedia}>
      <IconButton
        component={ExternalLink}
        onClick={() => analyticsService.logSocialMedia('facebook')}
        href={routes.FACEBOOK}
        className={classes.iconButtons}
        aria-label="Facebook"
        size="large"
      >
        <FacebookIcon fontSize="large" style={{ color: '#4267B2' }} />
      </IconButton>

      <IconButton
        component={ExternalLink}
        onClick={() => analyticsService.logSocialMedia('instagram')}
        href={routes.INSTAGRAM}
        className={classes.iconButtons}
        aria-label="Instagram"
        size="large"
      >
        <InstagramIcon fontSize="large" style={{ color: '#FD1D1D' }} />
      </IconButton>

      <IconButton
        component={ExternalLink}
        onClick={() => analyticsService.logSocialMedia('youtube')}
        href={routes.YOUTUBE}
        className={classes.iconButtons}
        aria-label="Youtube"
        size="large"
      >
        <YoutubeIcon fontSize="large" style={{ color: '#FF0000' }} />
      </IconButton>
    </Root>
  );
};

export default SocialMediaIconGroup;
