export interface ISimpleLocation {
  lat: number;
  lng: number;
  city?: string;
  country?: string;
}

export interface ILocation extends ISimpleLocation {
  id: number;
}

export function mirrorReflect(location: ISimpleLocation, mirrorLocation: ISimpleLocation): ISimpleLocation {
  const delta: ISimpleLocation = {
    lat: mirrorLocation.lat - location.lat,
    lng: mirrorLocation.lng - location.lng,
  };

  return {
    lat: mirrorLocation.lat + delta.lat,
    lng: mirrorLocation.lng + delta.lng,
    city: location.city,
    country: location.country,
  };
}
