import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { showSnackbar } from '../utils/showSnackbar';
import commonActions from '../actions';
import { emptyStringToUndefined } from '../../../utils/helper/strings';
import { requireUserOrThrow } from '../../utils/sagas';

const createTourReviewSaga = takeLatest(getType(commonActions.createTourReview.request), function* (
  action: ReturnType<typeof commonActions.createTourReview.request>,
) {
  const { tourCode, rating, review, silent, onSuccess } = action.payload;
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    yield requireUserOrThrow();
    const reviewSummary = {
      rating,
      review: emptyStringToUndefined(review),
    };
    yield serviceProvider.api.createUpdateTourReview(tourCode, reviewSummary);
    yield put(commonActions.createTourReview.success(tourCode));

    if (!silent) {
      yield showSnackbar({ key: 'tour_review_dialog.on_success' });
    }

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (e) {
    yield put(commonActions.createTourReview.failure(e));
    serviceProvider.analyticsService.logError(e);
  }
});

export default [createTourReviewSaga];
