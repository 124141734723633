import { RootState } from '../../store/root';
import { INotificationPreferences } from '../../common';
import { IEditNotificationPreferences } from "./reducer";

export const selectFcmToken = (state: RootState): string | null => state.notification.fcmToken;

export const selectFcmTokenUpdatedAt = (state: RootState): Date | null => state.notification.fcmTokenUpdatedAt;

export const selectNotificationPreferences = (state: RootState): INotificationPreferences | null =>
  state.notification.notificationPreferences;

export const selectEditNotificationPreferences = (state: RootState): IEditNotificationPreferences => state.notification.editNotificationPreferences;
