import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { RootAction } from '../../store/root';
import actions from './actions';
import { ITourStatistics, ITourSummary, TourCode } from '../../models';
import { commonActions } from '../common';
import { IAsyncError } from '../../common';

export interface IMyToursState {
  isLoadingCreatedTours: boolean;
  isLoadingPlayedTours: boolean;
  error?: IAsyncError;
  playedTours: Record<TourCode, ITourSummary | null>;
  createdTours: ITourSummary[];
  isLoadingStatistics: boolean;
  statisticsError?: IAsyncError;
  tourStatistics?: ITourStatistics;
}

const defaultState: IMyToursState = {
  isLoadingCreatedTours: false,
  isLoadingPlayedTours: false,
  isLoadingStatistics: false,
  playedTours: {},
  createdTours: [],
};

const myToursReducer = createReducer<IMyToursState, RootAction>(defaultState)
  .handleAction(actions.fetchPlayedTours.request, (state, action) =>
    produce(state, draft => {
      draft.isLoadingPlayedTours = true;
      draft.error = undefined;
    })
  )
  .handleAction(actions.fetchCreatedTours.request, (state, action) =>
    produce(state, draft => {
      draft.isLoadingCreatedTours = true;
      draft.error = undefined;
    })
  )
  .handleAction(actions.fetchPlayedTours.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoadingPlayedTours = false;
      draft.error = action.payload;
    })
  )
  .handleAction(actions.fetchCreatedTours.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoadingCreatedTours = false;
      draft.error = action.payload;
    })
  )
  .handleAction(actions.fetchPlayedTours.cancel, (state, action) =>
    produce(state, draft => {
      draft.isLoadingPlayedTours = false;
    })
  )
  .handleAction(actions.fetchCreatedTours.cancel, (state, action) =>
    produce(state, draft => {
      draft.isLoadingCreatedTours = false;
    })
  )
  .handleAction(commonActions.deleteTour.success, (state, action) =>
    produce(state, draft => {
      draft.createdTours = draft.createdTours.filter(t => t.shortLink !== action.payload);
    })
  )
  .handleAction(actions.fetchPlayedTours.success, (state, action) =>
    produce(state, draft => {
      draft.isLoadingPlayedTours = false;
      draft.playedTours = action.payload;
    })
  )
  .handleAction(actions.fetchCreatedTours.success, (state, action) =>
    produce(state, draft => {
      draft.isLoadingCreatedTours = false;
      draft.createdTours = action.payload;
    })
  )
  .handleAction(actions.fetchStatistics.request, (state, action) =>
    produce(state, draft => {
      draft.isLoadingStatistics = true;
      draft.statisticsError = undefined;
    })
  )
  .handleAction(actions.fetchStatistics.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoadingStatistics = false;
      draft.statisticsError = action.payload;
    })
  )
  .handleAction(actions.fetchStatistics.success, (state, action) =>
    produce(state, draft => {
      draft.tourStatistics = action.payload;
      draft.isLoadingStatistics = false;
    })
  )
  .handleAction(actions.fetchStatistics.cancel, (state, action) =>
    produce(state, draft => {
      draft.isLoadingStatistics = false;
      draft.statisticsError = undefined;
      draft.tourStatistics = undefined;
    })
  )
  .handleAction(commonActions.signOut.success, (state, action) => ({
    ...defaultState,
  }));
export default myToursReducer;
