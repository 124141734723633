import { ElementType, stringLiterals } from '../utils/types/utilTypes';

export type Occasions = ElementType<typeof AvailableSettings['occasions']>;
export type Environments = ElementType<typeof AvailableSettings['environments']>;
export type Audience = ElementType<typeof AvailableSettings['audience']>;
export type Categories = ElementType<typeof AvailableSettings['categories']>;
export type ContentTypes = ElementType<typeof AvailableSettings['contentTypes']>;
export type Duration = ElementType<typeof AvailableSettings['duration']>;
export type Distance = ElementType<typeof AvailableSettings['distance']>;
export type MeansOfTransport = ElementType<typeof AvailableSettings['meansOfTransport']>;
export type Season = ElementType<typeof AvailableSettings['season']>;

export interface ITourSettings {
  duration: Duration | null;
  distance: Distance | null;
  audience: Audience | null;
  handicappedAccessible: boolean;
  shoppingFacilities: boolean;
  playAtHome: boolean;
  categories: Categories[];
  occasions: Occasions[];
  contentTypes: ContentTypes[];
  meansOfTransport: MeansOfTransport | null;
  environments: Environments[];
  season: Season | null;
}

export type TourSettingsKey = keyof ITourSettings;

export const AvailableSettings = {
  meansOfTransport: stringLiterals('BY_FOOT', 'BIKE', 'PUBLIC_TRANSPORT', 'CAR'),
  distance: stringLiterals('SHORT', 'MEDIUM', 'LONG'),
  categories: stringLiterals(
    'HISTORY',
    'ART',
    'MOVIE',
    'MUSIC',
    'ARCHITECTURE',
    'FOOD_AND_DRINK',
    'NIGHTLIFE',
    'NATURE',
    'SPORTS',
    'SHOPPING',
    'SCIENCE',
    'TRAFFIC',
    'SUSTAINABILITY'
  ),
  audience: stringLiterals('KIDS', 'ADULTS', 'FAMILIES'),
  duration: stringLiterals('SHORT', 'MEDIUM', 'LONG'),
  environments: stringLiterals('INDOOR', 'OUTDOOR'),
  contentTypes: stringLiterals('RIDDLE', 'INFORMATION', 'CHALLENGE'),
  occasions: stringLiterals(
    'SIGHTSEEING',
    'BIRTHDAY_PARTY',
    'SCHOOL_TRIP',
    'TEAM_EVENT',
    'STAG_PARTY',
    'NEIGHBOURHOOD_TOUR',
    'THEMATIC_TOUR'
  ),
  season: stringLiterals('SUMMER', 'WINTER', 'YEAR_LONG'),
};

export const emptySettings: ITourSettings = {
  occasions: [],
  environments: [],
  audience: null,
  categories: [],
  contentTypes: [],
  duration: null,
  distance: null,
  meansOfTransport: null,
  season: null,
  handicappedAccessible: false,
  playAtHome: false,
  shoppingFacilities: true,
};

export type AvailableSettingsKey = keyof typeof AvailableSettings;
