export const hashCode = (s: string) => {
  return s.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);
};

export const indexToChar = (index: number, offset = 65) => {
  return String.fromCharCode(index + offset);
};

export const emptyStringToUndefined = <T>(s: T): T | undefined => {
  if (typeof s === 'string' && s.trim().length === 0) {
    return undefined;
  }
  return s;
};

export const trimLines = <T>(s: T): T => {
  if (typeof s === 'string') {
    return (s
      .split('\n')
      .map(it => it.trimEnd())
      .join('\n') as unknown) as T;
  } else {
    return s;
  }
};
