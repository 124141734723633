import { put, select, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { getType } from 'typesafe-actions';
import { getServiceProvider, IServiceProvider } from '../../services';
import selectors from './selectors';
import { commonActions } from '../common';

function* saveTourStopOfflineStatus(action: ReturnType<typeof actions.syncTourStopOfflineStatusAsync.request>) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const syncId = action.payload;

  const statusWithSyncId = selectors.selectBySyncId(yield select(), syncId);

  if (statusWithSyncId.length === 0) {
    return;
  }

  try {
    yield serviceProvider.api.saveTourStopOfflineStatus(statusWithSyncId);
    yield put(actions.syncTourStopOfflineStatusAsync.success(syncId));
  } catch (e) {
    yield put(actions.syncTourStopOfflineStatusAsync.failure(syncId));
  }
}

const saveSaga = takeEvery(getType(actions.syncTourStopOfflineStatusAsync.request), saveTourStopOfflineStatus);

/*
trigger sync whenever
- a new status is added
- the app loads (next session)
- or the internet connection is restored
 */
const addSaga = takeEvery(
  [getType(commonActions.connection.online), getType(commonActions.initApp), getType(actions.addTourStopOfflineStatus)],
  function* () {
    const randomSyncId = Math.random().toString(36).substring(2, 15);
    yield put(actions.syncTourStopOfflineStatusAsync.request(randomSyncId));
  }
);

export default [saveSaga, addSaga];
