import { createPersistedReducer, rootSaga } from './root';
import createSagaMiddleware from 'redux-saga';
import { IServiceProvider } from '../services';
import { createLogger } from 'redux-logger';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { persistStore } from 'redux-persist';
import { Persistor, Storage } from 'redux-persist/es/types';
import { History } from 'history';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';

const dev = process.env.NODE_ENV === 'development';

// eslint-disable-next-line @typescript-eslint/no-empty-function

export default function configureStore(
  storage: Storage,
  history: History<any>,
  serviceProvider: IServiceProvider
): [Store, Persistor] {
  const sagaMiddleware = createSagaMiddleware({ context: { serviceProvider } });
  const routerMiddleware = createRouterMiddleware(history);
  const loggerMiddleware = (createLogger as any)();
  const composeEnhancers = dev ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
  const middlewares = dev ? [sagaMiddleware, routerMiddleware, loggerMiddleware] : [sagaMiddleware, routerMiddleware];
  const persistedReducer = createPersistedReducer(history, storage);
  const initialState = {};

  const store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));

  const persistor = persistStore(store);
  // persistor.purge();

  sagaMiddleware.run(rootSaga);
  return [store, persistor];
}
