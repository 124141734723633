import { styled } from '@mui/material/styles';
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';

const PREFIX = 'ConfirmationDialog';

const classes = {
  textContent: `${PREFIX}-textContent`,
  paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)((
  {
    theme,
  },
) => ({
  [`& .${classes.textContent}`]: {
    whiteSpace: 'pre-wrap',
  },

  [`& .${classes.paper}`]: {
    width: '100%',
  },
}));

interface IProps {
  title?: string;
  description: React.ReactNode;
  confirmationLabel?: string;
  cancelLabel?: string;
  hideCancelBtn?: boolean;
  onClose: (confirmed: boolean) => void;
}

export const ConfirmationDialog = (props: IProps & Omit<DialogProps, 'onClose'>) => {

  const { title, description, onClose, confirmationLabel, hideCancelBtn, cancelLabel, ...dialogProps } = props;

  const confirm = () => onClose(true);
  const cancel = () => onClose(false);

  return (
    <StyledDialog classes={{ paper: classes.paper }} {...dialogProps} onClose={cancel}
                  aria-labelledby="form-dialog-title">
      {props.title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText className={classes.textContent}>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {!hideCancelBtn && cancelLabel && (
          <Button onClick={cancel} color="primary">
            {cancelLabel}
          </Button>
        )}
        {confirmationLabel && (
          <Button onClick={confirm} color="primary">
            {confirmationLabel}
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
};
