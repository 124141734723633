import userSaga from './userSaga';
import connectionSaga from './connectionSaga';
import appUpdateSaga from './appUpdateSaga';
import authorizationSaga from './authorizationSaga';
import clearCompletedOfflineToursSaga from './clearCompletedOfflineToursSaga';

export default [
  ...userSaga,
  ...connectionSaga,
  ...appUpdateSaga,
  ...authorizationSaga,
  ...clearCompletedOfflineToursSaga,
];
