import React from 'react';
import { CircularProgress, ButtonProps, Button } from "@mui/material";

interface IProps {
  component: React.ComponentType<ButtonProps>;
  isLoading: boolean;
}

export const LoadingButton = (props: IProps & React.ComponentProps<typeof Button>) => {
  const { component, isLoading, ...buttonProps } = props;

  return React.createElement(component, {
    ...buttonProps,
    disabled: isLoading ? true : buttonProps.disabled,
    startIcon: isLoading ? <CircularProgress size={18} /> : buttonProps.startIcon,
  });
};
