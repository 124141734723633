import fetchTourSaga from './initTourSaga';
import navigateSaga from './navigateSaga';
import saveTourProgressSaga from './saveTourProgressSaga';
import tourEventSaga from './tourEventSaga';
import sendProblemReportSaga from './sendProblemReportSaga';
import completeTourSaga from './completeTourSaga';
import clearAssetCacheSaga from './clearAssetCacheSaga';
import sendTipReminderSaga from './sendTipReminderSaga';

export default [
  ...fetchTourSaga,
  ...navigateSaga,
  ...clearAssetCacheSaga,
  ...saveTourProgressSaga,
  ...tourEventSaga,
  ...sendProblemReportSaga,
  ...completeTourSaga,
  ...sendTipReminderSaga,
];
