import { PayloadAction, TypeConstant } from 'typesafe-actions';
import produce from 'immer';
import { IAsyncError } from '../../common/AsyncError';

export interface IAsyncActionState {
  isLoading: boolean;
  error?: IAsyncError;
}

export const reduceRequestAction = <TState extends IAsyncActionState, TType extends TypeConstant>(
  state: TState,
  action: PayloadAction<TType, any>
) =>
  produce(state, draft => {
    draft.error = undefined;
    draft.isLoading = true;
  });

export const reduceCancelAction = <TState extends IAsyncActionState, TType extends TypeConstant>(
  state: TState,
  action: PayloadAction<TType, any>
) =>
  produce(state, draft => {
    draft.isLoading = false;
  });

export const reduceFailureAction = <
  TState extends IAsyncActionState,
  TType extends TypeConstant,
  TPayload extends IAsyncError
>(
  state: TState,
  action: PayloadAction<TType, TPayload>
) =>
  produce(state, draft => {
    draft.error = action.payload;
    draft.isLoading = false;
  });
