//fooBar => foo_bar
export function snakeCase(str: string): string {
  return str
    .trim()
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
}

// foo-bar => fooBar
export function kebabCase(snakeCaseString: string): string {
  return snakeCaseString.replace(/(-\w)/g, m => m[1].toUpperCase());
}