import React, { useContext } from 'react';

type NavigationDrawerState = {
  isOpen: boolean;
  toggleState(): void;
};

const StateContext = React.createContext<NavigationDrawerState>({
  isOpen: false,
  toggleState: () => {},
});

export const NavigationDrawerStateProvider = (props: { children: React.ReactNode }) => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <StateContext.Provider
      value={{
        isOpen,
        toggleState: () => setOpen(!isOpen),
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
};

export const useNavigationDrawerState = () => useContext(StateContext);
