import { INavigationService, NavigateOptions, State, To } from '@lialo/core/lib/services';
import { createPath, History, LocationDescriptorObject } from 'history';
import { generatePath } from 'react-router';
import { i18n } from 'i18next';
import routes from '@lialo/core/lib/pages/utils/routes';
import {
  ImageDetailPath,
  ImageListPath,
  isStopImagePath,
} from '@lialo/core/lib/pages/createTour/utils/imagePathHelper';
import { mapObjectValues } from '@lialo/core/lib/utils/helper/object';
import { stringify } from 'query-string';
import { ISimpleLocation } from '@lialo/core/lib/models';

export default class NavigationService implements INavigationService {
  constructor(private readonly history: History, private readonly i18n: i18n) {}

  navigate(to: To, options?: NavigateOptions) {
    if (typeof to === 'string') {
      if (options?.replace === true) {
        this.history.replace(to, options.state);
      } else {
        this.history.push(to, options?.state);
      }
    } else {
      const locationDescriptor: LocationDescriptorObject = {
        state: options?.state,
        ...to,
      };
      if (options?.replace === true) {
        this.history.replace(locationDescriptor);
      } else {
        this.history.push(locationDescriptor);
      }
    }
  }

  navigateI18n(to: To, options?: NavigateOptions) {
    this.navigate(this.generateI18nPath(to), options);
  }

  generateI18nPath(to: To, params: Record<string, string | number> = {}): To {
    return typeof to === 'string'
      ? generatePath(to, { lang: this.i18n.language, ...params })
      : {
          ...to,
          pathname: to.pathname ? generatePath(to.pathname, { lang: this.i18n.language, ...params }) : undefined,
        };
  }

  createHref(to: To, absolute: boolean): string {
    const path = typeof to === 'string' ? to : createPath(to);
    return absolute ? `${window.location.protocol}//${window.location.host}${path}` : path;
  }

  returnTo(returnTo: any): State {
    return { returnTo };
  }

  goBack(): void {
    this.history.goBack();
  }

  generateEditTourStopUrl(route: string, localId: number) {
    return this.generateI18nPath(route, { localId });
  }

  generateEditTourUrl(route: string, loadTourParam?: 'empty' | string) {
    return this.generateI18nPath({
      pathname: route,
      search: loadTourParam ? `?${routes.PARAM_TOUR_SHORTLINK}=${loadTourParam}` : undefined,
    });
  }

  generatePlayTourUrl(route: string, shortCode: string, restartIfNecessary = false, search = {}): To {
    const queryParams = {
      ...search,
      ...(restartIfNecessary ? { start: 'true' } : {}),
    };

    return this.generateI18nPath(
      {
        pathname: route,
        search: new URLSearchParams(queryParams).toString(),
      },
      {
        tour: shortCode,
      }
    );
  }

  generatePlayTourStopUrl(route: string, shortCode: string, stopIndex: number): To {
    return this.generateI18nPath(route, {
      tour: shortCode,
      stopIndex,
    });
  }

  generatePublicProfileUrl(userId: string, lang?: string): To {
    return this.generateI18nPath(routes.PUBLIC_PROFILE, { userId, ...(lang ? { lang } : {}) });
  }

  generateTourDetailUrl(shortCode: string, lang?: string): To {
    return this.generateI18nPath(routes.TOUR_DETAIL, { tourCode: shortCode, ...(lang ? { lang } : {}) });
  }

  generateTourDetailOrPlayTourUrl(isRunningTour: boolean, shortCode: string): To {
    return isRunningTour
      ? this.generatePlayTourUrl(routes.PLAY_TOUR, shortCode)
      : this.generateTourDetailUrl(shortCode);
  }

  generateTourPreviewUrl(anchor?: string): To {
    return this.generateI18nPath({
      pathname: routes.CREATE_TOUR_PREVIEW,
      hash: anchor,
    });
  }

  generateImageListUrl(path: ImageListPath): To {
    const listType = path.listType.toLowerCase();
    if (isStopImagePath(path)) {
      return this.generateI18nPath(`${routes.CREATE_TOUR}/images/${path.stopLocalId}/${listType}`);
    } else {
      return this.generateI18nPath(`${routes.CREATE_TOUR}/images/${listType}`);
    }
  }

  generateImageDetailUrl(path: ImageDetailPath): To {
    const to = this.generateImageListUrl(path);
    if (typeof to === 'string') {
      return `${to}/${path.localId}`;
    } else {
      return { ...to, pathname: `${to.pathname}/${path.localId}` };
    }
  }

  generateFindTourLink(
    filters?: any,
    center?: ISimpleLocation,
    selectedTour?: string,
    zoom?: number,
    list?: boolean
  ): To {
    const centerRounded = center ? mapObjectValues(center, v => (typeof v === 'number' ? v.toFixed(7) : v)) : {};
    const mode = list ? 'list' : undefined;
    const params = {
      ...filters,
      ...centerRounded,
      selectedTour,
      zoom,
      mode,
    };

    const search = stringify(params, {
      skipNull: true,
      arrayFormat: 'comma',
    });

    return this.generateI18nPath({
      pathname: routes.ROOT,
      search,
    });
  }

  generateTourContentUrl(shortCode: string): To {
    return this.generateI18nPath(routes.TOUR_CONTENT, { tourCode: shortCode });
  }

  generateTourInsightUrl(shortCode: string): To {
    return this.generateI18nPath(routes.TOUR_INSIGHT, { tourCode: shortCode });
  }

  generateTourCheckoutUrl(shortCode: string, query?: Record<string, any>): To {
    return this.generateI18nPath(
      {
        pathname: routes.TOUR_CHECKOUT,
        search: query ? stringify(query) : '',
      },
      { tourCode: shortCode }
    );
  }

  generatePurchaseUnlockCouponUrl(shortCode: string, query?: Record<string, any>): To {
    return this.generateI18nPath(
      {
        pathname: routes.TOUR_PURCHASE_COUPON,
        search: query ? stringify(query) : '',
      },
      { tourCode: shortCode }
    );
  }
}
