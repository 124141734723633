import { useLocation } from 'react-router';
import React from 'react';

export function useQueryParameter() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function extractSearchParams(search: string, ...params: string[]): Record<string, string> {
  const urlSearchParams = new URLSearchParams(search);
  return params.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: urlSearchParams.get(curr),
    }),
    {}
  );
}

export function getPathFromUrl(url: string): string {
  const parts = url.split(/[?#]/);
  return parts[0];
}

export function removeProtocol(url: string): string {
  return url.replace(/(^\w+:|^)\/\//, '');
}

export function makeExternalUrl(url: string): string {
  if (!/^(?:ht)tps?:\/\//.test(url)) {
    return '//' + url;
  }
  return url;
}
