import { createAction, createAsyncAction } from 'typesafe-actions';
import { TourStopOfflineStatus } from '../../common';

const addTourStopOfflineStatus = createAction('ADD_TOURSTOPOFFLINESTATUS', action => (status: TourStopOfflineStatus) =>
  action(status)
);

const syncTourStopOfflineStatusAsync = createAsyncAction(
  'SYNC_TOURSTOPOFFLINESTATUS_REQUEST',
  'SYNC_TOURSTOPOFFLINESTATUS_SUCCESS',
  'SYNC_TOURSTOPOFFLINESTATUS_FAILURE'
)<string, string, string>(); // param = syncId

export default {
  addTourStopOfflineStatus,
  syncTourStopOfflineStatusAsync,
};
