import { convertToTask, ICreateTaskModel } from './Task';
import { convertToImages, IImageList, ImageType } from './Image';
import { ITourStop, IWaypoint } from "../../../models";

export interface ICreateTourStopModel {
  id: number;
  localId?: number;
  orderId: number;
  task?: ICreateTaskModel;
  title?: string;
  directions?: string;
  information?: string;
  imageList?: IImageList;
  lookIntoTour: boolean;
  waypointsEnabled: boolean;
  waypoints?: Array<IWaypoint>;
}

export function convertToTourStop(createTourStopModel: ICreateTourStopModel): ITourStop {
  return {
    id: createTourStopModel.id,
    orderId: createTourStopModel.orderId,
    title: createTourStopModel.title,
    information: createTourStopModel.information,
    directions: createTourStopModel.directions,
    directionImages: convertToImages(createTourStopModel.imageList, ImageType.DIRECTIONS),
    informationImages: convertToImages(createTourStopModel.imageList, ImageType.INFORMATION),
    task: createTourStopModel.task ? convertToTask(createTourStopModel.task) : undefined,
    lookIntoTour: createTourStopModel.lookIntoTour,
    waypoints: createTourStopModel.waypointsEnabled ? createTourStopModel.waypoints : undefined,
  };
}
