import { createReducer } from 'typesafe-actions';
import { RootAction } from '../../store/root';
import produce from 'immer';
import { DialogConfig, SnackbarConfig } from './models';
import commonActions from './actions';
import { IAsyncError } from '../../common';
import { equals } from '../../utils/helper/deep-equal';

export interface ICommonState {
  dialogs: DialogConfig[];
  snackbars: SnackbarConfig[];

  reauthenticationRequested: boolean;
  isReauthenticating: boolean;
  reauthenticationError?: IAsyncError;

  isSendingPasswordEmail: boolean;
  passwordEmailError?: IAsyncError;
  hasSentPasswordEmail: boolean;
}

const defaultState: ICommonState = {
  dialogs: [],
  snackbars: [],
  reauthenticationRequested: false,
  isReauthenticating: false,

  isSendingPasswordEmail: false,
  hasSentPasswordEmail: false,

};

const reducer = createReducer<ICommonState, RootAction>(defaultState)
  .handleAction(commonActions.showDialog, (state, action) =>
    produce(state, draft => {
      draft.dialogs.push(action.payload.dialogConfig);
    })
  )
  .handleAction(commonActions.hideDialog, (state, action) =>
    produce(state, draft => {
      draft.dialogs = draft.dialogs.slice(1);
    })
  )
  .handleAction(commonActions.showSnackbar, (state, action) =>
    produce(state, draft => {
      if (!draft.snackbars.some(snackbarConfig => equals(snackbarConfig, action.payload.snackbarConfig))) {
        draft.snackbars.push(action.payload.snackbarConfig);
      }
    })
  )
  .handleAction(commonActions.hideSnackbar, (state, action) =>
    produce(state, draft => {
      draft.snackbars = draft.snackbars.slice(1);
    })
  )
  .handleAction(commonActions.reauthenticate.showDialog, (state, action) =>
    produce(state, draft => {
      draft.reauthenticationRequested = true;
      draft.reauthenticationError = undefined;
      draft.isSendingPasswordEmail = false;
      draft.hasSentPasswordEmail = false;
      draft.passwordEmailError = undefined;
    })
  )
  .handleAction(commonActions.reauthenticate.request, (state, action) =>
    produce(state, draft => {
      draft.reauthenticationRequested = true;
      draft.reauthenticationError = undefined;
      draft.isReauthenticating = true;
    })
  )
  .handleAction(commonActions.reauthenticate.success, (state, action) =>
    produce(state, draft => {
      draft.reauthenticationRequested = false;
      draft.isReauthenticating = false;
    })
  )
  .handleAction(commonActions.reauthenticate.failure, (state, action) =>
    produce(state, draft => {
      draft.isReauthenticating = false;
      draft.reauthenticationError = action.payload;
    })
  )
  .handleAction(commonActions.reauthenticate.cancel, (state, action) =>
    produce(state, draft => {
      draft.isReauthenticating = false;
      draft.reauthenticationRequested = false;
    })
  )
  .handleAction(commonActions.resetPassword.request, (state, action) =>
    produce(state, draft => {
      draft.isSendingPasswordEmail = true;
      draft.hasSentPasswordEmail = false;
      draft.passwordEmailError = undefined;
    })
  )
  .handleAction(commonActions.resetPassword.success, (state, action) =>
    produce(state, draft => {
      draft.isSendingPasswordEmail = false;
      draft.hasSentPasswordEmail = true;
    })
  )
  .handleAction(commonActions.resetPassword.failure, (state, action) =>
    produce(state, draft => {
      draft.isSendingPasswordEmail = false;
      draft.passwordEmailError = action.payload;
    })
  );

export default reducer;
