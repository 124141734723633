import { getServiceProvider, IServiceProvider } from '../../../services';
import actions from '../actions';
import { getType } from 'typesafe-actions';
import { put, takeLatest } from 'redux-saga/effects';

function* fetchData(action: ReturnType<typeof actions.fetchTourInsightAsync.request>) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    const tourInsight = yield serviceProvider.api.fetchTourInsight(action.payload);
    yield put(actions.fetchTourInsightAsync.success(tourInsight));
  } catch (e) {
    yield put(actions.fetchTourInsightAsync.failure(e));
  }
}

const fetchTourInsight = takeLatest(getType(actions.fetchTourInsightAsync.request), fetchData);

export default [fetchTourInsight];
