import { put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { ITour } from '../../../models';
import actions from '../actions';
import selectors from '../selectors';
import { commonSelectors } from '../index';

function* fetchData(action: ReturnType<typeof actions.fetchTourAsync.request>) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    const user = commonSelectors.selectUser(yield select());
    const tour = yield serviceProvider.api.fetchTourByShortLink(action.payload, !!user);
    yield put(actions.fetchTourAsync.success(tour));
  } catch (e) {
    const existingTour: ITour | undefined = yield select(selectors.selectTourWithTourCode, action.payload);
    if (existingTour) {
      yield put(actions.fetchTourAsync.cancel(e));
    } else {
      yield put(actions.fetchTourAsync.failure(e));
      serviceProvider.analyticsService.logError(e);
    }
  }
}

const fetchTourSaga = takeEvery(getType(actions.fetchTourAsync.request), fetchData);

export default [fetchTourSaga];
