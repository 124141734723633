import { IStorageService } from '@lialo/core/lib/services';

export default class StorageService implements IStorageService {
  // check if localStorage is available and can be used
  checkLocalStorage(): boolean {
    try {
      const key = '__storage_test';
      window.localStorage.setItem(key, 'test');
      window.localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  async persistStorage() {
    try {
      if (navigator.storage && navigator.storage.persist) {
        // https://developer.mozilla.org/en-US/docs/Web/API/StorageManager/persist
        const persisted = await navigator.storage.persist();
        console.log('Persisted Permission Granted?', persisted);
      }
    } catch (e) {
      // e.g. when in incognito mode in Firefox
      console.warn('Failed to persist storage', e);
    }
  }

  getItem<T extends string | number | object>(key: string): T | null {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (e) {
      return null;
    }
  }

  setItem(key: string, value: any) {
    try {
      const item = JSON.stringify(value);
      window.localStorage.setItem(key, item);
    } catch (e) {
      console.error('Failed to save to localStorage', e);
    }
  }

  deleteItem(key: string) {
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      console.error('Failed to delete from localStorage', e);
    }
  }
}
