import { call, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { takeConfirmation } from '../../utils/sagas';
import { setupNotifications } from '../../notifications';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { showErrorSnackbar, showSnackbar } from '../../common/utils/showSnackbar';

const subscribeSaga = takeLeading(getType(actions.subscribeLocation), function* (
  action: ReturnType<typeof actions.subscribeLocation>
) {
  const { api }: IServiceProvider = yield getServiceProvider();

  const confirmed = action.payload.showDialog
    ? yield takeConfirmation({
        titleKey: 'find_tour.subscribe_location_dialog.title',
        content: { key: 'find_tour.subscribe_location_dialog.description' },
        positiveKey: 'find_tour.subscribe_location_dialog.subscribe',
      })
    : true;

  if (confirmed) {
    const setupSuccess = yield setupNotifications();
    if (!setupSuccess) {
      return;
    }

    try {
      yield call(api.subscribeLocation.bind(api), action.payload.location);
      yield showSnackbar({ key: 'find_tour.subscribe_location_dialog.success' });
    } catch (e) {
      yield showErrorSnackbar(e);
    }
  }
});

export default [subscribeSaga];
