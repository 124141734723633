import React from 'react';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Persistor } from 'redux-persist/es/types';
import { History, LocationState } from 'history';
import { I18nextProvider } from 'react-i18next';
import { ConnectedRouter } from 'connected-react-router';

import { IServiceProvider } from '@lialo/core/lib/services';
import AppContainer from './AppContainer';
import { LoadingScreen } from '@lialo/pwa-ui/lib/PageComponents/Loading';
import { InstallPromptProvider, ServiceProvider } from '../common/Providers';
import i18n from '../i18n';
import { ThemeProvider } from '@lialo/pwa-ui/lib/Theme';
import { Theme } from '@mui/material';

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface IProps {
  store: Store;
  persistor: Persistor;
  history: History<LocationState>;
  services: IServiceProvider;
  children?: React.ReactNode;
}

const App = (props: IProps) => {
  return (
    <I18nextProvider i18n={i18n}>
      <InstallPromptProvider onInstall={() => props.services.analyticsService.logAddToHomescreen()}>
        <Provider store={props.store}>
          <ServiceProvider serviceProvider={props.services}>
            <ThemeProvider>
              <PersistGate loading={<LoadingScreen />} persistor={props.persistor}>
                <ConnectedRouter history={props.history}>
                  <AppContainer />
                </ConnectedRouter>
              </PersistGate>
            </ThemeProvider>
          </ServiceProvider>
        </Provider>
      </InstallPromptProvider>
    </I18nextProvider>
  );
};

export default App;
