import { getType } from 'typesafe-actions';
import actions from '../actions';
import { put, takeLatest } from 'redux-saga/effects';
import { getServiceProvider, IServiceProvider } from '../../../services';

const fetchPrivateUserSaga = takeLatest(getType(actions.fetchPrivateUser.request), function* () {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    const privateUser = yield serviceProvider.api.fetchPrivateUser();
    yield put(actions.fetchPrivateUser.success(privateUser));
  } catch (e) {
    yield put(actions.fetchPrivateUser.failure(e));
    serviceProvider.analyticsService.logError(e);
  }
});

export default [fetchPrivateUserSaga];
