import { styled } from '@mui/material/styles';
import React, { Component } from 'react';
import { TextFieldProps } from '@mui/material/TextField';

const StyledSpan = styled('span')(({}) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

interface IProps {
  maxLength: number;
}

const withMaxLength = <T extends TextFieldProps, P extends IProps>(Component: React.ComponentType<T>) => (
  props: P & T
) => {
  const { maxLength, value, onChange, helperText, inputProps, ...otherProps } = props;

  const [inputValue, setInputValue] = React.useState((value as string) || '');

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const componentProps = {
    ...otherProps,
    inputProps: { maxLength: maxLength, ...inputProps },
    helperText: (
      <StyledSpan>
        {helperText && <span>{helperText}</span>}
        <span>{`${inputValue.length}/${maxLength}`}</span>
      </StyledSpan>
    ),
    value: inputValue,
    onChange: onInputChange,
  };

  return <Component {...(componentProps as any)} />;
};

export default withMaxLength;
