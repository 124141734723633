import { RootState } from '../../../store/root';
import { connect } from 'react-redux';
import { commonActions, commonSelectors } from '../../common';

const mapStateToProps = (state: RootState) => ({
  username: commonSelectors.selectUser(state)?.username,
  imageUrl: commonSelectors.selectUser(state)?.imageUrl,
  isRegisteredUser: commonSelectors.selectIsRegisteredUser(state),
});

const dispatchProps = {
  signOut: commonActions.signOut.request,
};

export type IDrawerProps = ReturnType<typeof mapStateToProps> & typeof dispatchProps;
export const connectDrawer = connect(mapStateToProps, dispatchProps);
