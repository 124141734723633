import { ISimpleLocation } from './Location';
import { getTitleImage, ITour } from './Tour';
import { TourVisibility } from './TourVisibility';
import { IFirebaseUser, IUserPreview } from './User';
import { pick } from '../utils/helper/object';
import { Distance, Duration } from './TourSettings';
import { IAverageRating } from './AverageRating';
import { Language } from '@lialo/common/lib/language';

export interface ITourSummary {
  user: IUserPreview;
  title: string;
  description: string;
  shortLink: string;
  valid: boolean;
  startLocation?: ISimpleLocation;
  imageUrl?: string;
  updatedAt: Date;
  createdAt: Date;
  visibility: TourVisibility;
  language: Language;
  availableLanguages: Language[] | null;
  playAtHome: boolean;
  averageRating?: IAverageRating;
  duration?: Duration;
  distance?: Distance;
  price?: string;
  enableDonation: boolean;
}

export function convertToTourSummary(tour: ITour): ITourSummary {
  return {
    shortLink: tour.shortLink,
    title: tour.title!,
    description: tour.description!,
    startLocation: tour.startLocation!,
    imageUrl: getTitleImage(tour)?.url!,
    createdAt: tour.createdAt ?? new Date(),
    updatedAt: tour.updatedAt ?? new Date(),
    user: convertUserToUserPreview(tour.user),
    language: tour.language,
    availableLanguages: null,
    playAtHome: tour.settings.playAtHome ?? false,
    valid: tour.valid,
    visibility: tour.visibility,
    averageRating: tour.rating,
    distance: tour.settings?.distance ?? undefined,
    duration: tour.settings?.duration ?? undefined,
    price: tour.price,
    enableDonation: tour.enableDonation,
  };
}

function convertUserToUserPreview(user: IFirebaseUser): IUserPreview {
  return pick(user, ['id', 'anonymous', 'username', 'imageUrl']);
}
