import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { RootAction } from '../../store/root';
import * as actions from './actions';
import { IAsyncError, INotificationPreferences } from '../../common';

export interface IEditNotificationPreferences {
  isDialogOpen: boolean;
  isLoading: boolean;
  error: IAsyncError | null;
}

export interface INotificationState {
  fcmToken: string | null;
  fcmTokenUpdatedAt: Date | null;
  notificationPreferences: INotificationPreferences | null;
  editNotificationPreferences: IEditNotificationPreferences;
}

const defaultState: INotificationState = {
  fcmToken: null,
  fcmTokenUpdatedAt: null,
  notificationPreferences: null,
  editNotificationPreferences: {
    isDialogOpen: false,
    isLoading: false,
    error: null,
  },
};

export const whitelist: (keyof INotificationState)[] = ['fcmToken', 'fcmTokenUpdatedAt', 'notificationPreferences'];

export const notificationReducer = createReducer<INotificationState, RootAction>(defaultState)
  .handleAction(actions.requestFcmToken.success, (state, action) =>
    produce(state, draft => {
      draft.fcmToken = action.payload;
      draft.fcmTokenUpdatedAt = new Date();
    })
  )
  .handleAction(actions.deleteFcmToken.success, (state, action) =>
    produce(state, draft => {
      draft.fcmToken = null;
      draft.fcmTokenUpdatedAt = null;
    })
  )
  .handleAction(actions.fetchNotificationPreferences.success, (state, action) =>
    produce(state, draft => {
      draft.notificationPreferences = action.payload;
    })
  )
  .handleAction(actions.updateNotificationPreferences.prompt, state =>
    produce(state, draft => {
      draft.editNotificationPreferences = {
        isDialogOpen: true,
        isLoading: false,
        error: null,
      };
    })
  )
  .handleAction(actions.updateNotificationPreferences.request, state =>
    produce(state, draft => {
      draft.editNotificationPreferences.isLoading = true;
    })
  )
  .handleAction(actions.updateNotificationPreferences.success, (state, action) =>
    produce(state, draft => {
      draft.notificationPreferences = action.payload;
      draft.editNotificationPreferences.isLoading = false;
      draft.editNotificationPreferences.isDialogOpen = false;
    })
  )
  .handleAction(actions.updateNotificationPreferences.failure, (state, action) =>
    produce(state, draft => {
      draft.editNotificationPreferences.isLoading = false;
      draft.editNotificationPreferences.error = action.payload;
    })
  )
  .handleAction(actions.updateNotificationPreferences.canceled, (state, action) =>
    produce(state, draft => {
      draft.editNotificationPreferences.isLoading = false;
      draft.editNotificationPreferences.error = null;
      draft.editNotificationPreferences.isDialogOpen = false;
    })
  );
