import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider, ShareData } from '../../../services';
import { commonActions } from '../../common';
import selectors from '../selectors';
import routes from '../../utils/routes';

const copyCodeSaga = takeEvery(getType(actions.share.code), function* () {
  const tourCode: string = yield select(selectors.selectTourCode);
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  serviceProvider.shareService.copyToClipboard(`#${tourCode}`);
  yield put(commonActions.showSnackbar({ key: 'create_tour.share.tour_code_success' }));
});

const shareLinkSaga = takeEvery(getType(actions.share.link), function* () {
  const tourCode: string = yield select(selectors.selectTourCode);
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  const tourLink = routes.generateAbsoluteTourLink(tourCode);

  if (serviceProvider.shareService.supportsSharing()) {
    try {
      yield call(serviceProvider.shareService.share, {
        // title: {key: 'create_tour.share.web_share.title'},
        // text: {key: 'create_tour.share.web_share.text'},
        url: tourLink,
      } as ShareData);
    } catch (e) {
      // user canceled sharing, nothing needs to be done
    }
  } else {
    serviceProvider.shareService.copyToClipboard(tourLink);
    yield put(commonActions.showSnackbar({ key: 'create_tour.share.tour_link_success' }));
  }
});

export default [copyCodeSaga, shareLinkSaga];
