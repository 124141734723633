import config from '../../config';

/**
 * Always returns noindex,nofollow for non-prod-environments
 * @param valueForProd
 */
export function getRobotsValue(valueForProd: string) {
  if (config.projectId !== 'lialo-com') {
    return 'noindex,nofollow';
  }

  return valueForProd;
}

export function cleanMetaDescription(text?: string): string | undefined {
  if (!text) return;

  // remove linebreaks
  return text.replace(/(\r\n|\n|\r)/gm, '');
}
