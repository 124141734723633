import { ActionType, StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { connectRouter, RouterAction } from 'connected-react-router';
import { History } from 'history';
import { navigationActions, navigationReducer, navigationSagas } from '../pages/common/navigation';
import {
  createTourActions,
  createTourMigrations,
  createTourReducer,
  createTourSagas,
  createTourWhitelist,
} from '../pages/createTour';
import { Storage } from 'redux-persist/es/types';
import { createMigrate, persistReducer } from 'redux-persist';
import { findTourActions, findTourReducer, findTourSagas, findTourWhitelist } from '../pages/findTour';
import { publicProfileActions, publicProfileReducer, publicProfileSagas } from '../pages/publicProfile';
import { IMyToursState, myToursActions, myToursReducer, myToursSagas } from '../pages/myTours';
import {
  playTourActions,
  playTourMigrations,
  playTourReducer,
  playTourSagas,
  playTourWhitelist,
} from '../pages/playTour';
import { appActions, appBlacklist, appMigrations, appReducer, appSagas } from '../pages/app';
import { tourDetailActions, tourDetailReducer, tourDetailSagas } from '../pages/tourDetail';
import { dateTransform } from './transformers';
import { commonActions, commonReducer, commonSagas } from '../pages/common';
import { profileActions, profileReducer, profileSagas } from '../pages/profile';
import { tourContentReducer, tourContentSagas } from '../pages/tourContent';
import { appFeedbackActions, appFeedbackReducer, appFeedbackSagas } from '../pages/appFeedback';
import { landingPageActions, landingPageReducer, landingPageSagas } from '../pages/landing';
import {
  tourStopOfflineStatusActions,
  tourStopOfflineStatusReducer,
  tourStopOfflineStatusSagas,
} from '../pages/tourStopOfflineStatus';
import {
  followAuthorActions,
  followAuthorReducer,
  followAuthorSagas,
  followAuthorWhitelist,
} from '../pages/followAuthor';
import {
  notificationActions,
  notificationReducer,
  notificationSagas,
  notificationWhitelist,
} from '../pages/notifications';

function createMigrateAndVersion(migrations: any) {
  const persistVersions = Object.keys(migrations).map(m => parseInt(m));
  return {
    version: Math.max(...persistVersions),
    migrate: createMigrate(migrations),
  };
}

function createRootReducer(history: History, storage: Storage) {
  const appPersistConfig = {
    key: 'app',
    blacklist: appBlacklist,
    transforms: [dateTransform],
    storage,
    ...createMigrateAndVersion(appMigrations),
  };

  const createTourPersistConfig = {
    key: 'createTour',
    whitelist: createTourWhitelist,
    transforms: [dateTransform],
    storage,
    ...createMigrateAndVersion(createTourMigrations),
  };

  const findTourPersistConfig = {
    key: 'findTour',
    whitelist: findTourWhitelist,
    transforms: [dateTransform],
    storage,
  };

  const playTourPersistConfig = {
    key: 'playTour',
    whitelist: playTourWhitelist,
    transforms: [dateTransform],
    storage,
    ...createMigrateAndVersion(playTourMigrations),
  };

  const myToursWhitelist: (keyof IMyToursState)[] = ['playedTours', 'createdTours'];
  const myToursPersistConfig = {
    key: 'myTours',
    whitelist: myToursWhitelist,
    transforms: [dateTransform],
    storage,
  };

  const followAuthorPersistConfig = {
    key: 'followAuthor',
    whitelist: followAuthorWhitelist,
    transforms: [dateTransform],
    storage,
  };

  const notificationPersistConfig = {
    key: 'notification',
    whitelist: notificationWhitelist,
    transforms: [dateTransform],
    storage,
  };

  const combinedReducer = combineReducers({
    router: connectRouter(history),
    app: persistReducer(appPersistConfig, appReducer),
    previousLocation: navigationReducer,
    createTour: persistReducer(createTourPersistConfig, createTourReducer),
    findTour: persistReducer(findTourPersistConfig, findTourReducer),
    playTour: persistReducer(playTourPersistConfig, playTourReducer),
    myTours: persistReducer(myToursPersistConfig, myToursReducer),
    followAuthor: persistReducer(followAuthorPersistConfig, followAuthorReducer),
    notification: persistReducer(notificationPersistConfig, notificationReducer),
    common: commonReducer,
    tourDetail: tourDetailReducer,
    profile: profileReducer,
    publicProfile: publicProfileReducer,
    appFeedback: appFeedbackReducer,
    tourContent: tourContentReducer,
    landingPage: landingPageReducer,
    tourStopOfflineStatus: tourStopOfflineStatusReducer,
  });

  return combinedReducer;
}

export function createPersistedReducer(history: History<any>, storage: Storage) {
  const rootReducer = createRootReducer(history, storage);

  const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
      'router',
      'previousLocation',
      'app',
      'createTour',
      'findTour',
      'playTour',
      'myTours',
      'followAuthor',
      'notification',
      'common',
      'profile',
      'publicProfile',
      'tourContent',
      'appFeedback',
      'tourDetail',
      'landingPage',
    ],
  };

  return persistReducer(persistConfig, rootReducer);
}

export function* rootSaga() {
  yield all([
    ...navigationSagas,
    ...createTourSagas,
    ...findTourSagas,
    ...myToursSagas,
    ...playTourSagas,
    ...tourDetailSagas,
    ...profileSagas,
    ...publicProfileSagas,
    ...tourContentSagas,
    ...appFeedbackSagas,
    ...commonSagas,
    ...appSagas,
    ...landingPageSagas,
    ...followAuthorSagas,
    ...notificationSagas,
    ...tourStopOfflineStatusSagas,
  ]);
}

export type RootState = StateType<ReturnType<typeof createRootReducer>>;

export type RootAction =
  | ActionType<typeof navigationActions>
  | ActionType<typeof createTourActions>
  | ActionType<typeof findTourActions>
  | ActionType<typeof playTourActions>
  | ActionType<typeof myToursActions>
  | ActionType<typeof tourDetailActions>
  | ActionType<typeof profileActions>
  | ActionType<typeof publicProfileActions>
  | ActionType<typeof commonActions>
  | ActionType<typeof appActions>
  | ActionType<typeof appFeedbackActions>
  | ActionType<typeof commonActions>
  | ActionType<typeof landingPageActions>
  | ActionType<typeof followAuthorActions>
  | ActionType<typeof notificationActions>
  | ActionType<typeof tourStopOfflineStatusActions>
  | RouterAction;
