import { AvailableSettings } from '../../models';
import { stringLiterals } from '../../utils/types/utilTypes';
import { equals } from '../../utils/helper/deep-equal';
import { ITourFilter, ITourListItemVM } from '../../common';
import { Language } from '@lialo/common/lib/language';

export enum TourMarkerType {
  DEFAULT,
  RUNNING,
  COMPLETED,
}

export enum ViewMode {
  SLIDER = 'slider',
  LIST = 'list',
}

export interface ITourSummaryVM extends ITourListItemVM {
  isSelected: boolean;
  status: TourMarkerType;
}

export const AvailableFilters = {
  audience: stringLiterals('KIDS', 'ADULTS'),
  environment: AvailableSettings['environments'],
  durations: AvailableSettings['duration'],
  distances: AvailableSettings['distance'],
  occasions: AvailableSettings['occasions'],
  categories: AvailableSettings['categories'],
  languages: [Language.de, Language.en, Language.fr, Language.it, Language.es],
};

export function createTourFilterFromParams(params: Record<string, any>): ITourFilter {
  const filters: any = { ...defaultFilters };
  const availableFilters: Record<any, string[]> = AvailableFilters;
  Object.entries(params).forEach(([key, value]) => {
    if (filters.hasOwnProperty(key)) {
      const k = key as keyof ITourFilter;
      // boolean filters
      if ((key === 'handicappedAccessible' || key === 'roundTrip') && value === true) {
        filters[k] = value;
      }
      // filter is array, but only one is selected
      else if (Array.isArray(filters[k]) && typeof value === 'string' && availableFilters[k]?.includes(value)) {
        filters[k] = [value];
      }
      // filter is array and multiple are selected
      else if (Array.isArray(filters[k]) && Array.isArray(value) && availableFilters[k]?.includesAll(value)) {
        filters[k] = value;
      }
      // filter is single select
      else if (typeof value === 'string' && availableFilters[k]?.includes(value)) {
        filters[k] = value;
      }
    }
  });

  return filters;
}

export function hasAppliedFilters(filters: ITourFilter): boolean {
  return !equals(filters, defaultFilters);
}

export function countAppliedFilter(filters: ITourFilter): number {
  return Object.values(filters).reduce((prevCount: number, filterValue) => {
    const thisCount = Array.isArray(filterValue) ? filterValue.length : filterValue ? 1 : 0;
    return prevCount + thisCount;
  }, 0);
}

export const defaultFilters: ITourFilter = {
  languages: [],
  audience: null,
  categories: [],
  distances: [],
  durations: [],
  occasions: [],
  handicappedAccessible: undefined,
  roundTrip: undefined,
  playAtHome: false,
};

export const filterSections: Array<keyof ITourFilter> = [
  'audience',
  'durations',
  'distances',
  'occasions',
  'categories',
  'handicappedAccessible',
  'roundTrip',
];
