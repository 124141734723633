import { actionChannel, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { ICreateTourState } from '../models/state';
import { mergeStateIntoTour } from '../utils/mergeStateIntoTour';
import actions from '../actions';
import { getType, PayloadMetaAction, TypeConstant } from 'typesafe-actions';
import selectors from '../selectors';
import { requireUser, RequireUserResult } from '../../utils/sagas';
import { ICreateTourModel } from '../models/Tour';
import { showErrorSnackbar } from '../../common/utils/showSnackbar';
import { AsyncError, ErrorType } from '../../../common';
import { showErrorDialog } from '../../common/utils/showDialog';

const triggerSave = takeEvery(
  [
    getType(actions.updateTourField),
    getType(actions.updateTourStopField),
    getType(actions.deleteTourStop),
    getType(actions.deleteImage),
    getType(actions.reorderImages),
    getType(actions.reorderTourStops),
    getType(actions.updatePreviewTourStop),
  ],
  function* (action: PayloadMetaAction<TypeConstant, any, { save: boolean }>) {
    if (action.meta.save) {
      yield put(actions.saveTourAsync.request());
    }
  }
);

function* saveTour() {
  // discard duplicate actions in the buffer
  const requestChannel = yield actionChannel([getType(actions.saveTourAsync.request)], buffers.sliding(1));
  while (true) {
    const action: ReturnType<typeof actions.saveTourAsync.request> = yield take(requestChannel);
    yield call(updateAndSaveTour);
  }
}

function* updateAndSaveTour() {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  const currentState: ICreateTourState = yield select(selectors.selectCreateTourState);
  const tour: ICreateTourModel | undefined = currentState.tour;
  const result: RequireUserResult = yield requireUser();

  if (!result.user) {
    yield put(actions.saveTourAsync.failure(result.error));
  } else {
    const tourState = currentState.tourState;
    const updatedTour = mergeStateIntoTour(tourState, tour, result.user);

    try {
      const tourResponse: ICreateTourModel = yield updatedTour.id > 0
        ? serviceProvider.api.updateTour(updatedTour)
        : serviceProvider.api.createTour(updatedTour);
      yield put(actions.saveTourAsync.success(tourResponse));
    } catch (error) {
      yield put(actions.saveTourAsync.failure(error));
      shouldShowErrorDialog(error) ? yield showErrorDialog(error) : yield showErrorSnackbar(error);
      serviceProvider.analyticsService.logError(error);
    }
  }
}

function shouldShowErrorDialog(error: Error): boolean {
  return (
    error instanceof AsyncError &&
    [ErrorType.TOUR_VALIDATION_FAILED, ErrorType.TOUR_ALREADY_SOLD].indexOf(error.errorCode) >= 0
  );
}

export default [call(saveTour), triggerSave];
