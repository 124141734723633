export enum ErrorType {
  // Common Errors
  NETWORK = 'Network',
  USER_CANCELED = 'UserCanceled',
  UNKNOWN = 'Unknown',

  // Client
  CACHING_FAILED = 'CachingFailed',
  LOCATION_POSITION_UNAVAILABLE = 'LocationPositionUnavailable',
  LOCATION_PERMISSION_DENIED = 'LocationPermissionDenied',
  LOCATION_TIMEOUT = 'LocationTimeout',
  SIGN_OUT = 'SignOut',
  INVALID_FILE = 'InvalidFile',
  ZERO_RESULTS = 'ZeroResults',
  SERVICE_WORKER = 'ServiceWorker',
  TOUR_RUNNING = 'TourRunning',
  TOUR_NOT_COMPLETED = 'TourNotCompleted',
  UNSUPPORTED = 'Unsupported',
  CACHE_FILE_FAILED = 'CacheFileFailed',

  // Server
  USER_NOT_FOUND = 'UserNotFound',
  JSON_PARSE_ERROR = 'JsonParseError',
  TOUR_NOT_FOUND = 'TourNotFound',
  TOUR_VALIDATION_FAILED = 'TourValidationFailed',
  VALIDATION_FAILED = 'ValidationFailed',
  UNREVIEWED_TOUR = 'UnreviewedTour',
  BAD_REQUEST = 'BadRequest',
  INTERNAL_SERVER_ERROR = 'InternalServerError',
  UNSUPPORTED_MEDIA_TYPE = 'UnsupportedMediaType',
  UNAUTHORIZED = 'Unauthorized',
  FORBIDDEN = 'Forbidden',
  FORBIDDEN_TOUR_MODIFICATION = 'ForbiddenTourModification',
  DUBLICATE = 'Dublicate',
  ANONYMOUS_USER = 'AnonymousUser',
  UNAUTHENTICATED = 'Unauthenticated',
  TOUR_NOT_UNLOCKED = 'TourNotUnlocked',
  SEND_GRID_ERROR = 'SendGridError',
  CONTRACT_VERSION_TOO_LOW = 'ContractVersionTooLow',
  TOUR_DELETION_NOT_ALLOWED = 'TourDeletionNotAllowed',
  TOUR_SYNC = 'TourSync',
  USER_DELETION_SYNC = 'UserDeletionSync',
  USER_DELETION_NOT_ALLOWED = 'UserDeletionNotAllowed',
  TOUR_ALREADY_SOLD = 'TourAlreadySold',
  TRANSLATION_FAILED = 'TranslationFailed',
  TRANSLATION_QUOTA_EXCEEDED = 'TranslationQuotaExceeded',

  //Firebase-Auth-Errors
  FIREBASE = 'Firebase', // unspecific firebase error
  USER_IS_NULL = 'UserIsNull',
  EMAIL_ALREADY_IN_USE = 'EmailAlreadyInUse',
  USER_DISABLED = 'UserDisabled',
  TOKEN_EXPIRED = 'TokenExpired',
  INVALID_EMAIL = 'InvalidEmail',
  WRONG_PASSWORD = 'WrongPassword',
  WEAK_PASSWORD = 'WeakPassword',
  RECENT_LOGIN_REQUIRED = 'RecentLoginRequired',
  USER_MISMATCH = 'UserMismatch',
  TOO_MANY_REQUESTS = 'TooManyRequests',

  // FCM
  FCM_UNSUPPORTED = 'FcmUnsupported',
  FCM_PERMISSION_NOT_GIVEN = 'FcmPermissionNotGiven',
  FCM_TOKEN_SUBSCRIBE_FAILED = 'FcmTokenSubscribeFailed',
  FCM_OTHER = 'FcmOther',
}

export interface IAsyncError {
  errorCode: ErrorType;
  devDescription: string;
}

export class AsyncError extends Error implements IAsyncError {
  public errorCode: ErrorType;
  public devDescription: string;

  constructor(code: ErrorType, description = '') {
    super(description);
    Object.setPrototypeOf(this, AsyncError.prototype);
    this.errorCode = code;
    this.devDescription = description;
  }
}
