import { getType } from 'typesafe-actions';
import actions from '../actions';
import { put, takeLatest } from 'redux-saga/effects';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { takeConfirmation } from '../../utils/sagas';
import { commonActions } from '../../common';
import routes from '../../utils/routes';
import reauthenticateWithPassword from '../../common/utils/reauthenticateWithPassword';
import { showErrorSnackbar } from '../../common/utils/showSnackbar';
import { AsyncError, ErrorType } from '../../../common';
import { showErrorDialog } from '../../common/utils/showDialog';

const deleteProfileSaga = takeLatest(getType(actions.deleteProfile.request), function* () {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    const canBeDeleted = yield serviceProvider.api.canDeleteUser();
    if (!canBeDeleted) {
      throw new AsyncError(ErrorType.USER_DELETION_NOT_ALLOWED, 'User has non-private tours');
    }

    const confirmed = yield takeConfirmation({
      content: { key: 'profile.delete.dialog_text' },
      positiveKey: 'actions.continue',
    });

    if (!confirmed) {
      yield put(actions.deleteProfile.cancel());
      return;
    }

    yield reauthenticate();
    yield serviceProvider.api.deleteCurrentUser();
    yield deleteLocally();

    yield put(commonActions.signOut.success());
    yield put(commonActions.showSnackbar({ key: 'profile.delete.delete_completed' }));
    serviceProvider.analyticsService.logDeleteProfile();
    serviceProvider.navigationService.navigateI18n(routes.ROOT, { replace: true });
  } catch (e) {
    yield put(actions.deleteProfile.failure(e));
    if (e instanceof AsyncError && e.errorCode !== ErrorType.USER_CANCELED) {
      serviceProvider.analyticsService.logError(e);
      e.errorCode === ErrorType.USER_DELETION_NOT_ALLOWED ? yield showErrorDialog(e) : yield showErrorSnackbar(e);
    }
  }
});

function* reauthenticate() {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService = yield serviceProvider.getAuthService();
  if (authService.isOAuthUser) {
    yield authService.reauthenticateWithPopup();
  } else {
    yield reauthenticateWithPassword();
  }
}

function* deleteLocally() {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService = yield serviceProvider.getAuthService();

  yield authService.deleteCurrentUser();
  yield put(actions.deleteProfile.success());
}

export default [deleteProfileSaga];
