import { useLocation } from 'react-router-dom';
import { Location } from 'history';
import React from 'react';
import { useServiceProvider } from '../Providers';

export default function useLogScreenName() {
  const location = useLocation();
  const { analyticsService } = useServiceProvider();
  const previousLocation = React.useRef<Location | undefined>();
  const timeoutId = React.useRef<number | null>(null);

  // dismiss pending events to prevent tracking of redirects
  const logScreenView = React.useCallback(
    (screenName: string) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = window.setTimeout(() => {
        timeoutId.current = null;
        analyticsService.logScreenView(screenName);
      }, 20);
    },
    [analyticsService]
  );

  // Fired on every route change
  React.useEffect(() => {
    if (location.pathname !== previousLocation.current?.pathname) {
      logScreenView(location.pathname);
    }
    previousLocation.current = location;
  }, [logScreenView, location]);
}
