import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { IAsyncActionState, reduceRequestAction } from '../utils';
import actions from './actions';
import { RootAction } from '../../store/root';
import { ITourSummary } from '../../models/';
import { addOrRemove, normalize } from '../../utils/helper/array';
import { commonActions } from '../common';
import { createTourFilterFromParams, defaultFilters } from './models';
import { ErrorType, ITourFilter } from '../../common';

export interface IFindTourState extends IAsyncActionState {
  tours: Record<string, ITourSummary>;
  currentFilters: ITourFilter;
  appliedFilters: ITourFilter;
  selectedTour?: string;
  isListOpen: boolean;
}

const defaultState: IFindTourState = {
  isLoading: false,
  currentFilters: defaultFilters,
  appliedFilters: defaultFilters,
  isListOpen: false,
  tours: {},
};

const findTourReducer = createReducer<IFindTourState, RootAction>(defaultState)
  .handleAction([actions.fetchInitialBounds.request, actions.fetchNearbyTours.start], reduceRequestAction)
  .handleAction([actions.fetchInitialBounds.failure, actions.fetchNearbyTours.failure], (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      if ([ErrorType.ZERO_RESULTS, ErrorType.TOUR_NOT_FOUND].includes(action.payload.errorCode)) {
        draft.tours = {};
        draft.selectedTour = undefined;
        draft.isListOpen = false;
      }
    })
  )
  .handleAction(actions.fetchNearbyTours.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.tours = normalize(action.payload, t => t.shortLink);
      if (draft.selectedTour && draft.tours[draft.selectedTour] === undefined) {
        draft.selectedTour = undefined;
      }
    })
  )
  .handleAction(actions.fetchInitialBounds.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
    })
  )
  .handleAction(actions.selectTour, (state, action) =>
    produce(state, draft => {
      draft.selectedTour = action.payload;
    })
  )
  .handleAction(actions.toggleFilter, (state, action) =>
    produce(state, draft => {
      const { key, value } = action.payload;
      const filtername = key as keyof ITourFilter;
      const filters = draft.currentFilters;
      const filterValue = filters[filtername];

      if (Array.isArray(filterValue)) {
        (filters[filtername] as any) = addOrRemove(filterValue, value);
      }
      if (filterValue === null || typeof filterValue === 'string') {
        (filters[filtername] as any) = filterValue === value ? null : value;
      }
      if (['roundTrip', 'handicappedAccessible'].includes(filtername)) {
        (filters[filtername] as any) = filterValue === true ? undefined : true;
      }
    })
  )
  .handleAction(actions.applyFilters, (state, action) =>
    produce(state, draft => {
      draft.appliedFilters = draft.currentFilters;
    })
  )
  .handleAction(actions.resetFilters, (state, action) =>
    produce(state, draft => {
      draft.currentFilters = defaultFilters;
      if (action.meta.apply) {
        draft.appliedFilters = defaultFilters;
      }
    })
  )
  .handleAction(actions.dismissDialog, (state, action) =>
    produce(state, draft => {
      draft.currentFilters = draft.appliedFilters;
    })
  )
  .handleAction(actions.hydrateFilters, (state, action) =>
    produce(state, draft => {
      const params = action.payload;
      draft.appliedFilters = draft.currentFilters = createTourFilterFromParams(params);
      draft.selectedTour = params.selectedTour;
      draft.isListOpen = params.mode === 'list';
    })
  )
  .handleAction(actions.toggleList, (state, action) =>
    produce(state, draft => {
      draft.isListOpen = !draft.isListOpen;
    })
  )
  .handleAction(commonActions.signOut.success, (state, action) => ({
    ...defaultState,
  }));

export default findTourReducer;
