import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, appSelectors } from '@lialo/core/lib/pages/app';
import { LoadingMessage } from '@lialo/pwa-ui/lib/Messages/LoadingMessage';

const AppUpdateDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isOpen = useSelector(appSelectors.selectShowUpdateDialog);
  const updateAvailable = useSelector(appSelectors.selectIsAppUpdateAvailable);

  const cancel = () => dispatch(appActions.appUpdate.cancel());
  const refresh = () => dispatch(appActions.appUpdate.updateApp());

  return (
    <Dialog onClose={cancel} open={isOpen} aria-labelledby="form-dialog-title">
      <DialogContent>
        <DialogContentText>
          {t('app_update_dialog.message')}
          {!updateAvailable && <LoadingMessage mt={2} message={t('app_update_dialog.loading_message')} />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary">
          {t('actions.cancel')}
        </Button>
        <Button onClick={refresh} color="primary" disabled={!updateAvailable}>
          {t('actions.refresh')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppUpdateDialog;
