import { Language } from "@lialo/common/lib/language";
import { ITourState } from '../models/state';
import { TourVisibility } from '../../../models';
import ReviewStatus from '../../../models/ReviewStatus';
import { ImageType } from '../models/Image';

export function createFakeTourState(): ITourState {
  return {
    id: 0,
    language: Language.de,
    title: `Test-Tour ${new Date().toLocaleString()}`,
    startLocation: { lat: 52.51957483826324, lng: 13.40686438961502 },
    description: 'Eine Test-Tour',
    startText: 'Willkommen zur automatisch generierten Test-Tour',
    endText: 'Du bist am Ende angelangt.',
    images: [
      {
        id: 4,
        orderId: 0,
        localId: 1,
        url:
          'https://www.googleapis.com/download/storage/v1/b/lialo-com-dev.appspot.com/o/f5dc99cc-25e9-427f-bc94-adaa4851dd8c.jpeg?generation=1582650784674312&alt=media',
        filename: 'test.jpg',
        type: ImageType.MAIN,
      },
    ],
    settings: {
      season: 'SUMMER',
      audience: 'ADULTS',
      categories: ['ART'],
      contentTypes: ['CHALLENGE'],
      distance: 'MEDIUM',
      duration: 'SHORT',
      environments: ['OUTDOOR'],
      meansOfTransport: 'BY_FOOT',
      occasions: ['SIGHTSEEING'],
      handicappedAccessible: true,
      shoppingFacilities: true,
      playAtHome: false,
    },
    visibility: TourVisibility.PRIVATE,
    reviewStatus: ReviewStatus.NONE,
    isRoundTrip: true,
    stops: [
      {
        title: 'Ein Tour-Stopp',
        information: 'Information zum Tour-Stopp',
        directions: 'Hier gehts lang zum Stopp.',
        orderId: 1,
        localId: 1,
        id: 0,
        images: [],
        lookIntoTour: false,
        waypointsEnabled: false,
        task: {
          id: 0,
          images: [],
          question: 'Springe auf den Stuhl!',
          answer: 'Yes, I did it!',
        },
      },
    ],
  };
}
