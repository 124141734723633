import { pick } from '../../../utils/helper/object';
import { arrayToObject } from '../../../utils/helper/array';
import { ITourSettings } from '../../../models';
import { NonBooleanPropertyKeys } from '../../../utils/types/utilTypes';
import { IImageState, isEmptyTaskState, ISingleChoiceTaskState, ITourState, ITourStopState } from '../models/state';
import ReviewStatus from '../../../models/ReviewStatus';
import { ImageType } from '../models/Image';

export interface ISimpleTaskValidation {
  isQuestionValid: boolean;
  isAnswerValid: boolean;
}

export interface ISingleChoiceTaskValidation {
  isQuestionValid: boolean;
  isAnswerValid: boolean;
  isWrongAnswer1Valid: boolean;
  isWrongAnswer2Valid: boolean;
  isWrongAnswer3Valid: boolean;
  areImagesValid?: boolean;
}

export interface ITourStopValidation {
  isValid: boolean;
  isTaskValid?: boolean;
  isTitleValid: boolean;
  isDirectionsValid: boolean;
  areDirectionImagesValid?: boolean;
  isInformationValid: boolean;
  areInformationImagesValid?: boolean;
  areWaypointsValid: boolean;
  taskValidation?: ISingleChoiceTaskValidation;
}

export interface ITourValidation {
  isTourValid: boolean;
  isTitleValid: boolean;
  isStartLocationValid: boolean;
  isEndLocationValid?: boolean;
  areMainImagesValid: boolean;
  areStopsValid: boolean;
  isDescriptionValid: boolean;
  isStartTextValid: boolean;
  isStartValid: boolean;
  areStartImagesValid?: boolean;
  isEndTextValid: boolean;
  isEndValid: boolean;
  areEndImagesValid?: boolean;
  stopsValidation: Record<number, ITourStopValidation>;
  areSettingsValid: boolean;
  settingsValidation?: SettingsValidation;
}

export type SettingsValidation = Record<NonBooleanPropertyKeys<ITourSettings>, boolean>;

export function validate(state: ITourState): ITourValidation {
  return validateTourState(state);
}

export function validateTourState(state: ITourState): ITourValidation {
  const stopValidation = arrayToObject(state.stops, item => item.localId, validateTourStopState);

  const areStopsValid =
    Object.keys(stopValidation).length >= 1 &&
    Object.values(stopValidation).every(validation => {
      const validatedFields = pick(validation, [
        'isTitleValid',
        'isDirectionsValid',
        'isInformationValid',
        'isTaskValid',
        'areWaypointsValid',
      ]);
      return allFieldsValid(validatedFields);
    });

  const settingsValidation = validateSettings(state.settings);

  const fields = {
    isTitleValid: notEmpty(state.title),
    isDescriptionValid: notEmpty(state.description),
    areMainImagesValid: imagesValid(state.images.filter(i => i.type === ImageType.MAIN)) ?? false,
    isStartTextValid: notEmpty(state.startText),
    areStartImagesValid: imagesValid(state.images.filter(i => i.type === ImageType.START)),
    isEndTextValid: notEmpty(state.endText),
    areEndImagesValid: imagesValid(state.images.filter(i => i.type === ImageType.END)),
    isStartLocationValid: notUndefined(state.startLocation),
    isEndLocationValid: state.isRoundTrip ? true : !!state.endLocation,
    areStopsValid: areStopsValid,
    areSettingsValid: state.reviewStatus === ReviewStatus.REVIEWED ? allFieldsValid(settingsValidation) : true,
  };

  return {
    isTourValid: allFieldsValid(fields),
    stopsValidation: stopValidation,
    isStartValid: fields.isStartTextValid && fields.isStartLocationValid,
    isEndValid: fields.isEndTextValid && fields.isEndLocationValid,
    settingsValidation,
    ...fields,
  };
}

function validateSettings(settings: ITourSettings): SettingsValidation {
  return {
    duration: notNull(settings.duration),
    distance: notNull(settings.distance),
    audience: notNull(settings.audience),
    season: notNull(settings.season),
    meansOfTransport: notNull(settings.meansOfTransport),
    categories: atLeastOne(settings.categories),
    occasions: atLeastOne(settings.occasions) || settings.playAtHome,
    contentTypes: atLeastOne(settings.contentTypes),
    environments: atLeastOne(settings.environments),
  };
}

function validateTourStopState(state: ITourStopState): ITourStopValidation {
  const taskValidation = isEmptyTaskState(state.task) ? undefined : validateTaskState(state.task);

  const fields = {
    isTitleValid: notEmpty(state.title),
    isDirectionsValid: notEmpty(state.directions),
    areDirectionImagesValid: imagesValid(state.images.filter(i => i.type === ImageType.DIRECTIONS)),
    areWaypointsValid: state.waypointsEnabled ? !!state.waypoints && atLeastOne(state.waypoints) : true,
    isInformationValid: notEmpty(state.information),
    areInformationImagesValid: imagesValid(state.images.filter(i => i.type === ImageType.INFORMATION)),
    isTaskValid: taskValidation ? allFieldsValid(taskValidation) : undefined,
  };

  return {
    isValid: allFieldsValid(fields),
    taskValidation: taskValidation,
    ...fields,
  };
}

function validateTaskState(state: ISingleChoiceTaskState): ISingleChoiceTaskValidation {
  return {
    isQuestionValid: notEmpty(state.question),
    isAnswerValid: notEmpty(state.answer),
    isWrongAnswer1Valid: true,
    isWrongAnswer2Valid: true,
    isWrongAnswer3Valid: true,
    areImagesValid: imagesValid(state.images),
  };
}

export function imagesValid(images: IImageState[]): boolean | undefined {
  return images.length > 0 ? true : undefined;
}

export function notUndefined(val?: any): boolean {
  return !!val;
}

export function notNull(val: any | null): boolean {
  return val !== null;
}

export function undefinedOrEmpty(val?: string): boolean {
  return val === undefined || val.trim().length === 0;
}

export function notEmpty(val?: string): boolean {
  return !undefinedOrEmpty(val);
}

export function atLeastOne(val: any[]): boolean {
  return val.length > 0;
}

function allFieldsValid<T extends { [K in keyof T]: boolean | undefined }>(obj: T): boolean {
  return !Object.values(obj).some(val => val === false);
}
