import { styled } from '@mui/material/styles';
import React from 'react';
import { LinearProgress } from '@mui/material';

const EmptyDiv = styled('div')(() => ({
  height: 4,
}));

interface IProps {
  isLoading: boolean;
  className?: string;
}

export const LoadingIndicator = (props: IProps) => {
  return props.isLoading ? <LinearProgress className={props.className} color={'secondary'} /> : <EmptyDiv />;
};
