import { IShareService, ShareData as IShareData } from '@lialo/core/lib/services';
import i18n from '../i18n';

export default class ShareService implements IShareService {
  constructor() {
    this.supportsSharing = this.supportsSharing.bind(this);
    this.share = this.share.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  supportsSharing(): boolean {
    return !!navigator.share;
  }

  share(data: IShareData): Promise<void> {
    if (!this.supportsSharing()) {
      throw new Error('Share-API not available');
    }

    const translatedData: ShareData = {
      title: data.title ? i18n.t(data.title.key, data.title.params) : undefined,
      text: data.text ? i18n.t(data.text.key, data.text.params) : undefined,
      url: data.url,
    };

    return navigator.share!(translatedData);
  }

  copyToClipboard(text: string): void {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}
