import { IImage } from '../../../models';

export interface ICreateImageModel {
  id: number;
  localId: number;
  orderId: number;
  url?: string;
  filename?: string;
  type: ImageType;
  caption?: string;
}

export interface IImageList {
  id: number;
  images: ICreateImageModel[];
}

export enum ImageType {
  MAIN = 'MAIN',
  START = 'START',
  END = 'END',
  DIRECTIONS = 'DIRECTIONS',
  INFORMATION = 'INFORMATION',
  TASK = 'TASK',
}

export function convertToImages(imageList: IImageList | undefined, type: ImageType): IImage[] {
  if (!imageList) return [];
  return imageList.images?.filter(img => img.type === type)?.mapNotOptional(it => convertToImage(it)) ?? [];
}

export function convertToImage(createImageModel: ICreateImageModel): IImage | null {
  if (createImageModel.url) {
    return {
      url: createImageModel.url,
      orderId: createImageModel.orderId,
      caption: createImageModel.caption,
    };
  } else {
    return null;
  }
}
