import { put } from 'redux-saga/effects';
import { commonActions } from '../index';
import { IAsyncError, TranslationTO } from '../../../common';
import { errorTranslationKey } from '../../../translations/translationHelper';

export function* showSnackbar(translation: TranslationTO) {
  yield put(commonActions.showSnackbar(translation));
}

export function* showErrorSnackbar(error: IAsyncError) {
  yield showSnackbar({
    key: errorTranslationKey(error),
  });
}
