import { getType } from 'typesafe-actions';
import { fork, put, select, takeEvery } from 'redux-saga/effects';
import actions from '../actions';
import { Chapter, getTitleImage, IStaticMapSize, ITour, TourProgressStatus } from '../../../models';
import selectors from '../selectors';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { requireUserOrThrow, takeConfirmation } from '../../utils/sagas';
import { commonActions, commonSelectors } from '../../common';
import { AsyncError, ErrorType } from '../../../common';
import { PlayTourParams } from '../utils/routerUtils';
import { showErrorSnackbar } from '../../common/utils/showSnackbar';

const initTour = takeEvery(getType(actions.initializeTour.request), function* (
  action: ReturnType<typeof actions.initializeTour.request>
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  const { params, imageWidth, staticMapSize, restartIfNecessary } = action.payload;
  try {
    yield requireUserOrThrow();
    const tour = validateTour(yield getOrFetchTour(params));
    const shouldResetTour = yield checkReset(tour, params, restartIfNecessary);
    yield startTour(tour, shouldResetTour, imageWidth, staticMapSize);
    yield fork(preloadAssets, params.tour);

    yield put(actions.initializeTour.success(tour.shortLink));
  } catch (e) {
    yield put(actions.initializeTour.failure(e));
    serviceProvider.analyticsService.logError(e);
  }
});

function* getOrFetchTour(params: PlayTourParams) {
  const tour: ITour | undefined = yield select(selectors.selectTour, params);
  const user = commonSelectors.selectUser(yield select());
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  const tourProgress = selectors.selectTourProgress(yield select(), params);

  // Return cached version, when tour is running and user has gone beyond start-chapter
  // or when device is offline
  if (
    tour &&
    tour.user.id !== user?.id && // authors always get the latest version
    ((tourProgress &&
      tourProgress.status === TourProgressStatus.running &&
      tourProgress.playbook?.currentPage.chapter !== Chapter.start) ||
      !serviceProvider.connectionService.online)
  ) {
    return tour;
  }

  try {
    const tour: ITour = yield serviceProvider.api.fetchTourByShortLink(params.tour, true);
    yield put(commonActions.fetchTourAsync.success(tour));
    return tour;
  } catch (e) {
    yield put(commonActions.fetchTourAsync.failure(e));
    serviceProvider.analyticsService.logError(e);
    throw e;
  }
}

function validateTour(tour: ITour) {
  if (!tour.valid) {
    throw new AsyncError(ErrorType.TOUR_VALIDATION_FAILED);
  }
  return tour;
}

function* checkReset(tour: ITour, params: PlayTourParams, restartIfNecessary: boolean) {
  const tourProgress = selectors.selectRunningTourProgress(yield select(), params);
  const tourWasChangedSinceStart = tourProgress && tour.updatedAt && tourProgress.startedAt < tour.updatedAt;

  if (tourWasChangedSinceStart) {
    const shouldResetTourProgress = yield takeConfirmation({
      content: { key: 'play_tour.reset_dialog.content' },
      positiveKey: 'play_tour.reset_dialog.confirm',
    });

    if (!shouldResetTourProgress) {
      throw new AsyncError(ErrorType.USER_CANCELED);
    }

    return true;
  }

  return restartIfNecessary && !tourProgress;
}

function* startTour(tour: ITour, reset: boolean, imageWidth: number, staticMapSize: IStaticMapSize) {
  const tourProgress = selectors.selectRunningTourProgress(yield select(), { tour: tour.shortLink! });
  if (!tourProgress || reset) {
    const request = {
      tourCode: tour.shortLink!,
      title: tour.title ?? '',
      mainImageUrl: getTitleImage(tour)?.url ?? '',
      imageWidth,
      staticMapSize,
      purchased: !!tour.price,
    };
    yield put(actions.openPlaybook(request, reset));
  }
}

function* preloadAssets(tourCode: string) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  if (!serviceProvider.cacheService.supportsCache()) {
    return;
  }

  try {
    const imageUrls: string[] = yield select(selectors.selectAllTourImages, { tour: tourCode });
    const staticMapUrls: string[] = yield select(selectors.selectStaticMapUrls, { tour: tourCode });
    const audioUrls: string[] = yield select(selectors.selectAllAudioUrls, { tour: tourCode });
    yield Promise.all([
      serviceProvider.cacheService.cacheNewFiles('image-cache', imageUrls.concat(staticMapUrls)),
      // Feature: Text-to-speech
      serviceProvider.cacheService.cacheNewFiles('audio-cache', audioUrls),
    ]);
  } catch (e) {
    console.warn('failed to add files to cache', e);
    serviceProvider.analyticsService.logError(e);
  }
}

export default [initTour];
