import { createAction, createAsyncAction } from 'typesafe-actions';
import { AsyncError, IAsyncError, INotificationPreferences } from '../../common';

export interface RequestFcmTokenPayload {
  permissionTranslationKey: string | false;
  silent: boolean;
}

export const requestFcmToken = createAsyncAction(
  '@notification/REQUEST_FCM_TOKEN_REQUEST',
  '@notification/REQUEST_FCM_TOKEN_SUCCESS',
  '@notification/REQUEST_FCM_TOKEN_FAILURE',
  '@notification/REQUEST_FCM_TOKEN_CANCELED'
)<RequestFcmTokenPayload, string, IAsyncError, void>();

export const deleteFcmToken = createAsyncAction(
  '@notification/DELETE_FCM_TOKEN_REQUEST',
  '@notification/DELETE_FCM_TOKEN_SUCCESS',
  '@notification/DELETE_FCM_TOKEN_FAILURE',
  '@notification/DELETE_FCM_TOKEN_CANCELED'
)<void, void, IAsyncError, void>();

export const fetchNotificationPreferences = createAsyncAction(
  '@notification/FETCH_NOTIFICATION_PREFERENCES_REQUEST',
  '@notification/FETCH_NOTIFICATION_PREFERENCES_SUCCESS',
  '@notification/FETCH_NOTIFICATION_PREFERENCES_FAILURE'
)<void, INotificationPreferences, IAsyncError>();

export const updateNotificationPreferences = {
  prompt: createAction('@notification/UPDATE_NOTIFICATION_PREFERENCES_PROMPT'),
  request: createAction(
    '@notification/UPDATE_NOTIFICATION_PREFERENCES_REQUEST',
    action => (preferences: INotificationPreferences) => action(preferences)
  ),
  success: createAction(
    '@notification/UPDATE_NOTIFICATION_PREFERENCES_SUCCESS',
    action => (preferences: INotificationPreferences) => action(preferences)
  ),
  failure: createAction('@notification/UPDATE_NOTIFICATION_PREFERENCES_FAILURE', action => (error: AsyncError) =>
    action(error)
  ),
  canceled: createAction('@notification/UPDATE_NOTIFICATION_PREFERENCES_CANCELED'),
};
