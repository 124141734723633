import { getType } from 'typesafe-actions';
import actions from '../actions';
import { put, select, takeLatest } from 'redux-saga/effects';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { ITourProgress, TourCode } from '../../../models';
import { commonActions, commonSelectors } from '../../common';
import { AsyncActionResult, takeAsyncActionResult } from '../../utils/sagas';
import { showErrorSnackbar } from '../../common/utils/showSnackbar';
import { minutesBetween } from '@lialo/common/lib/date';

const fetchPlayedToursSaga = takeLatest(getType(actions.fetchPlayedTours.request), function* () {
  const user = commonSelectors.selectUser(yield select());
  if (!user) {
    yield put(actions.fetchPlayedTours.cancel());
    return;
  }
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    if (!(yield synchronizeTourProgressesIfNecessary())) {
      return;
    }

    const tourProgresses: Record<TourCode, ITourProgress> = yield select(commonSelectors.selectTourProgresses);
    const tourCodesToFetch = Object.values(tourProgresses).map(tp => tp.shortLink);
    const tourSummaries = yield serviceProvider.api.fetchTourSummariesByTourCodes(tourCodesToFetch);
    yield put(actions.fetchPlayedTours.success(tourSummaries));
  } catch (e) {
    yield put(actions.fetchPlayedTours.failure(e));
    yield showErrorSnackbar(e);
    serviceProvider.analyticsService.logError(e);
  }
});

function* synchronizeTourProgressesIfNecessary() {
  const lastSynchronizationAt = commonSelectors.selectLastSynchronizationAt(yield select()) ?? new Date('1970-01-01');
  const minutesSinceLastSync = minutesBetween(lastSynchronizationAt, new Date());

  if (minutesSinceLastSync > 5) {
    yield put(commonActions.synchronizeTourProgress.request());
    const result = yield takeAsyncActionResult(commonActions.synchronizeTourProgress);
    if (result !== AsyncActionResult.SUCCESS) {
      yield put(actions.fetchPlayedTours.cancel());
      return false;
    }
  }

  return true;
}

export default [fetchPlayedToursSaga];
