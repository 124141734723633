import { createReducer } from 'typesafe-actions';
import { RootAction } from '../../store/root';
import { IAsyncActionState, reduceCancelAction, reduceFailureAction, reduceRequestAction } from '../utils';
import actions from './actions';
import produce from 'immer';
import { comparePlayProgress } from './models';
import { Chapter, ITourEvent, ITourProgress, TourCode, TourProgressStatus } from '../../models';
import { normalize } from '../../utils/helper/array';
import { commonActions } from '../common';
import { filterRecord } from '../../utils/helper/object';
import { IAsyncError } from '../../common';

export interface IPlayTourState extends IAsyncActionState {
  tourProgresses: Record<TourCode, ITourProgress>;
  tourEvents: Record<number, ITourEvent>;
  currentlyPlayedTour?: TourCode; // used to automatically resume the tour after app start
  isSynchronizingTourProgresses: boolean;
  isSendingReview: boolean;
  sendReviewError?: IAsyncError;
  isSendingProblemReport: boolean;
  sendReportError?: IAsyncError;
  lastSynchronizationAt?: Date;
  onboardingCompleted: boolean;
  hasHeartOnboardingAudio?: boolean;
}

const defaultState: IPlayTourState = {
  tourProgresses: {},
  tourEvents: {},
  isLoading: false,
  isSynchronizingTourProgresses: false,
  isSendingProblemReport: false,
  isSendingReview: false,
  onboardingCompleted: false,
  hasHeartOnboardingAudio: false,
};

const playTourReducer = createReducer<IPlayTourState, RootAction>(defaultState)
  .handleAction(actions.initializeTour.request, reduceRequestAction)
  .handleAction(actions.initializeTour.failure, reduceFailureAction)
  .handleAction(actions.initializeTour.cancel, reduceCancelAction)
  .handleAction(actions.initializeTour.success, (state, action) =>
    produce(state, draft => {
      draft.currentlyPlayedTour = action.payload;
      draft.isLoading = false;
    })
  )
  .handleAction(actions.clearCurrentlyPlayedTour, (state, action) =>
    produce(state, draft => {
      draft.currentlyPlayedTour = undefined;
    })
  )
  .handleAction(actions.openPlaybook, (state, action) =>
    produce(state, draft => {
      draft.error = undefined;
      draft.onboardingCompleted = false;

      const { tourCode, title, mainImageUrl, imageWidth, staticMapSize, purchased } = action.payload;

      const tourProgress = state.tourProgresses[tourCode];
      if (!tourProgress || action.meta.reset) {
        draft.tourProgresses[tourCode] = {
          isSyncPending: true,
          status: TourProgressStatus.running,
          startedAt: new Date(),
          completedAt: undefined,
          completedOnce: tourProgress?.completedOnce ?? false,
          didRateTour: tourProgress?.didRateTour ?? false,
          score: undefined,
          mainImageUrl,
          title,
          purchased,
          shortLink: tourCode,
          playbook: {
            currentPage: { chapter: Chapter.start },
            quizResponses: {},
            staticMapSize,
            imageWidth,
          },
        } as ITourProgress;
      }
    })
  )
  .handleAction(actions.closePlaybook, (state, action) =>
    produce(state, draft => {
      const tourProgress = draft.tourProgresses[action.payload];
      if (tourProgress) {
        tourProgress.playbook = undefined;
      }
    })
  )
  .handleAction(actions.navigatePage.update, (state, action) =>
    produce(state, draft => {
      const tour = draft.tourProgresses[action.payload.tourCode];
      if (tour) {
        tour.isSyncPending = true;
        if (tour.playbook && comparePlayProgress(tour.playbook.currentPage, action.payload.nextPage) < 0) {
          tour.playbook.currentPage = action.payload.nextPage;
        }
      }
    })
  )
  .handleAction(commonActions.cancelTour.confirmed, (state, action) =>
    produce(state, draft => {
      const tour = draft.tourProgresses[action.payload];
      if (tour) {
        tour.status = TourProgressStatus.cancelled;
        tour.playbook = undefined;
        tour.isSyncPending = true;
      }
    })
  )
  .handleAction(actions.completeTour.success, (state, action) =>
    produce(state, draft => {
      const tourProgress = draft.tourProgresses[action.payload];
      if (tourProgress) {
        // tourProgress.playbook = undefined;
        tourProgress.status = TourProgressStatus.completed;
        tourProgress.completedOnce = true;
        tourProgress.completedAt = new Date();
        tourProgress.isSyncPending = true;
      }
    })
  )
  .handleAction(actions.chooseAnswer, (state, action) =>
    produce(state, draft => {
      const openPlaybook = draft.tourProgresses[action.payload.tourCode].playbook;
      if (openPlaybook) {
        if (!openPlaybook.quizResponses[action.payload.stopIndex]) {
          openPlaybook.quizResponses[action.payload.stopIndex] = { attempts: 0 };
        }
        openPlaybook.quizResponses[action.payload.stopIndex].answer = action.payload.answer;
      }
    })
  )
  .handleAction(actions.increaseAttempts, (state, action) =>
    produce(state, draft => {
      const openPlaybook = draft.tourProgresses[action.payload.tourCode].playbook;
      if (openPlaybook && openPlaybook.quizResponses[action.payload.stopIndex]) {
        openPlaybook.quizResponses[action.payload.stopIndex].attempts += 1;
      }
    })
  )
  .handleAction(commonActions.deleteTour.success, (state, action) =>
    produce(state, draft => {
      delete draft.tourProgresses[action.payload];
    })
  )
  .handleAction(actions.saveTourProgress.success, (state, action) =>
    produce(state, draft => {
      const savedTourProgress = action.payload;
      const localTourProgress = draft.tourProgresses[savedTourProgress.shortLink];
      localTourProgress.isSyncPending = false;
      localTourProgress.didRateTour = savedTourProgress.didRateTour;
    })
  )
  .handleAction(commonActions.synchronizeTourProgress.request, (state, action) =>
    produce(state, draft => {
      draft.isSynchronizingTourProgresses = true;
    })
  )
  .handleAction(commonActions.synchronizeTourProgress.failure, (state, action) =>
    produce(state, draft => {
      draft.isSynchronizingTourProgresses = false;
    })
  )
  .handleAction(commonActions.synchronizeTourProgress.success, (state, action) =>
    produce(state, draft => {
      draft.isSynchronizingTourProgresses = false;
      draft.lastSynchronizationAt = new Date();

      const unsyncedProgresses = filterRecord(draft.tourProgresses, tp => !!tp.isSyncPending);

      draft.tourProgresses = {
        ...normalize(action.payload, tp => tp.shortLink),
        ...unsyncedProgresses, //these are new progresses that were created in the meantime and should not be overridden
      };
    })
  )
  .handleAction(actions.tourEvents.log, (state, action) =>
    produce(state, draft => {
      const { tourCode, type } = action.payload;
      const timestamp = new Date();

      const tourEvent: ITourEvent = {
        shortLink: tourCode,
        eventType: type,
        timestamp,
      };

      draft.tourEvents[timestamp.getTime()] = tourEvent;
    })
  )
  .handleAction(actions.tourEvents.syncSuccess, (state, action) =>
    produce(state, draft => {
      const timestamps: number[] = action.payload;
      timestamps.forEach(timestamp => {
        delete draft.tourEvents[timestamp];
      });
    })
  )
  .handleAction(actions.sendProblemReportAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isSendingProblemReport = true;
      draft.sendReportError = undefined;
    })
  )
  .handleAction(actions.sendProblemReportAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isSendingProblemReport = false;
    })
  )
  .handleAction(actions.sendProblemReportAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isSendingProblemReport = false;
      draft.sendReportError = action.payload;
    })
  )
  .handleAction(actions.sendProblemReportAsync.cancel, (state, action) =>
    produce(state, draft => {
      draft.isSendingProblemReport = false;
      draft.sendReportError = undefined;
    })
  )
  .handleAction(commonActions.createTourReview.request, (state, action) =>
    produce(state, draft => {
      draft.isSendingReview = true;
      draft.sendReviewError = undefined;
    })
  )
  .handleAction(commonActions.createTourReview.success, (state, action) =>
    produce(state, draft => {
      draft.isSendingReview = false;
      const tourCode = action.payload;
      const tourProgress = draft.tourProgresses[tourCode];
      if (tourProgress) {
        tourProgress.didRateTour = true;
      }
    })
  )
  .handleAction(commonActions.createTourReview.failure, (state, action) =>
    produce(state, draft => {
      draft.isSendingReview = false;
      draft.sendReviewError = action.payload;
    })
  )
  .handleAction(commonActions.createTourReview.cancel, (state, action) =>
    produce(state, draft => {
      draft.isSendingReview = false;
      draft.sendReviewError = undefined;
    })
  )
  .handleAction(actions.completeOnboarding, (state, action) =>
    produce(state, draft => {
      draft.onboardingCompleted = true;
    })
  )
  .handleAction(actions.updateScore, (state, action) =>
    produce(state, draft => {
      const tourProgress = draft.tourProgresses[action.payload.tourCode];
      if (tourProgress) {
        tourProgress.score = (tourProgress.score ?? 0) + action.payload.score;
      }
    })
  )
  .handleAction(actions.markOnboardingAudioAsPlayed, (state) =>
    produce(state, draft => {
      draft.hasHeartOnboardingAudio = true;
    })
  )
  .handleAction(commonActions.signOut.success, (state, action) => ({
    ...defaultState,
  }));

export default playTourReducer;
