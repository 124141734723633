import { indexToChar } from '../utils/helper/strings';
import { ITask, ITourStop } from '../models';

export interface IQuizResponseVM {
  key: string;
  prefix?: string;
  value: string;
  selected: boolean;
}

export const calculateAnswerPrefix = (index: number, totalAnswers: number) => {
  return totalAnswers > 2 ? indexToChar(index) : undefined;
};

export function createQuizResponseVMsFromTourStop(tourStop: ITourStop, showSelected: boolean): IQuizResponseVM[] {
  const keys: Array<keyof ITask> = ['answer', 'wrongAnswer1', 'wrongAnswer2', 'wrongAnswer3'];
  const task = tourStop.task;
  return keys
    .filter(key => task && task[key] !== undefined)
    .shuffle(tourStop.id ?? 0)
    .map((key, idx, answers) => {
      const answer = task![key];
      return {
        key,
        prefix: calculateAnswerPrefix(idx, answers.length),
        selected: showSelected && key === 'answer',
        value: `${answer}`,
      };
    });
}
