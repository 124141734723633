import React from 'react';
import { useTranslation } from 'react-i18next';
import { getRobotsValue } from '../../services/utils';
import { Helmet } from 'react-helmet';
import { territories } from '@lialo/common/lib/language';

interface Props {}

export const HelmetRoot = (props: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <Helmet>
      <html lang={i18n.language} />
      <title>{t('common.meta.title')}</title>
      <meta name="description" content={t('common.meta.description')} />
      <meta
        name="robots"
        content={getRobotsValue('index,follow,max-image-preview:large,max-snippet:-1,max-video-preview:-1')}
      />

      <meta property="og:title" content={t('common.meta.title')} />
      <meta property="twitter:title" content={t('common.meta.title')} />
      <meta property="og:description" content={t('common.meta.description')} />
      <meta property="twitter:description" content={t('common.meta.description')} />
      {territories[i18n.language] && <meta property="og:locale" content={territories[i18n.language]} />}
    </Helmet>
  );
};
