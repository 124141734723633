import { RootState } from '../../store/root';

const selectIsLoading = (state: RootState) => state.appFeedback.isLoading;
const selectError = (state: RootState) => state.appFeedback.error;
const selectFeedbackSent = (state: RootState) => state.appFeedback.feedbackSent;

export default {
  selectIsLoading,
  selectError,
  selectFeedbackSent,
};
