import React from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { theme as themeConfig } from './theme';

declare module '@mui/material/styles' {
  interface OtherColors {
    mint: string;
    orange: string;
    blue: string;
  }

  interface Palette {
    valid: string;
    white: PaletteOptions['primary'];
    other: OtherColors;
  }

  interface PaletteOptions {
    white: PaletteOptions['primary'];
  }

  // allow configuration using `createMuiTheme`
  interface PaletteOptions {
    valid: string;
    other: OtherColors;
  }
}

const theme = createTheme({
  ...themeConfig,
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: { marginBottom: '12px' },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:after': {
            borderBottomColor: themeConfig.palette.secondary.main,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          color: themeConfig.palette.primary.dark,
        },
      },
    },
  },
});

interface Props {
  children: React.ReactNode;
}

export const ThemeProvider = (props: Props) => {
  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
};
