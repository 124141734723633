import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { commonActions } from '../../common';
import { showErrorSnackbar } from '../../common/utils/showSnackbar';
import { IFirebaseUser } from '../../../models';
import { ImageUploadResponse } from '../../../common';
import selectors from '../selectors';

const updateImageSaga = takeLatest(getType(actions.updateImage.request), function* (
  action: ReturnType<typeof actions.updateImage.request>
) {
  const { val: request, done } = action.payload;

  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService = yield serviceProvider.getAuthService();
  const imageCropService = yield serviceProvider.getImageCropService();

  try {
    const blob = yield imageCropService.createCroppedImage(request.imageSrc, request.area, request.mimeType, 150);
    const imageUploadResponse: ImageUploadResponse = yield serviceProvider.api.uploadImage(blob);
    const previousUser = yield authService.currentUser;

    const user = yield updateFirebaseUser(imageUploadResponse.url);
    yield updateServerUser(user, previousUser);

    yield put(actions.updateImage.success(imageUploadResponse.url));
    yield put(commonActions.updateUser(user));
    yield call(done);
  } catch (e) {
    yield showErrorSnackbar(e);
    yield put(actions.updateImage.failure(e));
    serviceProvider.analyticsService.logError(e);
  }
});

function* updateFirebaseUser(photoURL: string | null) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService = yield serviceProvider.getAuthService();
  return yield authService.updateProfile({ photoURL });
}

function* updateServerUser(user: IFirebaseUser, previousUser: IFirebaseUser) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const privateUser = selectors.selectPrivateUser(yield select());
  try {
    yield serviceProvider.api.updateUser({
      username: user.username,
      translations: privateUser?.translations ?? [],
      imageUrl: user.imageUrl,
      anonymous: user.anonymous,
    });
  } catch (e) {
    yield updateFirebaseUser(previousUser.imageUrl ?? null);
    throw e;
  }
}

export default [updateImageSaga];
