import { RootState } from '../../store/root';
import { convertCreatedToursToTourCardVMs, convertPlayedToursToTourCardVMs, ITourCardVM } from './models';
import { createSelector } from 'reselect';
import { convertToTourSummary, ITourSummary } from '../../models';
import { commonSelectors } from '../common';

const selectIsLoadingPlayedTours = (state: RootState) => state.myTours.isLoadingPlayedTours;
const selectIsLoadingCreatedTours = (state: RootState) => state.myTours.isLoadingCreatedTours;

const selectIsLoading = createSelector(
  [selectIsLoadingPlayedTours, selectIsLoadingCreatedTours],
  (isLoadingPlayedTours, isLoadingCreatedTours) => isLoadingPlayedTours || isLoadingCreatedTours
);

const selectError = (state: RootState) => state.myTours.error;

const selectCreatedTours = (state: RootState) => state.myTours.createdTours;

const selectPlayedTours = (state: RootState) => state.myTours.playedTours;

const selectPlayedTourVMs = createSelector(
  [
    selectPlayedTours,
    commonSelectors.selectTourProgresses,
    commonSelectors.selectTours,
    commonSelectors.selectUser,
  ],
  (playedTours, tourProgresses, offlineTours, user): ITourCardVM[] => {
    const sortedTourProgresses = Object.values(tourProgresses).sortBy('startedAt', 'DESC').distinct('shortLink');

    return sortedTourProgresses.map(tourProgress => {
      const offlineTour = offlineTours[tourProgress.shortLink];
      const tourSummary: ITourSummary | null = offlineTour
        ? convertToTourSummary(offlineTour)
        : playedTours[tourProgress.shortLink];
      const isMyTour = tourSummary && user ? tourSummary.user.id === user.id : false;
      return convertPlayedToursToTourCardVMs(tourProgress, tourSummary, isMyTour);
    });
  }
);

const selectCreatedTourVMs = createSelector(
  [selectCreatedTours, commonSelectors.selectTourProgresses],
  (tours, tourProgresses): ITourCardVM[] => {
    return tours.sortBy('createdAt', 'DESC').map(tourSummary => {
      const tourProgress = tourProgresses[tourSummary.shortLink];
      return convertCreatedToursToTourCardVMs(tourSummary, tourProgress);
    });
  }
);

const selectIsLoadingStatistics = (state: RootState) => state.myTours.isLoadingStatistics;
const selectStatisticsError = (state: RootState) => state.myTours.statisticsError;
const selectTourStatistics = (state: RootState) => state.myTours.tourStatistics;

export default {
  selectIsLoadingPlayedTours,
  selectIsLoadingCreatedTours,
  selectError,
  selectPlayedTourVMs,
  selectCreatedTourVMs,
  selectIsLoading,
  selectIsLoadingStatistics,
  selectStatisticsError,
  selectTourStatistics,
};
