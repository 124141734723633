import { IStaticMapSize, ITourHighlight } from '../../../models';

export function staticHighlightMap(highlights: ITourHighlight[], staticMapSize: IStaticMapSize) {
  const markers = highlights
    .map(({ location, stopNumber }, idx) => {
      return `color:0xffcf02|label:${stopNumber}|${location.lat},${location.lng}`;
    })
    .join('&markers=');

  const size = Math.min(staticMapSize.width, 640);
  const scale = Math.min(staticMapSize.devicePixelRatio, 2);

  return encodeURI(
    `https://maps.googleapis.com/maps/api/staticmap?size=${size}x${size}&scale=${scale}&markers=${markers}&key=${process.env.REACT_APP_MAPS_KEY}`
  );
}
