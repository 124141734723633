import { styled } from '@mui/material/styles';
import React from 'react';
import { Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { IUserPreview } from '@lialo/core/lib/models';
import classNames from 'classnames';
import { cropAvatarImage } from '@lialo/core/lib/utils/helper/image-link';

const PREFIX = 'ProfileAvatar';

const classes = {
  yellow: `${PREFIX}-yellow`,
  avatar: `${PREFIX}-avatar`,
  personIcon: `${PREFIX}-personIcon`,
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  [`&.${classes.yellow}`]: {
    backgroundColor: theme.palette.secondary.main,
  },

  [`&.${classes.avatar}`]: {
    fill: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[300]}`,
  },

  [`& .${classes.personIcon}`]: {
    width: '70%',
    height: '70%',
  },
}));

interface IProps {
  size: number;
  user?: IUserPreview;
  className?: string;
}

const ProfileAvatar = (props: IProps) => {
  const url = props.user?.imageUrl;
  const croppedUrl = url ? cropAvatarImage(url, props.size) : undefined;

  return (
    <StyledAvatar
      className={classNames(props.className, {
        [classes.yellow]: !croppedUrl,
        [classes.avatar]: croppedUrl,
      })}
      style={{
        height: props.size,
        width: props.size,
      }}
      src={croppedUrl}
    >
      {!croppedUrl && <PersonIcon className={classes.personIcon} />}
    </StyledAvatar>
  );
};

export default ProfileAvatar;
