import { put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { commonActions } from '../../common';
import { requireUserOrThrow, takeConfirmation } from '../../utils/sagas';
import { showErrorSnackbar } from '../../common/utils/showSnackbar';
import routes from '../../utils/routes';
import { AsyncError, ErrorType } from '../../../common';
import { showErrorDialog } from '../../common/utils/showDialog';

const deleteTourSaga = takeLatest(getType(commonActions.deleteTour.request), function* (
  action: ReturnType<typeof commonActions.deleteTour.request>
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  const tourCode = action.payload;

  try {
    const canDelete = yield serviceProvider.api.canDeleteTour(tourCode);
    if (!canDelete) {
      throw new AsyncError(ErrorType.TOUR_DELETION_NOT_ALLOWED, 'Tour is already sold and cannot be deleted');
    }

    const confirmed: boolean = yield takeConfirmation({
      content: {
        key: 'create_tour.delete_tour.confirmation_description',
      },
      positiveKey: 'actions.delete',
    });

    if (!confirmed) return;

    yield put(commonActions.clearAssetCache(tourCode));

    yield requireUserOrThrow();
    yield serviceProvider.api.deleteTour(tourCode);
    yield put(commonActions.deleteTour.success(tourCode));
    serviceProvider.navigationService.navigateI18n(routes.MY_TOURS_CREATED, { replace: true });
  } catch (e) {
    yield put(commonActions.deleteTour.failure(e));
    e instanceof AsyncError && e.errorCode === ErrorType.TOUR_DELETION_NOT_ALLOWED
      ? yield showErrorDialog(e)
      : yield showErrorSnackbar(e);
    serviceProvider.analyticsService.logError(e);
  }
});

export default [deleteTourSaga];
