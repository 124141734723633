import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';

const updatePasswordSaga = takeLatest(getType(actions.updatePassword.request), function* (
  action: ReturnType<typeof actions.updatePassword.request>
) {
  const { val: password, done } = action.payload;

  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService = yield serviceProvider.getAuthService();

  try {
    const user = yield authService.updatePassword(password);
    yield put(actions.updatePassword.success(user));
    yield call(done);
  } catch (e) {
    yield put(actions.updatePassword.failure(e));
    serviceProvider.analyticsService.logError(e);
  }
});

export default [updatePasswordSaga];
