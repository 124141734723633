import { createAsyncAction } from 'typesafe-actions';
import { ITourSummary, TourCode } from '../../models';
import { IAsyncError } from '../../common/AsyncError';
import { ITourStatistics } from '../../models/TourStatistics';

const fetchPlayedTours = createAsyncAction(
  '@my_tours/FETCH_PLAYED_TOURS_REQUEST',
  '@my_tours/FETCH_PLAYED_TOURS_SUCCESS',
  '@my_tours/FETCH_PLAYED_TOURS_FAILURE',
  '@my_tours/FETCH_PLAYED_TOURS_CANCEL'
)<void, Record<string, ITourSummary | null>, IAsyncError, void>();

const fetchCreatedTours = createAsyncAction(
  '@my_tours/FETCH_CREATED_TOURS_REQUEST',
  '@my_tours/FETCH_CREATED_TOURS_SUCCESS',
  '@my_tours/FETCH_CREATED_TOURS_FAILURE',
  '@my_tours/FETCH_CREATED_TOURS_CANCEL'
)<void, ITourSummary[], IAsyncError, void>();

const fetchStatistics = createAsyncAction(
  '@my_tours/FETCH_STATISTICS_REQUEST',
  '@my_tours/FETCH_STATISTICS_SUCCESS',
  '@my_tours/FETCH_STATISTICS_FAILURE',
  '@my_tours/FETCH_STATISTICS_CANCEL'
)<TourCode, ITourStatistics, IAsyncError, void>();

export default {
  fetchPlayedTours,
  fetchCreatedTours,
  fetchStatistics,
};
