import { styled } from '@mui/material/styles';
import * as React from 'react';
import { IconButton, Snackbar, SnackbarProps } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const PREFIX = 'CloseableSnackbar';

const classes = {
  root: `${PREFIX}-root`,
  message: `${PREFIX}-message`,
  action: `${PREFIX}-action`,
};

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexFlow: 'row',
    alignItems: 'flex-start',
    padding: 0,
  },

  [`& .${classes.message}`]: {
    flex: '1 1 auto',
    marginRight: '0px',
    padding: theme.spacing(1, 3),
    alignSelf: 'center',
  },

  [`& .${classes.action}`]: {
    padding: theme.spacing(0.5),
    marginRight: 0,
  },
}));

const CloseableSnackbar = ({ ContentProps, action, ...rest }: SnackbarProps) => {
  const { t } = useTranslation();

  return (

    <StyledSnackbar
      {...rest}
      ContentProps={{
        classes: {
          root: classes.root,
          message: classes.message,
          action: classes.action,
        },
        ...ContentProps,
      }}
      action={
        action ?? (
          <IconButton
            key="close"
            aria-label={t('actions.close')}
            color="inherit"
            onClick={e => rest.onClose && rest.onClose(e, 'clickaway')}
            disableRipple
            disableFocusRipple
            size={'small'}
          >
            <Close />
          </IconButton>
        )
      }
    />
  );
};

export default CloseableSnackbar;
