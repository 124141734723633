/*
const myAction = createAction('MY_ACTION', action => {
  return (arg: number) => action(arg);
});
*/

import { createAction, createAsyncAction } from 'typesafe-actions';
import { IFirebaseUser, IPrivateUser, IUserTranslation } from '../../models';
import { IAsyncError } from '../../common';
import { Area } from '../../services';

type PendingRequest<T> = {
  val: T;
  done: () => void;
};

type UploadImageRequest = {
  imageSrc: string;
  area: Area;
  mimeType: string;
};

type UserTranslationRequest = {
  field: keyof IUserTranslation;
  value: string;
  language: string;
};

const updateUsername = createAsyncAction(
  '@profile/UPDATE_USERNAME_REQUEST',
  '@profile/UPDATE_USERNAME_SUCCESS',
  '@profile/UPDATE_USERNAME_FAILURE'
)<string, IFirebaseUser, IAsyncError>();

const updateEmail = createAsyncAction(
  '@profile/UPDATE_EMAIL_REQUEST',
  '@profile/UPDATE_EMAIL_SUCCESS',
  '@profile/UPDATE_EMAIL_FAILURE'
)<PendingRequest<string>, IFirebaseUser, IAsyncError>();

const updatePassword = createAsyncAction(
  '@profile/UPDATE_PASSWORD_REQUEST',
  '@profile/UPDATE_PASSWORD_SUCCESS',
  '@profile/UPDATE_PASSWORD_FAILURE'
)<PendingRequest<string>, void, IAsyncError>();

const updateImage = createAsyncAction(
  '@profile/UPDATE_IMAGE_REQUEST',
  '@profile/UPDATE_IMAGE_SUCCESS',
  '@profile/UPDATE_IMAGE_FAILURE'
)<PendingRequest<UploadImageRequest>, string, IAsyncError>();

const fetchPrivateUser = createAsyncAction(
  '@profile/FETCH_PRIVATE_USER_REQUEST',
  '@profile/FETCH_PRIVATE_USER_SUCCESS',
  '@profile/FETCH_PRIVATE_USER_FAILURE'
)<void, IPrivateUser, IAsyncError>();

const deleteProfile = createAsyncAction(
  '@profile/DELETE_PROFILE_REQUEST',
  '@profile/DELETE_PROFILE_SUCCESS',
  '@profile/DELETE_PROFILE_FAILURE',
  '@profile/DELETE_PROFILE_CANCEL'
)<void, void, IAsyncError, void>();

const updateUserTranslation = createAction(
  '@profile/UPDATE_USER_TRANSLATION',
  action => (request: UserTranslationRequest) => action(request)
);
const updateWebsite = createAction('@profile/UPDATE_WEBSITE', action => (website: string) => action(website));

const updatePrivateUser = createAsyncAction(
  '@profile/UPDATE_PRIVATE_USER_REQUEST',
  '@profile/UPDATE_PRIVATE_USER_SUCCESS',
  '@profile/UPDATE_PRIVATE_USER_FAILURE'
)<void, IPrivateUser, IAsyncError>();

const showReauthenticationDialog = createAction('@profile/SHOW_REAUTHENTICATION_DIALOG', action => {
  return (done: () => void) => action(done);
});

export default {
  updateUsername,
  updateEmail,
  updatePassword,
  updateImage,
  updateUserTranslation,
  updateWebsite,
  updatePrivateUser,
  fetchPrivateUser,
  deleteProfile,
  showReauthenticationDialog,
};
