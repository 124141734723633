import { createAsyncAction } from 'typesafe-actions';
import { IAsyncError } from '../../common';
import { IUserProfile } from '../../models';

/*
const myAction = createAction('MY_ACTION', action => {
  return (arg: number) => action(arg);
});
*/

const fetchPublicProfileAsync = createAsyncAction(
  'FETCH_PUBLIC_PROFILE_REQUEST',
  'FETCH_PUBLIC_PROFILE_SUCCESS',
  'FETCH_PUBLIC_PROFILE_FAILURE'
)<string, IUserProfile, IAsyncError>();

export default {
  fetchPublicProfileAsync,
};
