import { matchPath } from 'react-router';
import routes from '../../utils/routes';
import { IImageState, ITourState } from '../models/state';
import { ImageType } from '../models/Image';

type TourImagePath = {
  listType: ImageType.MAIN | ImageType.START | ImageType.END;
};

type StopImagePath = {
  stopLocalId: number;
  listType: ImageType.DIRECTIONS | ImageType.INFORMATION | ImageType.TASK;
};

export function isStopImagePath(arg: any): arg is StopImagePath {
  return arg.stopLocalId !== undefined;
}

export type ImageListPath = TourImagePath | StopImagePath;
export type ImageDetailPath = ImageListPath & { localId: number };

export function selectImages(state: ITourState, path: ImageListPath): IImageState[] | undefined {
  if (isStopImagePath(path)) {
    const stop = state.stops.find(stop => stop.localId === path.stopLocalId);
    if (path.listType === ImageType.TASK) {
      return stop?.task?.images;
    } else {
      return stop?.images;
    }
  } else {
    return state.images;
  }
}

export function matchImageListPath(url: string): ImageListPath | undefined {
  const match = matchPath<any>(url, routes.EDIT_IMAGE_LIST);
  if (match) {
    return {
      ...match.params,
      listType: match.params?.listType ? match.params.listType.toUpperCase() as ImageType : undefined,
      stopLocalId: match.params?.stopLocalId ? parseInt(match.params.stopLocalId, 10) : undefined,
    };
  }
}

export function matchImageDetailPath(url: string): ImageDetailPath | undefined {
  const match = matchPath<any>(url, routes.EDIT_IMAGE_DETAIL);
  if (match) {
    return {
      ...match.params,
      listType: match.params?.listType ? match.params.listType.toUpperCase() : undefined,
      stopLocalId: match.params?.stopLocalId ? parseInt(match.params.stopLocalId, 10) : undefined,
      localId: match.params.localId ? parseInt(match.params.localId, 10) : undefined,
    };
  }
}
