import { put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { navigationActions, navigationSelectors } from './index';
import { goBack, replace } from 'connected-react-router';

// Alternative implmentation without redux https://github.com/ReactTraining/history/issues/573
function* goBackOrReplace(action: ReturnType<typeof navigationActions.goBackOrReplace>) {
  const previousLocation = yield select(navigationSelectors.selectPreviousLocation);
  const { replaceUrl, forceReplace } = action.payload;
  if (previousLocation === null || forceReplace === true) {
    if (typeof replaceUrl === 'string') {
      yield put(replace(replaceUrl as string));
    } else {
      yield put(replace(replaceUrl));
    }
  } else {
    yield put(goBack());
  }
}

const navigation = takeEvery(getType(navigationActions.goBackOrReplace), goBackOrReplace);

export default [navigation];
