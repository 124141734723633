import * as React from 'react';
import { Modal } from '@mui/material';
import { useInstallationPrompt } from '../Providers';
import { isMobileSafari } from '../../services/utils';
import { useTranslation } from 'react-i18next';
import { IosAddIcon, IosShareIcon } from '../Icons';
import useInstalledOnIOS from './useInstalledOnIOS';
import { styled } from '@mui/material/styles';

const PREFIX = 'InstallationPrompt';

const classes = {
  modal: `${PREFIX}-modal`,
  shareIcon: `${PREFIX}-shareIcon`,
  addIcon: `${PREFIX}-addIcon`,
  triangle: `${PREFIX}-triangle`,
};

const triangleHeight = 10;
const StyledDiv = styled('div')(({ theme }) => ({
  [`&.${classes.modal}`]: {
    position: 'absolute',
    bottom: 0,
    left: `50%`,
    transform: `translate(-50%, -${triangleHeight}px)`,
    minWidth: 280,
    padding: theme.spacing(2, 3, 2),
    outline: 'none',
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.other.blue,
    color: theme.palette.common.white,
    lineHeight: '1.4em',
  },
  [`& .${classes.triangle}`]: {
    position: 'absolute',
    left: '50%',
    borderLeft: `solid transparent ${triangleHeight}px`,
    borderRight: `solid transparent ${triangleHeight}px`,
    borderTop: `solid ${theme.palette.other.blue} ${triangleHeight}px`,
    bottom: -triangleHeight,
    marginLeft: -triangleHeight,
  },
  [`& .${classes.shareIcon}`]: {
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(1),
    fontSize: 16,
  },
  [`& .${classes.addIcon}`]: {
    position: 'relative',
    bottom: -3,
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(1),
    fontSize: 16,
  },
}));

type Clickable = {
  onClick?: () => void;
};

interface IProps<T extends Clickable> {
  children: React.ReactElement<T>;
}

const InstallationPrompt = <T extends Clickable>(props: IProps<T>) => {
  const { t } = useTranslation();
  const { setInstalledOnIOS, getInstalledOnIOS } = useInstalledOnIOS();
  const [open, setOpen] = React.useState(false);
  const { prompt } = useInstallationPrompt();
  const notInstalledAndOnMobileSafari = React.useRef(isMobileSafari() && !getInstalledOnIOS());
  const showInstallationListItem = !!prompt || notInstalledAndOnMobileSafari.current;

  const installApp = () => {
    if (prompt) {
      prompt.prompt();
    } else if (notInstalledAndOnMobileSafari.current) {
      handleModal();
    }
  };

  const handleModal = () => {
    setOpen(wasOpen => !wasOpen);
    setInstalledOnIOS(true);
  };
  //ToDo: Try to get rid of any
  const { onClick, ...elementProps } = props.children.props as any;
  return showInstallationListItem ? (
    <>
      {React.cloneElement(props.children, {
        onClick: () => {
          onClick && onClick();
          installApp();
        },
        ...elementProps,
      })}
      <Modal open={open} onClose={handleModal}>
        <StyledDiv className={classes.modal}>
          {t('drawer.ios_tooltip_1')}
          <IosShareIcon className={classes.shareIcon} />
          {t('drawer.ios_tooltip_2')}
          <IosAddIcon className={classes.addIcon} />
          <div className={classes.triangle} />
        </StyledDiv>
      </Modal>
    </>
  ) : null;
};

export default InstallationPrompt;
