import React, { useContext } from 'react';
import { IServiceProvider } from '@lialo/core/lib/services';

type State = IServiceProvider | null;
const StateContext = React.createContext<State>(null);

export const ServiceProvider = (props: { serviceProvider: IServiceProvider; children: React.ReactNode }) => {
  return <StateContext.Provider value={props.serviceProvider}>{props.children}</StateContext.Provider>;
};

export const useServiceProvider = (): IServiceProvider => {
  const context = useContext(StateContext);
  if (context === null) throw new Error('ServiceProvider have not been initialized');
  return context;
};
