import { RootState } from '../../store/root';
import { createSelector } from 'reselect';
import { commonSelectors } from '../common';
import { Authority, hasAuthority } from '../../common';

const selectIsLoading = (state: RootState) => state.profile.isLoading;
const selectError = (state: RootState) => state.profile.error;
const selectFetchPrivateUserError = (state: RootState) => state.profile.fetchPrivateUserError;
const selectIsLoadingPrivateUser = (state: RootState) => state.profile.isLoadingPrivateUser;
const selectPrivateUser = (state: RootState) => state.profile.privateUser;
const selectCanEditPaymentSettings = createSelector(commonSelectors.selectAuthorities, authorities =>
  hasAuthority(authorities, Authority.EDIT_PRODUCT_OWNER)
);

export default {
  selectIsLoading,
  selectError,
  selectFetchPrivateUserError,
  selectIsLoadingPrivateUser,
  selectPrivateUser,
  selectCanEditPaymentSettings,
};
