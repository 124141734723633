import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { IAsyncActionState, reduceFailureAction, reduceRequestAction } from '../utils';
import { RootAction } from '../../store/root';
import actions from './actions';
import { IUserProfile } from '../../models';

export interface IPublicProfileState extends IAsyncActionState {
  profile?: IUserProfile;
}

const defaultState: IPublicProfileState = {
  isLoading: false,
};

const publicProfileReducer = createReducer<IPublicProfileState, RootAction>(defaultState)
  .handleAction(actions.fetchPublicProfileAsync.request, reduceRequestAction)
  .handleAction(actions.fetchPublicProfileAsync.failure, reduceFailureAction)
  .handleAction(actions.fetchPublicProfileAsync.success, (state, action) =>
    produce(state, draft => {
      const profile = action.payload;
      draft.isLoading = false;
      draft.profile = profile;
    })
  );

export default publicProfileReducer;
