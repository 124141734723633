import { createReducer } from 'typesafe-actions';
import { IAsyncActionState, reduceFailureAction, reduceRequestAction } from '../utils';
import { RootAction } from '../../store/root';
import actions from './actions';
import produce from 'immer';
import { IAsyncError } from '../../common';
import { IPrivateUser } from '../../models';
import { commonActions } from '../common';

export interface IProfileState extends IAsyncActionState {
  isLoadingPrivateUser: boolean;
  fetchPrivateUserError?: IAsyncError;
  privateUser?: IPrivateUser;
}

const defaultState: IProfileState = {
  isLoadingPrivateUser: false,
  isLoading: false,
};

const profileReducer = createReducer<IProfileState, RootAction>(defaultState)
  .handleAction(
    [
      actions.updateUsername.request,
      actions.updateEmail.request,
      actions.updatePassword.request,
      actions.updateImage.request,
      actions.deleteProfile.request,
      actions.updatePrivateUser.request,
    ],
    reduceRequestAction
  )
  .handleAction(
    [
      actions.updateUsername.failure,
      actions.updateEmail.failure,
      actions.updatePassword.failure,
      actions.updateImage.failure,
      actions.deleteProfile.failure,
      actions.updatePrivateUser.failure,
    ],
    reduceFailureAction
  )
  .handleAction(
    [
      actions.updateEmail.success,
      actions.updatePassword.success,
      actions.deleteProfile.success,
      actions.deleteProfile.cancel,
    ],
    (state, action) =>
      produce(state, draft => {
        draft.isLoading = false;
      })
  )
  .handleAction(actions.updateImage.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      if (draft.privateUser) {
        draft.privateUser.imageUrl = action.payload;
      }
    })
  )
  .handleAction(actions.updateUsername.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      if (draft.privateUser) {
        draft.privateUser.username = action.payload.username;
      }
    })
  )
  .handleAction(actions.fetchPrivateUser.request, (state, action) =>
    produce(state, draft => {
      draft.isLoadingPrivateUser = true;
      draft.fetchPrivateUserError = undefined;
    })
  )
  .handleAction(actions.fetchPrivateUser.success, (state, action) =>
    produce(state, draft => {
      draft.isLoadingPrivateUser = false;
      draft.privateUser = action.payload;
    })
  )
  .handleAction(actions.fetchPrivateUser.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoadingPrivateUser = false;
      draft.fetchPrivateUserError = action.payload;
    })
  )
  .handleAction(actions.updatePrivateUser.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.privateUser = action.payload;
    })
  )
  .handleAction(commonActions.signOut.success, (state, action) => ({
    ...defaultState,
  }));

export default profileReducer;
