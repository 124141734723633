import React, { useState } from 'react';
import { notificationActions, notificationSelectors } from '@lialo/core/lib/pages/notifications';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useIsPushSupported } from './useIsPushSupported';
import { commonSelectors } from '@lialo/core/lib/pages/common';
import { TextFieldWithCheckmark } from '@lialo/pwa-ui/lib/TextFields';
import { anyNotificationsEnabled, INotificationPreferences } from '@lialo/core/lib/common';
import { LoadingButton } from '@lialo/pwa-ui/lib/LoadingButton';
import { isValidEmail } from '@lialo/core/lib/utils/helper/validators';
import { styled } from '@mui/material/styles';
import { ErrorMessage } from '@lialo/pwa-ui/lib/Messages/ErrorMessage';
import { errorTranslationKey } from '@lialo/core/lib/translations/translationHelper';
import { Language } from '@lialo/common/lib/language';

interface Props {}

const StyledErrorMessage = styled(ErrorMessage)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const NotificationPreferenceDialog = (props: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isPushSupported = useIsPushSupported();

  const isRegisteredUser = useSelector(commonSelectors.selectIsRegisteredUser);

  const [notificationPreferences, setNotificationPreferences] = useState<INotificationPreferences>(
    useSelector(notificationSelectors.selectNotificationPreferences) ?? {
      pushNotificationsEnabled: false,
      emailNotificationsEnabled: false,
      language: i18n.language as Language,
      fallbackEmail: null,
    }
  );

  const canSubmit =
    anyNotificationsEnabled(notificationPreferences) &&
    (notificationPreferences.emailNotificationsEnabled
      ? isValidEmail(notificationPreferences.fallbackEmail ?? '') || isRegisteredUser
      : true);

  const updatePreferences = () =>
    dispatch(
      notificationActions.updateNotificationPreferences.request({
        ...notificationPreferences,
        language: i18n.language as Language, // update with current language
      })
    );
  const cancel = () => dispatch(notificationActions.updateNotificationPreferences.canceled());

  const { isDialogOpen, isLoading, error } = useSelector(notificationSelectors.selectEditNotificationPreferences);

  return (
    <Dialog open={isDialogOpen} onClose={cancel}>
      <DialogTitle>{t('common.notification_preferences.dialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('common.notification_preferences.dialog.message')}</DialogContentText>
        <FormGroup>
          <FormControlLabel
            labelPlacement={'start'}
            control={
              <Switch
                disabled={!isPushSupported}
                checked={notificationPreferences.pushNotificationsEnabled}
                onChange={e =>
                  setNotificationPreferences(prev => ({
                    ...prev,
                    pushNotificationsEnabled: e.target.checked,
                  }))
                }
              />
            }
            label={t('common.notification_preferences.dialog.switch_push')}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            labelPlacement={'start'}
            control={
              <Switch
                checked={notificationPreferences.emailNotificationsEnabled}
                onChange={e =>
                  setNotificationPreferences(prev => ({
                    ...prev,
                    emailNotificationsEnabled: e.target.checked,
                    fallbackEmail: e.target.checked ? prev.fallbackEmail : null,
                  }))
                }
              />
            }
            label={t('common.notification_preferences.dialog.switch_email')}
          />
        </FormGroup>
        {notificationPreferences.emailNotificationsEnabled && !isRegisteredUser && (
          <FormGroup>
            <TextFieldWithCheckmark
              value={notificationPreferences.fallbackEmail ?? ''}
              onChange={e =>
                setNotificationPreferences(prev => ({
                  ...prev,
                  fallbackEmail: e.target.value,
                }))
              }
              margin={'dense'}
              label={t('common.notification_preferences.dialog.fallback_email')}
              helperText={t('common.notification_preferences.dialog.fallback_email_helper')}
              type={'email'}
              fullWidth
            />
          </FormGroup>
        )}
        {error && (
          <StyledErrorMessage
            iconSize={'small'}
            messageVariant={'body2'}
            message={t(errorTranslationKey(error))}
            orientation={'horizontal'}
            variant={'error'}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>{t('actions.cancel')}</Button>
        <LoadingButton disabled={!canSubmit} onClick={updatePreferences} component={Button} isLoading={isLoading}>
          {t('actions.activate')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
