import { put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { createTourActions, createTourSelectors } from '../index';
import { getServiceProvider, INavigationService } from '../../../services';
import routes from '../../utils/routes';

const routeToNewStop = takeEvery(getType(actions.createTourStop), function* () {
  const navigationService: INavigationService = (yield getServiceProvider()).navigationService;
  const latestTourStop = createTourSelectors.selectLatestTourStop(yield select());
  const numberOfStops = createTourSelectors.selectCountTourStops(yield select());

  if (numberOfStops === 2) {
    yield put(createTourActions.guidanceDialog.show());
  }
  if (latestTourStop) {
    navigationService.navigate(
      navigationService.generateEditTourStopUrl(routes.EDIT_TOUR_STOP, latestTourStop.localId!)
    );
  }
});

export default [routeToNewStop];
