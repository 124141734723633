import { put, select, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { IFirebaseUser } from '../../../models';
import { commonActions } from '../../common';
import { showErrorSnackbar } from '../../common/utils/showSnackbar';
import selectors from '../selectors';

const updateUsernameSaga = takeLatest(getType(actions.updateUsername.request), function* (
  action: ReturnType<typeof actions.updateUsername.request>
) {
  const username = action.payload;
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService = yield serviceProvider.getAuthService();

  const previousUser = authService.currentUser;

  try {
    const user = yield updateFirebaseUser(username);
    yield updateServerUser(user, previousUser);
    yield put(commonActions.updateUser(user));
    yield put(actions.updateUsername.success(user));
  } catch (e) {
    yield put(actions.updateUsername.failure(e));
    yield showErrorSnackbar(e);
    serviceProvider.analyticsService.logError(e);
  }
});

function* updateFirebaseUser(username: string) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService = yield serviceProvider.getAuthService();
  return yield authService.updateProfile({ displayName: username });
}

function* updateServerUser(user: IFirebaseUser, previousUser: IFirebaseUser) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const privateUser = selectors.selectPrivateUser(yield select());
  try {
    yield serviceProvider.api.updateUser({
      username: user.username,
      translations: privateUser?.translations ?? [],
      imageUrl: user.imageUrl,
      anonymous: user.anonymous,
    });
  } catch (e) {
    yield updateFirebaseUser(previousUser.username!); //rollback
    throw e;
  }
}

export default [updateUsernameSaga];
