import React from 'react';
import ReactDOM from 'react-dom';
import { initializeApp } from 'firebase/app';
import localForage from 'localforage';
import { createBrowserHistory, History } from 'history';
import StackdriverErrorReporter from 'stackdriver-errors-js';

import configureStore from '@lialo/core/lib/store';
import { appActions } from '@lialo/core/lib/pages/app';
import { Api, IServiceProvider } from '@lialo/core/lib/services';

import App from './app/App';
import './index.css';
import config from './config';
import { ServiceProvider } from './services';
import AppUpdateService from './services/appUpdateService';

const dev = process.env.NODE_ENV === 'development';

const errorHandler = setupStackdriver();
setupFirebaseApp();
const history = createBrowserHistory();
const serviceProvider = new ServiceProvider(config.apiUrl, config.apiContractVersion, history, errorHandler);
const { store, persistor } = setupStore(serviceProvider, history);

ReactDOM.render(
  <App store={store} persistor={persistor} history={history} services={serviceProvider} />,
  document.getElementById('root')
);

function setupStore(serviceProvider: IServiceProvider, history: History) {
  const [store, persistor] = configureStore(localForage, history, serviceProvider);
  (serviceProvider.api as Api).init({ onVersionError: error => store.dispatch(appActions.appUpdate.request()) });
  (serviceProvider.appUpdateService as AppUpdateService).init(() =>
    store.dispatch(appActions.appUpdate.updateAvailable())
  );
  return { store, persistor };
}

function setupFirebaseApp() {
  return initializeApp(config.firebaseConfig);
}

function setupStackdriver(): StackdriverErrorReporter {
  if (!dev) {
    const errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
      key: config.stackdriverKey, // 'AIzaSyCxvcKtclwnSIpd0h39K28BOatbvJAMnP0',
      projectId: config.projectId, // 'lialo-com-dev',
    });
    return errorHandler;
  } else {
    return {
      report: async (err: Error | String, options?: any) => {
        console.log('report stackdriver error', err);
      },
      start: (config: any) => {
        console.log('start error reporter');
      },
      setUser: (user?: string) => {
        console.log('set user', user);
      },
    };
  }
}
