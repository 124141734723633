import { Chapter, ITourProgress, TourProgressStatus } from '../../../models';
import { AsyncError, ErrorType, IAsyncError } from '../../../common';

export function checkViewTourContentPermissions(
  isMyTour: boolean,
  tourProgress: ITourProgress | undefined,
  allowAccessWhenChapterIsEnd: boolean
): IAsyncError | undefined {
  if (tourProgress?.status === TourProgressStatus.running) {
    if (!(allowAccessWhenChapterIsEnd && tourProgress?.playbook?.currentPage?.chapter === Chapter.end)) {
      return new AsyncError(ErrorType.TOUR_RUNNING);
    }
  }

  if (tourProgress?.completedOnce !== true && !isMyTour) {
    return new AsyncError(ErrorType.TOUR_NOT_COMPLETED);
  }
}
