import { getServiceProvider, IServiceProvider } from '../../../services';
import { select, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import selectors from '../selectors';

const checkUpdateSaga = takeLeading(getType(actions.appUpdate.request), function* () {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const appUpdateService = serviceProvider.appUpdateService;

  const updateAvailable = selectors.selectIsAppUpdateAvailable(yield select());
  if (!updateAvailable) {
    yield appUpdateService.checkForUpdates();
  }
});

const refreshSaga = takeLeading(getType(actions.appUpdate.updateApp), function* () {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  serviceProvider.appUpdateService.update();
});

export default [checkUpdateSaga, refreshSaga];
