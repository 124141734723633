import { getServiceProvider, IServiceProvider } from '../../../services';
import { call, put, takeLatest } from 'redux-saga/effects';
import { commonActions } from '../../common';
import { ConnectionStatus, createConnectionChannel } from '../../utils/sagas';

function* handleNetworkConnection() {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const connectionChannel = yield createConnectionChannel(serviceProvider.connectionService);

  yield takeLatest(connectionChannel, function* (status: ConnectionStatus) {
    if (status === ConnectionStatus.online) {
      yield put(commonActions.connection.online());
    } else {
      yield put(commonActions.connection.offline());
    }
  });
}

export default [call(handleNetworkConnection)];
