import { createReducer } from 'typesafe-actions';
import actions from './actions';
import { RootAction } from '../../store/root';
import produce from 'immer';
import { generateLocalId } from './utils/generateLocalId';
import { TourSettingsKey, TourVisibility } from '../../models';
import { addOrRemove } from '../../utils/helper/array';
import { selectImages } from './utils/imagePathHelper';
import arrayMove from 'array-move';
import { defaultState, defaultTask, ICreateTourState, ITourStopState } from './models/state';
import { mergeServerIdsIntoState } from './utils/mergeTourIntoState';
import { convertTourToTourState } from './utils/convert';
import { createFakeTourState } from './utils/createFakeTour';
import { emptyStringToUndefined, trimLines } from '../../utils/helper/strings';
import { commonActions } from '../common';
import ReviewStatus from '../../models/ReviewStatus';
import { ErrorType } from '../../common';

const createTourReducer = createReducer<ICreateTourState, RootAction>(defaultState)
  .handleAction(actions.saveTourAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isSaving = true;
      draft.saveError = undefined;
      if (action.meta.updateSnapshot) {
        draft.editingSnapshot = { ...state.tourState };
      } else {
        draft.editingSnapshot = undefined;
      }
    })
  )
  .handleAction([actions.saveTourAsync.failure, actions.uploadImageAsync.failure], (state, action) =>
    produce(state, draft => {
      draft.isSaving = false;
      draft.saveError = action.payload;

      // Rollback to last valid tour state
      // FORBIDDEN_TOUR_MODIFICATION -> temporarily because of potential bug in backend logic
      // TOUR_ALREADY_SOLD -> User tried to set tour to private, but it is already sold
      // VALIDATION_FAILED -> Tour is non-private but invalid
      if (
        state.tour &&
        [ErrorType.FORBIDDEN_TOUR_MODIFICATION, ErrorType.TOUR_ALREADY_SOLD, ErrorType.TOUR_VALIDATION_FAILED].includes(
          action.payload.errorCode
        )
      ) {
        draft.tourState = convertTourToTourState(state.tour);
        draft.editingSnapshot = draft.tourState;
        draft.saveError = undefined; // discard error to disable save button
      }
    })
  )
  .handleAction(
    actions.saveTourAsync.success,
    (state, action): ICreateTourState => {
      return {
        ...state,
        tourState: {
          ...mergeServerIdsIntoState(state.tourState, action.payload),
        },
        editingSnapshot: state.editingSnapshot
          ? {
              ...mergeServerIdsIntoState(state.editingSnapshot, action.payload),
            }
          : undefined,
        tour: action.payload,
        isSaving: false,
      };
    }
  )
  .handleAction(
    actions.updateTourField,
    (state, action): ICreateTourState => ({
      ...state,
      tourState: {
        ...state.tourState,
        [action.payload.field]: emptyStringToUndefined(action.payload.value),
      },
    })
  )
  .handleAction(
    actions.sanitizeTourField,
    (state, action): ICreateTourState => ({
      ...state,
      tourState: {
        ...state.tourState,
        [action.payload.field]: emptyStringToUndefined(trimLines(action.payload.value)),
      },
    })
  )

  .handleAction(actions.toggleIsRoundTrip, (state, action) =>
    produce(state, draft => {
      draft.tourState.isRoundTrip = !draft.tourState.isRoundTrip;
      // unchecking should not require the user to save
      if (draft.editingSnapshot && !draft.tourState.isRoundTrip) {
        draft.editingSnapshot.isRoundTrip = draft.tourState.isRoundTrip;
      }
      // checking must unset the location
      if (draft.tourState.isRoundTrip) {
        draft.tourState.endLocation = undefined;
      }
    })
  )
  .handleAction(actions.startEditingTour, (state, action) =>
    produce(state, draft => {
      draft.editingSnapshot = { ...state.tourState };
    })
  )
  .handleAction(actions.rollbackTourState, (state, action) =>
    produce(state, draft => {
      if (state.editingSnapshot) {
        draft.tourState = state.editingSnapshot;
        draft.editingSnapshot = undefined;
      }
    })
  )
  .handleAction(actions.createTourStop, (state, action) =>
    produce(state, draft => {
      draft.tourState.stops.push({
        id: 0,
        localId: generateLocalId(state.tourState.stops),
        task: defaultTask,
        images: [],
        orderId: draft.tourState.stops.length + 1,
        lookIntoTour: false,
        waypoints: [],
        waypointsEnabled: false,
      } as ITourStopState);
    })
  )
  .handleAction(actions.updateTourStopField, (state, action) =>
    produce(state, draft => {
      const stop: any = findTourStopByLocalId(draft, action.payload.localId);
      if (stop) {
        stop[action.payload.field] = emptyStringToUndefined(action.payload.value);
      }
    })
  )
  .handleAction(actions.sanitizeTourStopField, (state, action) =>
    produce(state, draft => {
      const stop: any = findTourStopByLocalId(draft, action.payload.localId);
      if (stop) {
        stop[action.payload.field] = emptyStringToUndefined(trimLines(action.payload.value));
      }
    })
  )
  .handleAction(actions.deleteTourStop, (state, action) =>
    produce(state, draft => {
      const index = state.tourState.stops.findIndex(s => s.localId === action.payload);
      if (index >= 0) {
        draft.tourState.stops.splice(index, 1);
      }
    })
  )
  .handleAction(actions.updateTask, (state, action) =>
    produce(state, draft => {
      const stop = findTourStopByLocalId(draft, action.payload.localId);
      if (stop) {
        (stop.task as any)[action.payload.field] = emptyStringToUndefined(action.payload.value);
      }
    })
  )
  .handleAction(actions.sanitizeTask, (state, action) =>
    produce(state, draft => {
      const stop = findTourStopByLocalId(draft, action.payload.localId);
      if (stop) {
        (stop.task as any)[action.payload.field] = emptyStringToUndefined(trimLines(action.payload.value));
      }
    })
  )
  .handleAction(actions.populateTourState.request, (state, action) =>
    produce(state, draft => {
      draft.isFetching = true;
      draft.fetchError = undefined;
    })
  )
  .handleAction([actions.populateTourState.success], (state, action) => {
    const tourState = convertTourToTourState(action.payload);
    return {
      ...defaultState,
      tour: action.payload,
      tourState,
    };
  })
  .handleAction(actions.populateTourState.failure, (state, action) =>
    produce(state, draft => {
      draft.isFetching = false;
      draft.fetchError = action.payload;
    })
  )
  .handleAction(actions.toggleSettingsField, (state, action) =>
    produce(state, draft => {
      const settingName = action.payload.settingName as TourSettingsKey;
      const value = action.payload.value;
      const settings = draft.tourState.settings;
      const section = settings[settingName];
      if (Array.isArray(section)) {
        (settings[settingName] as any) = addOrRemove(section, value);
      }
      if (section === null || typeof settings[settingName] === 'string') {
        (settings[settingName] as any) = value;
      }
      if (typeof section === 'boolean') {
        (settings[settingName] as any) = !section;
      }
    })
  )
  .handleAction(actions.createImage, (state, action) =>
    produce(state, draft => {
      const { path } = action.payload;
      const images = selectImages(draft.tourState, path);
      if (!images) {
        console.error('Required image list is not in state', path);
        return;
      }
      images.push({
        id: 0,
        orderId: images.filter(img => img.type === path.listType).length + 1,
        localId: path.localId,
        type: path.listType,
      });
    })
  )
  .handleAction(actions.updateImage, (state, action) =>
    produce(state, draft => {
      const { path, localId, value } = action.payload;
      const images = selectImages(draft.tourState, path);
      if (!images) {
        console.error('Required image list is not in state', path);
        return;
      }
      const image = images.find(img => img.localId === localId && img.type === path.listType);
      image && Object.assign(image, value);
    })
  )
  .handleAction(actions.reorderImages, (state, action) =>
    produce(state, draft => {
      const { listPath, oldIndex, newIndex } = action.payload;

      const images = selectImages(draft.tourState, listPath)
        ?.filter(img => img.type === listPath.listType)
        ?.sortBy('orderId', 'ASC');
      if (images) {
        arrayMove(images, oldIndex, newIndex).forEach((img, idx) => {
          img.orderId = idx + 1;
        });
      }
    })
  )
  .handleAction(actions.deleteImage, (state, action) =>
    produce(state, draft => {
      const { listPath, localId } = action.payload;
      const images = selectImages(draft.tourState, listPath);
      if (images) {
        const idx = images.findIndex(img => img.localId === localId && img.type === listPath.listType);
        images.splice(idx, 1);
        images.sortBy('orderId', 'ASC').forEach((img, idx) => {
          img.orderId = idx + 1;
        });
      }
    })
  )
  .handleAction(actions.uploadImageAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isSaving = true;
      draft.saveError = undefined;
    })
  )
  .handleAction(actions.uploadImageAsync.success, (state, action) =>
    produce(state, draft => {
      const { detailPath, imageInfo } = action.payload;
      draft.isSaving = false;
      const images = selectImages(draft.tourState, detailPath);
      if (!images) {
        console.error('Required image list is not in state', detailPath);
        return;
      }

      const image = images.find(img => img.localId === detailPath.localId && img.type === detailPath.listType);
      if (image) {
        Object.assign(image, { url: imageInfo.url, filename: imageInfo.filename });
      }
    })
  )
  .handleAction(actions.reorderTourStops, (state, action) =>
    produce(state, draft => {
      const { oldIndex, newIndex } = action.payload;

      const stops = draft.tourState.stops;
      if (stops) {
        const sortedStops = stops.sortBy('orderId', 'ASC');
        draft.tourState.stops = arrayMove(sortedStops, oldIndex, newIndex).map((stop, idx) => {
          stop.orderId = idx + 1;
          return stop;
        });
      }
    })
  )
  .handleAction(actions.updatePreviewTourStop, (state, action) =>
    produce(state, draft => {
      const stops = draft.tourState.stops;
      const stop = stops.find(stop => stop.id === action.payload.stopId);
      if (stop) {
        stops.forEach(stop => {
          stop.lookIntoTour = false;
        });
        stop.lookIntoTour = true;
      }
    })
  )
  .handleAction(actions.insertFakeTour, (state, action) =>
    produce(state, draft => {
      draft.tourState = createFakeTourState();
    })
  )
  .handleAction(
    [actions.requestReview.request, actions.cloneTour.start, actions.translateTour.request],
    (state, action) =>
      produce(state, draft => {
        draft.isSaving = true;
      })
  )
  .handleAction(
    [actions.requestReview.failure, actions.cloneTour.failure, actions.translateTour.failure],
    (state, action) =>
      produce(state, draft => {
        draft.isSaving = false;
        draft.saveError = action.payload;
      })
  )
  .handleAction([actions.cloneTour.cancel, actions.cloneTour.success, actions.translateTour.success], state =>
    produce(state, draft => {
      draft.isSaving = false;
    })
  )
  .handleAction(actions.requestReview.success, (state, action) =>
    produce(state, draft => {
      draft.tourState.reviewStatus = ReviewStatus.REQUESTED;
      draft.tourState.visibility = TourVisibility.FRIENDS;
      draft.isSaving = false;
      if (draft.tour) {
        draft.tour.reviewStatus = ReviewStatus.REQUESTED;
        draft.tour.visibility = TourVisibility.FRIENDS;
      }
    })
  )
  .handleAction(actions.guidanceDialog.show, (state, action) =>
    produce(state, draft => {
      draft.showGuidanceDialog = true;
    })
  )
  .handleAction(actions.guidanceDialog.hide, (state, action) =>
    produce(state, draft => {
      draft.showGuidanceDialog = false;
    })
  )
  .handleAction(commonActions.signOut.success, (state, action) => ({
    ...defaultState,
  }));

function findTourStopByLocalId(state: ICreateTourState, stopId: number) {
  return state.tourState.stops.find(s => s.localId === stopId);
}

export default createTourReducer;
