import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { IAsyncActionState, reduceCancelAction, reduceFailureAction, reduceRequestAction } from '../utils';
import { RootAction } from '../../store/root';
import * as actions from './actions';
import { commonActions } from '../common';

export interface IFollowAuthorState extends IAsyncActionState {
  followeeIds: string[];
  followeesFetchedAt: Date | null;
  isFetchingFolloweeIds: boolean;
}

const defaultState: IFollowAuthorState = {
  error: undefined,
  isLoading: false,
  followeesFetchedAt: null,
  followeeIds: [],
  isFetchingFolloweeIds: false,
};

export const whiteList: (keyof IFollowAuthorState)[] = ['followeeIds', 'followeesFetchedAt'];

const followAuthorReducer = createReducer<IFollowAuthorState, RootAction>(defaultState)
  .handleAction([actions.followAuthor.request, actions.unfollowAuthor.request], reduceRequestAction)
  .handleAction([actions.followAuthor.cancel], reduceCancelAction)
  .handleAction([actions.followAuthor.failure, actions.unfollowAuthor.failure], reduceFailureAction)
  .handleAction(actions.fetchFolloweeIds.request, (state, action) =>
    produce(state, draft => {
      draft.isFetchingFolloweeIds = true;
    })
  )
  .handleAction([actions.fetchFolloweeIds.failure, actions.fetchFolloweeIds.cancel], (state, action) =>
    produce(state, draft => {
      draft.isFetchingFolloweeIds = false;
    })
  )
  .handleAction(actions.fetchFolloweeIds.success, (state, action) =>
    produce(state, draft => {
      draft.followeeIds = action.payload;
      draft.followeesFetchedAt = new Date();
      draft.isFetchingFolloweeIds = false;
    })
  )
  .handleAction(actions.followAuthor.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.followeeIds.push(action.payload);
    })
  )
  .handleAction(actions.unfollowAuthor.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      const index = draft.followeeIds.indexOf(action.payload);
      if (index > -1) {
        draft.followeeIds.splice(index, 1);
      }
    })
  )
  .handleAction(commonActions.signOut.success, () => ({
    ...defaultState,
  }));

export default followAuthorReducer;
