import React from 'react';
import { OpenInNewOutlined } from '@mui/icons-material';
import { styled } from "@mui/material/styles";

interface Props {
  children: React.ReactNode;
}

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const OpenInNewIcon = styled(OpenInNewOutlined)(({ theme }) => ({
  paddingLeft: theme.spacing(1.25),
  fontSize: theme.typography.body1['fontSize'],
  color: theme.palette.grey[600],
}));

export const OpenInNewTabTitle = ({ children }: Props) => {
  return (
    <StyledDiv>
      {children}
      <OpenInNewIcon />
    </StyledDiv>
  );
};
