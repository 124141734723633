import { useServiceProvider } from '../Providers';
import React from 'react';

export function useIsPushSupported() {
  const { pushMessagingService } = useServiceProvider();
  const [isPushSupported, setIsPushSupported] = React.useState<boolean>(false);

  React.useEffect(() => {
    pushMessagingService.isSupported().then(setIsPushSupported);
  }, [pushMessagingService]);

  return isPushSupported;
}
