import { formatDate } from '../../utils/formatter/dateFormatter';
import {
  Distance,
  Duration,
  IAverageRating,
  ISimpleLocation,
  ITourProgress,
  ITourSummary,
  IUserPreview,
  TourVisibility,
} from '../../models';
import { checkViewTourContentPermissions } from '../common/utils/checkViewTourContentPermissions';
import { Language } from '@lialo/common/lib/language';

export interface ITourCardVM {
  date: string;
  updatedAt?: string;
  title?: string;
  imageUrl?: string;
  description?: string;
  duration?: Duration;
  distance?: Distance;
  isCancelable: boolean;
  canSeeContent: boolean;
  playAtHome: boolean;
  startPoint?: ISimpleLocation;
  isMyTour: boolean;
  tourCode?: string;
  isTourValid?: boolean;
  isUnavailable: boolean;
  visibility?: TourVisibility;
  user?: IUserPreview;
  showRateButton: boolean;
  showDonateButton: boolean;
  averageRating?: IAverageRating;
  clickMode?: 'play' | 'edit';
  purchased: boolean;
  price?: string;
  language?: Language;
}

export const convertPlayedToursToTourCardVMs = (
  tourProgress: ITourProgress,
  tourSummary: ITourSummary | null,
  isMyTour: boolean
): ITourCardVM => {
  const currentPage = tourProgress.playbook?.currentPage;

  return {
    date: formatDate(tourProgress.startedAt),
    title: tourSummary?.title ?? tourProgress.title,
    imageUrl: tourSummary?.imageUrl ?? tourProgress.mainImageUrl,
    description: tourSummary?.description,
    duration: tourSummary?.duration,
    distance: tourSummary?.distance,
    isCancelable: currentPage !== undefined,
    startPoint: tourSummary?.startLocation,
    visibility: tourSummary?.visibility,
    tourCode: tourProgress.shortLink,
    isTourValid: !!tourSummary,
    isUnavailable: !tourSummary,
    playAtHome: tourSummary?.playAtHome ?? false,
    user: tourSummary?.user,
    canSeeContent: checkViewTourContentPermissions(isMyTour, tourProgress, false) === undefined && tourSummary !== null,
    showRateButton: canRateTour(isMyTour, tourProgress, tourSummary),
    showDonateButton: canDonateTour(isMyTour, tourProgress, tourSummary),
    averageRating: tourSummary?.averageRating,
    clickMode: 'play',
    purchased: tourProgress.purchased === true,
    price: tourSummary?.price,
    language: tourSummary?.language,
    isMyTour,
  };
};

function canRateTour(isMyTour: boolean, tourProgress: ITourProgress, tourSummary: ITourSummary | null): boolean {
  return (
    !isMyTour &&
    (tourSummary?.price ? tourProgress.purchased === true : true) &&
    tourProgress.completedOnce === true &&
    tourSummary !== null &&
    !tourProgress.didRateTour
    // Temporarily disable duration constraint
    // &&
    // isMinPlayDurationFulfilled(tourProgress, tourSummary?.duration)
  );
}

function canDonateTour(isMyTour: boolean, tourProgress: ITourProgress, tourSummary: ITourSummary | null): boolean {
  return !isMyTour && tourProgress.completedOnce === true && tourSummary?.enableDonation === true;
}

export const convertCreatedToursToTourCardVMs = (
  tourSummary: ITourSummary,
  tourProgress?: ITourProgress
): ITourCardVM => {
  const currentPage = tourProgress?.playbook?.currentPage;

  return {
    date: formatDate(tourSummary.createdAt),
    updatedAt: formatDate(tourSummary.updatedAt),
    title: tourSummary.title,
    imageUrl: tourSummary.imageUrl,
    description: tourSummary.description,
    isCancelable: currentPage !== undefined,
    startPoint: tourSummary.startLocation,
    visibility: tourSummary.visibility,
    tourCode: tourSummary.shortLink,
    playAtHome: tourSummary.playAtHome,
    isMyTour: true,
    isUnavailable: false,
    isTourValid: tourSummary.valid,
    user: tourSummary.user,
    canSeeContent: checkViewTourContentPermissions(true, tourProgress, false) === undefined,
    showRateButton: false,
    showDonateButton: false,
    averageRating: tourSummary.averageRating,
    clickMode: 'edit',
    purchased: tourProgress?.purchased === true,
    price: tourSummary.price,
    language: tourSummary.language,
  };
};
