import { AsyncActionResult, takeAsyncActionResult } from '../../utils/sagas';
import { put } from 'redux-saga/effects';
import { commonActions } from '../index';
import { AsyncError, ErrorType } from "../../../common";

export default function* reauthenticateWithPassword() {
  let result: AsyncActionResult | undefined = undefined;
  yield put(commonActions.reauthenticate.showDialog());
  do {
    result = yield takeAsyncActionResult(commonActions.reauthenticate);
    if (result === AsyncActionResult.CANCELED) {
      throw new AsyncError(ErrorType.USER_CANCELED);
    }
  } while (result === AsyncActionResult.FAILURE);
}
