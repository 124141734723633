import { put, select, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { commonActions } from '../../common';
import { playTourSelectors } from '../../playTour';

const clearCompletedOfflineTours = takeLeading(getType(commonActions.initApp), function* () {
  const completedTourShortLinks = playTourSelectors.selectCompletedTourShortLinks(yield select());

  for (let i = 0; i < completedTourShortLinks.length; i++) {
    yield put(commonActions.removeTour(completedTourShortLinks[i]));
  }
});

export default [clearCompletedOfflineTours];
