import React from 'react';
import { styled } from '@mui/material/styles';
import { SvgIconProps, Typography, TypographyProps } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import classNames from 'classnames';

const PREFIX = 'ErrorMessage';

const classes = {
  vertical: `${PREFIX}-vertical`,
  horizontal: `${PREFIX}-horizontal`,
  icon: `${PREFIX}-icon`,
  error: `${PREFIX}-error`,
};

const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`&.${classes.vertical}`]: {
    textAlign: 'center',
  },

  [`&.${classes.horizontal}`]: {
    display: 'flex',
  },

  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1),
  },

  [`&.${classes.error}`]: {
    color: theme.palette.error.main,
  },
}));

interface IProps {
  message: string;
  className?: string;
  iconSize?: SvgIconProps['fontSize'];
  messageVariant?: TypographyProps['variant'];
  orientation: 'horizontal' | 'vertical';
  variant?: 'error' | 'default';
}

export const ErrorMessage = (props: IProps) => {


  return (
    <Root
      className={classNames(
        props.className,
        props.orientation === 'horizontal' ? classes.horizontal : classes.vertical,
        {
          [classes.error]: props.variant === 'error',
        },
      )}
    >
      <ErrorOutline className={classes.icon} fontSize={props.iconSize ?? 'medium'} />
      <Typography variant={props.messageVariant ?? 'body1'}>{props.message}</Typography>
    </Root>
  );
};
