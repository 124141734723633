import { IImage } from './Media';

export type TaskType = 'Simple' | 'YesNo' | 'SingleChoice';

export interface ITask {
  taskImages?: IImage[];
  question?: string;
  answer?: string;
  wrongAnswer1?: string;
  wrongAnswer2?: string;
  wrongAnswer3?: string;
  hint?: string;
}

export function countAnswerOptions(task: ITask): number {
  return [task.answer, task.wrongAnswer1, task.wrongAnswer2, task.wrongAnswer3].filter((answer) => !!answer).length;
}
