import { select, takeEvery } from 'redux-saga/effects';
import { getType, PayloadMetaAction, TypeConstant } from 'typesafe-actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import selectors from '../selectors';
import { commonActions } from '../../common';

const clearAssetCacheSaga = takeEvery([getType(commonActions.clearAssetCache)], function* (
  action: PayloadMetaAction<TypeConstant, string, any>
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  if (serviceProvider.cacheService.supportsCache()) {
    try {
      const state = yield select();
      const imageUrls = selectors.selectAllTourImages(state, { tour: action.payload });
      const audioUrls = selectors.selectAllAudioUrls(state, { tour: action.payload });
      yield serviceProvider.cacheService.removeFromCache('image-cache', imageUrls);
      yield serviceProvider.cacheService.removeFromCache('audio-cache', audioUrls);
    } catch (e) {
      console.error(e);
      serviceProvider.analyticsService.logError(e);
    }
  }
});

export default [clearAssetCacheSaga];
