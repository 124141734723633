/* eslint-disable no-extend-native */
export function normalize<T, K extends keyof T>(
  data: T[],
  idSelector: (item: T) => string | number
): Record<string | number, T> {
  const obj: Record<string | number, T> = {};
  data.forEach(d => {
    obj[idSelector(d)] = d;
  });
  return obj;
}

export function arrayToObject<T, U>(
  array: T[],
  keySelector: (item: T) => string | number,
  valueTransformer: (item: T) => U
): Record<string | number, U> {
  const obj: Record<string | number, U> = {};
  array.forEach(d => {
    obj[keySelector(d)] = valueTransformer(d);
  });
  return obj;
}

export function addOrRemove<T>(arr: T[], value: T): T[] {
  const pos = arr.indexOf(value);
  const copy = [...arr];
  if (pos !== -1) {
    copy.splice(pos, 1);
  } else {
    copy.push(value);
  }
  return copy;
}

export function isEmptyArray(val: any): boolean {
  return val && Array.isArray(val) && val.length === 0;
}

declare global {
  interface Array<T> {
    sortBy<K extends keyof T>(key: K, order: 'ASC' | 'DESC', comparator?: (a: K, b: K) => number): Array<T>;

    distinct(key: keyof T): Array<T>;

    shuffle(seed: number): Array<T>;

    mapNotOptional<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any): NonNullable<U>[];

    flatten<U>(): Array<U>;

    removeElement(needle: T): void;

    includesAll(elements: T[]): boolean;

    groupBy(by: (element: T) => string): Record<string, T[]>;
  }
}

Array.prototype.mapNotOptional = function (callbackfn, thisArg) {
  return this.map(callbackfn, thisArg).filter(item => item !== undefined && item !== null) as NonNullable<
    ReturnType<typeof callbackfn>
  >[];
};

Array.prototype.sortBy = function (key, order, comparator = (a: any, b: any) => a - b) {
  const copy = [...this];
  copy.sort((a, b) => comparator(a[key], b[key]));
  return order === 'DESC' ? copy.reverse() : copy;
};

Array.prototype.distinct = function (key) {
  return this.filter((item, index, array) => array.findIndex(t => t[key] === item[key]) === index);
};

Array.prototype.shuffle = function (seed) {
  const arr = this.slice();
  let currentIndex = arr.length;

  const random = () => {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    const randomIndex = Math.floor(random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    const temporaryValue = arr[currentIndex];
    arr[currentIndex] = arr[randomIndex];
    arr[randomIndex] = temporaryValue;
  }
  return arr;
};

Array.prototype.flatten = function () {
  return [].concat(...this);
};

Array.prototype.removeElement = function (needle) {
  const index = this.indexOf(needle);
  if (index > -1) {
    this.splice(index, 1);
  }
};

Array.prototype.includesAll = function (elements) {
  return elements.every(e => this.includes(e));
};

Array.prototype.groupBy = function <T>(by: (element: T) => string) {
  return this.reduce((acc, cur) => {
    const group = by(cur);
    acc[group] = [...(acc[group] ?? []), cur];
    return acc;
  }, {} as Record<string, T[]>);
};
