import {
  Api,
  IAnalyticsService,
  IApi,
  IAppUpdateService,
  IAuthService,
  ICacheService,
  IConnectionService,
  IErrorReporter,
  IFileSizeValidator,
  IImageCropService,
  IStorageService,
  INavigationService,
  IPushMessagingService,
  IServiceProvider,
  IShareService,
} from '@lialo/core/lib/services';
import { ILogger } from '@lialo/core/lib/services/analytics/logger';
import AnalyticsService from './analyticsService';
import AppUpdateService from './appUpdateService';
import CacheService from './cacheService';
import ConnectionService from './connectionService';
import ErrorReporter from './errorReporter';
import { History } from 'history';
import StackdriverErrorReporter from 'stackdriver-errors-js';
import FileSizeValidator from './fileSizeValidator';
import StorageService from './storageService';
import NavigationService from './navigationService';
import ShareService from './shareService';
import SentryLogger from './logger';
import PushMessagingService from './pushMessagingService';
import i18n from '../i18n';

export default class ServiceProvider implements IServiceProvider {
  private _analyticsService?: IAnalyticsService;
  private _api?: IApi;
  private _appUpdateService?: IAppUpdateService;
  private _cacheService?: ICacheService;
  private _connectionService?: IConnectionService;
  private _errorReporter?: IErrorReporter;
  private _fileSizeValidator?: IFileSizeValidator;
  private _storageService?: IStorageService;
  private _logger?: ILogger;
  private _navigationService?: INavigationService;
  private _shareService?: IShareService;
  private _pushMessagingService?: IPushMessagingService;

  private _authService?: Promise<IAuthService>;
  private _imageCropService?: Promise<IImageCropService>;

  constructor(
    private readonly baseUrl: string,
    private readonly apiContractVersion: number,
    private readonly history: History,
    private readonly errorHandler: StackdriverErrorReporter
  ) {
    this.getAuthService = this.getAuthService.bind(this);
  }

  get analyticsService(): IAnalyticsService {
    if (!this._analyticsService) {
      this._analyticsService = new AnalyticsService();
    }
    return this._analyticsService!;
  }

  get api(): IApi {
    if (!this._api) {
      this._api = new Api(this.baseUrl, this.apiContractVersion, () => this.getAuthService());
    }
    return this._api!;
  }

  get appUpdateService(): IAppUpdateService {
    if (!this._appUpdateService) {
      this._appUpdateService = new AppUpdateService();
    }
    return this._appUpdateService!;
  }

  get cacheService(): ICacheService {
    if (!this._cacheService) {
      this._cacheService = new CacheService();
    }
    return this._cacheService!;
  }

  get connectionService(): IConnectionService {
    if (!this._connectionService) {
      this._connectionService = new ConnectionService();
    }
    return this._connectionService!;
  }

  get errorReporter(): IErrorReporter {
    if (!this._errorReporter) {
      this._errorReporter = new ErrorReporter(this.errorHandler);
    }
    return this._errorReporter!;
  }

  get fileSizeValidator(): IFileSizeValidator {
    if (!this._fileSizeValidator) {
      this._fileSizeValidator = new FileSizeValidator();
    }
    return this._fileSizeValidator!;
  }

  get storageService(): IStorageService {
    if (!this._storageService) {
      this._storageService = new StorageService();
    }
    return this._storageService!;
  }

  get logger(): ILogger {
    if (!this._logger) {
      this._logger = new SentryLogger();
      // mock implementation, can be replaced by SentryLogger
      // this._logger = {
      //   setUser(userId: string) {},
      //   logError(error: Error) {},
      //   logMessage(message: string) {},
      // };
    }
    return this._logger!;
  }

  get navigationService(): INavigationService {
    if (!this._navigationService) {
      this._navigationService = new NavigationService(this.history, i18n);
    }
    return this._navigationService!;
  }

  get shareService(): IShareService {
    if (!this._shareService) {
      this._shareService = new ShareService();
    }
    return this._shareService!;
  }

  get pushMessagingService(): IPushMessagingService {
    if (!this._pushMessagingService) {
      this._pushMessagingService = new PushMessagingService();
    }
    return this._pushMessagingService!;
  }

  getAuthService(): Promise<IAuthService> {
    if (!this._authService) {
      this._authService = import('./authService').then(Import => new Import.default());
    }
    return this._authService!;
  }

  getImageCropService(): Promise<IImageCropService> {
    if (!this._imageCropService) {
      this._imageCropService = import('./imageCropService').then(Import => new Import.default());
    }
    return this._imageCropService!;
  }
}
