import { createAsyncAction } from 'typesafe-actions';
import { AsyncError } from '../../common';
import { LandingPageType, LoadSuccess } from './models';

const loadTours = createAsyncAction(
  '@landing/LOAD_TOURS_REQUEST',
  '@landing/LOAD_TOURS_SUCCESS',
  '@landing/LOAD_TOURS_FAILURE',
)<LandingPageType, LoadSuccess, AsyncError>();


export default {
  loadTours,
};
