import { put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { showErrorSnackbar, showSnackbar } from '../../common/utils/showSnackbar';
import { requireUserOrThrow } from '../../utils/sagas';

const sendTipReminderSaga = takeLatest(getType(actions.sendTipReminder.request), function* (
  action: ReturnType<typeof actions.sendTipReminder.request>,
) {
  const { onSuccess, ...tipReminderRequest } = action.payload;
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  try {
    yield requireUserOrThrow();

    yield serviceProvider.api.sendTipReminder(tipReminderRequest);
    yield put(actions.sendTipReminder.success());

    yield showSnackbar({ key: 'play_tour.end.donate.send_success' });
    onSuccess();
  } catch (e) {
    yield put(actions.sendTipReminder.failure(e));
    yield showErrorSnackbar(e);
    serviceProvider.analyticsService.logError(e);
  }
});

export default [sendTipReminderSaga];
