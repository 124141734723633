import { getType } from 'typesafe-actions';
import { put, takeEvery } from 'redux-saga/effects';
import actions from '../actions';

const completeTourSaga = takeEvery(getType(actions.completeTour.request), function* (
  action: ReturnType<typeof actions.completeTour.request>
) {
  yield put(actions.completeTour.success(action.payload));
});

export default [completeTourSaga];
