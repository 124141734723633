import { createAction, createAsyncAction, createCustomAction, TypeConstant } from 'typesafe-actions';
import { ITourSettings, TourVisibility } from '../../models';
import { LocationDescriptorObject, LocationState, Path } from 'history';
import { ImageDetailPath, ImageListPath } from './utils/imagePathHelper';
import { IImageState, ISingleChoiceTaskState, ITourState, ITourStopState } from './models/state';
import { IAsyncError, ImageUploadResponse } from '../../common';
import { ICreateTourModel } from './models/Tour';

const updateTourFieldCreateHandler = <T extends TypeConstant>(type: T) => {
  return <F extends keyof ITourState>(field: F, value: ITourState[F], save = false) => ({
    type,
    payload: { field, value },
    meta: { save },
  });
};
const updateTourField = createCustomAction('@createTour/UPDATE_TOUR_FIELD', updateTourFieldCreateHandler);
const sanitizeTourField = createCustomAction('@createTour/SANITIZE_TOUR_FIELD', updateTourFieldCreateHandler);

const updateTourStopFieldCreateHandler = <T extends TypeConstant>(type: T) => {
  return <F extends keyof ITourStopState>(localId: number, field: F, value: ITourStopState[F], save = false) => ({
    type,
    payload: { localId, field, value },
    meta: { save },
  });
};
const updateTourStopField = createCustomAction('@createTour/UPDATE_TOUR_STOP_FIELD', updateTourStopFieldCreateHandler);
const sanitizeTourStopField = createCustomAction('@createTour/SANITIZE_TOUR_STOP_FIELD', updateTourStopFieldCreateHandler);

const createOrUpdateTaskCreateHandler = <T extends TypeConstant>(type: T) => {
  return <F extends keyof ISingleChoiceTaskState>(localId: number, field: F, value: ISingleChoiceTaskState[F]) => ({
    type,
    payload: { localId, field, value },
  });
};
const updateTask = createCustomAction('@createTour/CREATE_OR_UPDATE_TASK', createOrUpdateTaskCreateHandler);
const sanitizeTask = createCustomAction('@createTour/SANITIZE_TASK', createOrUpdateTaskCreateHandler);

const updateTourVisibility = {
  request: createAction('@createTour/UPDATE_TOUR_VISIBILITY_REQUEST', action => {
    return (visibility: TourVisibility) => action(visibility);
  }),
  confirmed: createAction('@createTour/UPDATE_TOUR_VISIBILITY_CONFIRMED', action => {
    return (visibility: TourVisibility) => action(visibility);
  }),
};

const startEditingTour = createAction('@createTour/START_EDITING_TOUR');

const rollbackTourState = createAction('@createTour/ROLLBACK_TOUR_STATE');

const createTourStop = createAction('@createTour/CREATE_TOURSTOP');

const resetActive = createAction('@createTour/RESET_ACTIVE');

const deleteTourStop = createAction('@createTour/DELETE_TOUR_STOP', action => {
  return (localId: number) => action(localId, { save: true });
});

const populateTourState = {
  request: createAction('@createTour/POPULATE_TOUR_STATE_REQUEST', action => {
    return (shortLink?: string) => action(shortLink);
  }),
  success: createAction('@createTour/POPULATE_TOUR_STATE_SUCCESS', action => {
    return (tour: ICreateTourModel) => action(tour);
  }),
  failure: createAction('@createTour/POPULATE_TOUR_STATE_FAILURE', action => {
    return (error: IAsyncError) => action(error);
  }),
};
const insertFakeTour = createAction('@app/INSERT_FAKE_TOUR');

const toggleSettingsFieldCreateHandler = <T extends TypeConstant>(type: T) => {
  return <S extends keyof ITourSettings>(settingName: S, value: any) => ({
    type,
    payload: { settingName, value },
  });
};

const toggleIsRoundTrip = createAction('@createTour/TOGGLE_IS_ROUND_TRIP');

const toggleSettingsField = createCustomAction('@createTour/TOGGLE_SETTINGS_FIELD', toggleSettingsFieldCreateHandler);

const saveTourAsync = {
  request: createAction('@createTour/SAVE_TOUR_REQUEST', action => {
    return (updateSnapshot = false) =>
      action(undefined, {
        updateSnapshot,
      });
  }),
  success: createAction('@createTour/SAVE_TOUR_SUCCESS', action => {
    return (tour: ICreateTourModel) => action(tour);
  }),
  failure: createAction('@createTour/SAVE_TOUR_FAILURE', action => {
    return (error: IAsyncError) => action(error);
  }),
};

const uploadImageAsync = {
  request: createAction('@createTour/UPLOAD_IMAGE_REQUEST', action => {
    return (file: Blob, parentUrl: LocationDescriptorObject<LocationState> | Path, detailPath: ImageDetailPath) =>
      action({
        file,
        parentUrl,
        detailPath,
      });
  }),
  success: createAction('@createTour/UPLOAD_IMAGE_SUCCESS', action => {
    return (imageInfo: ImageUploadResponse, detailPath: ImageDetailPath) => action({ imageInfo, detailPath });
  }),
  failure: createAction('@createTour/UPLOAD_IMAGE_FAILURE', action => {
    return (error: IAsyncError) => action(error);
  }),
};

const createImage = createAction('@createTour/CREATE_IMAGE', action => {
  return (path: ImageDetailPath) => action({ path });
});

const updateImage = createAction('@createTour/UPDATE_IMAGE', action => {
  return (path: ImageListPath, localId: number, value: Partial<IImageState>) =>
    action({
      path,
      localId,
      value,
    });
});

const guidanceDialog = {
  show: createAction('@createTour/SHOW_GUIDANCE_DIALOG'),
  hide: createAction('@createTour/HIDE_GUIDANCE_DIALOG'),
};

const deleteImage = createAction('@createTour/DELETE_IMAGE', action => {
  return (listPath: ImageListPath, localId: number) => action({ listPath, localId }, { save: true });
});

const reorderImages = createAction('@createTour/REORDER_IMAGES', action => {
  return (listPath: ImageListPath, newIndex: number, oldIndex: number) =>
    action(
      {
        listPath,
        newIndex,
        oldIndex,
      },
      { save: true }
    );
});

const reorderTourStops = createAction('@createTour/REORDER_TOUR_STOPS', action => {
  return (newIndex: number, oldIndex: number) =>
    action(
      {
        newIndex,
        oldIndex,
      },
      { save: true }
    );
});

const updatePreviewTourStop = createAction('@createTour/UPDATE_PREVIEW_TOUR_STOP', action => (stopId: number) =>
  action({ stopId }, { save: true })
);

const share = {
  link: createAction('@createTour/SHARE_LINK'),
  code: createAction('@createTour/SHARE_CODE'),
};

const requestReview = createAsyncAction(
  '@createTour/REQUEST_REVIEW_REQUEST',
  '@createTour/REQUEST_REVIEW_SUCCESS',
  '@createTour/REQUEST_REVIEW_FAILURE'
)<void, void, IAsyncError>();

const cloneTour = {
  request: createAction('@createTour/CLONE_TOUR_REQUEST'),
  start: createAction('@createTour/CLONE_TOUR_START'),
  success: createAction('@createTour/CLONE_TOUR_SUCCESS', action => (tour: ICreateTourModel) => action(tour)),
  failure: createAction('@createTour/CLONE_TOUR_FAILURE', action => (error: IAsyncError) => action(error)),
  cancel: createAction('@createTour/CLONE_TOUR_CANCEL'),
};

const translateTour = createAsyncAction(
  '@createTour/TRANSLATE_TOUR_REQUEST',
  '@createTour/TRANSLATE_TOUR_SUCCESS',
  '@createTour/TRANSLATE_TOUR_FAILURE',
  '@createTour/TRANSLATE_TOUR_CANCEL'
)<string, ICreateTourModel, IAsyncError, void>();

export default {
  saveTourAsync,
  updateTourField,
  sanitizeTourField,
  uploadImageAsync,
  startEditingTour,
  rollbackTourState,
  createTourStop,
  updateTourStopField,
  sanitizeTourStopField,
  deleteTourStop,
  resetActive,
  updateTask,
  sanitizeTask,
  populateTourState,
  toggleSettingsField,
  createImage,
  updateImage,
  reorderImages,
  deleteImage,
  reorderTourStops,
  insertFakeTour,
  updateTourVisibility,
  updatePreviewTourStop,
  share,
  requestReview,
  toggleIsRoundTrip,
  guidanceDialog,
  cloneTour,
  translateTour,
};
