import produce from 'immer';
import { IImageState, ITourState } from '../models/state';
import { ICreateTourModel } from '../models/Tour';
import { IImageList } from '../models/Image';

export function mergeServerIdsIntoState(state: ITourState, tour: ICreateTourModel): ITourState {
  return produce(state, draftState => {
    draftState.id = tour.id;

    mergeImageIdsIntoState(draftState.images, tour.imageList);

    draftState.stops.forEach(stateStop => {
      const idType = stateStop.id > 0 ? 'id' : 'localId';
      const savedStop = tour.stops.find(s => s[idType] === stateStop[idType]);
      if (savedStop) {
        stateStop.id = savedStop.id;
        mergeImageIdsIntoState(stateStop.images, savedStop.imageList);
        if (savedStop.task) {
          stateStop.task.id = savedStop.task.id;
          mergeImageIdsIntoState(stateStop.task.images, savedStop.task.imageList);
        }
      }
    });
  });
}

function mergeImageIdsIntoState(imagesInState: IImageState[], imageList?: IImageList) {
  if (!imageList) {
    return;
  }

  imageList.images.forEach(image => {
    const imageInState = imagesInState.find(stateImage => stateImage.localId === image.localId && stateImage.type === image.type);
    if (imageInState) {
      imageInState.id = image.id;
    }
  });
}
