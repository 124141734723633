import produce from 'immer';
import { IImageState, isEmptyTaskState, ITourState, ITourStopState } from '../models/state';
import { createEmptyTour, ICreateTourModel } from '../models/Tour';
import { IFirebaseUser } from '../../../models';
import { ICreateImageModel, IImageList } from '../models/Image';
import { ICreateTourStopModel } from '../models/TourStop';
import { ICreateTaskModel } from '../models/Task';

export function mergeStateIntoTour(
  state: ITourState,
  tour: ICreateTourModel | undefined,
  user: IFirebaseUser
): ICreateTourModel {
  if (!tour) {
    tour = createEmptyTour(user);
  }

  return produce(tour, draftTour => {
    draftTour.startLocation = mergeOrUnset(draftTour.startLocation, state.startLocation);
    draftTour.endLocation = mergeOrUnset(draftTour.endLocation, state.endLocation);
    draftTour.visibility = state.visibility;
    draftTour.language = state.language;
    draftTour.description = state.description;
    draftTour.title = state.title;
    draftTour.endText = state.endText;
    draftTour.startText = state.startText;
    draftTour.stops = state.stops.map(stopState => createOrMergeTourStop(stopState, draftTour));
    draftTour.settings = state.settings;
    draftTour.imageList = createOrUpdateImageList(state.images, draftTour.imageList);
  });
}

function createOrUpdateImageList(images: IImageState[], imageList?: IImageList) {
  if (images.length === 0) return undefined;

  const updatedImageList = imageList ?? {
    id: 0,
    images: [],
  };

  updatedImageList.images = images.map(imageState => createOrUpdateImage(imageState, updatedImageList.images));
  return updatedImageList;
}

function createOrUpdateImage(imageState: IImageState, draftImages: ICreateImageModel[]) {
  const image: ICreateImageModel = draftImages.find(image => image.id === imageState.id) ?? {
    id: 0,
    localId: 0,
    orderId: 0,
    type: imageState.type,
  };
  image.localId = imageState.localId;
  image.url = imageState.url;
  image.filename = imageState.filename;
  image.orderId = imageState.orderId;
  image.type = imageState.type;
  image.caption = imageState.caption;

  return image;
}

function mergeOrUnset<T extends { id: number }>(existing?: T, update?: any): T | undefined {
  if (!update) return undefined;
  if (!existing) return { id: 0, ...update };
  return { id: existing.id, ...update };
}

function mergeOrUnsetTask(stopState: ITourStopState, tourStop: ICreateTourStopModel) {
  if (isEmptyTaskState(stopState.task)) {
    tourStop.task = undefined;
  } else {
    const task: ICreateTaskModel = tourStop.task ?? {
      id: 0,
    };

    task.imageList = createOrUpdateImageList(stopState.task.images, task.imageList);
    task.answer = stopState.task.answer;
    task.question = stopState.task.question;
    task.hint = stopState.task.hint;
    task.wrongAnswer1 = stopState.task.wrongAnswer1;
    task.wrongAnswer2 = stopState.task.wrongAnswer2;
    task.wrongAnswer3 = stopState.task.wrongAnswer3;

    tourStop.task = task;
  }
}

function createOrMergeTourStop(
  stopState: ITourStopState,
  draftTour: ICreateTourModel,
): ICreateTourStopModel {
  const tourStop: ICreateTourStopModel = draftTour.stops.find(s => s.id === stopState.id) ?? {
    id: 0,
    orderId: 1,
    lookIntoTour: false,
    waypointsEnabled: false,
  };

  tourStop.localId = stopState.localId;
  tourStop.orderId = stopState.orderId;
  tourStop.imageList = createOrUpdateImageList(stopState.images, tourStop.imageList);
  tourStop.lookIntoTour = stopState.lookIntoTour;
  tourStop.title = stopState.title;
  tourStop.directions = stopState.directions;
  tourStop.information = stopState.information;
  tourStop.waypointsEnabled = stopState.waypointsEnabled;
  tourStop.waypoints = stopState.waypoints;

  mergeOrUnsetTask(stopState, tourStop);

  return tourStop;
}
