import { put, select, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { IPrivateUser, IUserTranslation } from '../../../models';
import { showErrorSnackbar } from '../../common/utils/showSnackbar';
import selectors from '../selectors';
import produce from 'immer';
import { normalize } from '../../../utils/helper/array';
import { exclude } from '../../../utils/helper/object';

const updateUserTranslationSaga = takeLatest(getType(actions.updateUserTranslation), function* (
  action: ReturnType<typeof actions.updateUserTranslation>
) {
  const { field, value, language } = action.payload;

  const privateUser: IPrivateUser | undefined = selectors.selectPrivateUser(yield select());

  if (privateUser) {
    const updatedTranslation = produce(
      normalize(privateUser.translations, t => t.language),
      draft => {
        const translation: IUserTranslation = draft[language] ?? { language };
        translation[field] = value;
        draft[language] = translation;
      }
    );

    const translations = Object.values(updatedTranslation).filter(translation => {
      // remove translations without any texts
      return Object.values(exclude(translation, ['language'])).some(v => v !== undefined && v.length > 0);
    });

    yield updatePrivateUser({
      ...privateUser,
      translations,
    });
  }
});

const updateWebsiteSaga = takeLatest(getType(actions.updateWebsite), function* (
  action: ReturnType<typeof actions.updateWebsite>
) {
  const website = action.payload.length ? action.payload : undefined; //reset

  const privateUser: IPrivateUser | undefined = selectors.selectPrivateUser(yield select());
  if (privateUser) {
    yield updatePrivateUser({
      ...privateUser,
      website,
    });
  }
});

function* updatePrivateUser(privateUser: IPrivateUser) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  yield put(actions.updatePrivateUser.request());
  try {
    const updatedUser = yield serviceProvider.api.updateUser({
      username: privateUser.username,
      imageUrl: privateUser.imageUrl,
      anonymous: privateUser.anonymous,
      website: privateUser.website,
      translations: privateUser.translations,
    });

    yield put(actions.updatePrivateUser.success(updatedUser));
  } catch (e) {
    yield put(actions.updatePrivateUser.failure(e));
    yield showErrorSnackbar(e);
    serviceProvider.analyticsService.logError(e);
  }
}

export default [updateUserTranslationSaga, updateWebsiteSaga];
