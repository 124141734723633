import { styled } from '@mui/material/styles';
import React from 'react';
import ProfileAvatarLink from '../ProfileAvatar/ProfileAvatarLink';

const PREFIX = 'ProfileLink';

const classes = {
  username: `${PREFIX}-username`,
  profileLink: `${PREFIX}-profileLink`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.username}`]: {
    padding: theme.spacing(0.75, 0, 0, 0),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  [`&.${classes.profileLink}`]: {
    margin: theme.spacing(-1.25, 1, 1.5, 1),
    padding: theme.spacing(0, 2),
  },
}));

interface Props {
  username: string;
  className?: string;
  onClick?: () => void;
}

const ProfileLink = (props: Props) => {
  return (
    <Root className={classes.profileLink}>
      <ProfileAvatarLink onClick={props.onClick} size={48} className={props.className} />
      <div className={classes.username}>{props.username}</div>
    </Root>
  );
};

export default ProfileLink;
