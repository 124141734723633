import { styled } from '@mui/material/styles';
import React from 'react';
import { CircularProgress } from '@mui/material';

const Root = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

interface IProps {}

export const LoadingScreen = (props: IProps) => {
  return (
    <Root>
      <CircularProgress />
    </Root>
  );
};
