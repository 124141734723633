import { eventChannel } from 'redux-saga';
import { IConnectionService } from '../../../services';

export enum ConnectionStatus {
  online,
  offline,
}

export function createConnectionChannel(connectionService: IConnectionService) {
  return eventChannel(emit => {
    function onlineHandler() {
      emit(ConnectionStatus.online);
    }

    function offlineHandler() {
      emit(ConnectionStatus.offline);
    }

    connectionService.subscribe(onlineHandler, offlineHandler);

    return () => {
      connectionService.unsubscribe(onlineHandler, offlineHandler);
    };
  });
}
