import React from 'react';
import { useServiceProvider } from '../Providers';

const LOCAL_STORAGE_KEY = 'addToHomeScreen::ios';

export default function useInstalledOnIOS() {
  const { storageService } = useServiceProvider();
  const setInstalledOnIOS = React.useCallback(
    (installed: boolean) => storageService.setItem(LOCAL_STORAGE_KEY, installed),
    [storageService],
  );
  const getInstalledOnIOS = React.useCallback(() => storageService.getItem<string>(LOCAL_STORAGE_KEY), [
    storageService,
  ]);
  return { getInstalledOnIOS, setInstalledOnIOS };
}

