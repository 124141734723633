import { IErrorReporter } from '@lialo/core/lib/services';
import StackdriverErrorReporter from 'stackdriver-errors-js';

export default class ErrorReporter implements IErrorReporter {
  constructor(private readonly stackdriver: StackdriverErrorReporter) {}

  reportError(e: Error): void {
    this.stackdriver.report(e);
  }
}
