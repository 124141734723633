import { getContext } from 'redux-saga/effects';
import { IFileSizeValidator } from './file/fileSizeValidator';
import { IImageCropService } from './file/imageCropService';
import { ICacheService } from './cache/cache';
import { IAuthService } from './auth/authService';
import { INavigationService } from './navigation/navigationService';
import { IConnectionService } from './connection/connectionService';
import { IApi } from './api/api';
import { IShareService } from './share/shareService';
import { IAppUpdateService } from './appUpdate/appUpdateService';
import { IAnalyticsService } from './analytics/analytics';
import { IStorageService } from './storage/storage';
import { IErrorReporter } from './analytics/errorReporter';
import { ILogger } from './analytics/logger';
import { IPushMessagingService } from './pushMessaging/pushMessagingService';

export * from './api/api';
export * from './auth/authService';
export * from './cache/cache';
export * from './connection/connectionService';
export * from './file/fileSizeValidator';
export * from './file/imageCropService';
export * from './navigation/navigationService';
export * from './share/shareService';
export * from './appUpdate/appUpdateService';
export * from './analytics/analytics';
export * from './analytics/errorReporter';
export * from './storage/storage';
export * from './pushMessaging/pushMessagingService';

export interface IPlatformServices {
  appUpdateService: IAppUpdateService;
  fileSizeValidator: IFileSizeValidator;
  cacheService: ICacheService;
  navigationService: INavigationService;
  connectionService: IConnectionService;
  shareService: IShareService;
  analyticsService: IAnalyticsService;
  storageService: IStorageService;
  errorReporter: IErrorReporter;
  logger: ILogger;
  pushMessagingService: IPushMessagingService;

  getAuthService(): Promise<IAuthService>;

  getImageCropService(): Promise<IImageCropService>;
}

export interface IGlobalServices {
  api: IApi;
}

export type IServiceProvider = IGlobalServices & IPlatformServices;

export const getServiceProvider = function* (): Generator<any, IServiceProvider, IServiceProvider> {
  const serviceProvider = yield getContext('serviceProvider');
  return serviceProvider;
};
