export function getPhysicalPixel(cssPixel: number): number {
  return Math.floor(cssPixel * window.devicePixelRatio);
}

export function getPhysicalBodyWidth() {
  return getPhysicalPixel(window.document.body.clientWidth);
}

export function getPhysicalScreenResolution(): { width: number; height: number } {
  return {
    width: getPhysicalPixel(window.screen.width),
    height: getPhysicalPixel(window.screen.height),
  };
}

export function getDevicePixelRatio() {
  return window.devicePixelRatio;
}

export function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}
