import { getType } from 'typesafe-actions';
import actions from '../actions';
import { put, takeEvery } from 'redux-saga/effects';
import { navigationActions } from '../../common/navigation';
import { getServiceProvider, IServiceProvider, MAX_FILE_SIZE } from '../../../services';
import { AsyncError, ErrorType, ImageUploadResponse } from '../../../common';

const uploadImage = takeEvery(getType(actions.uploadImageAsync.request), function* (
  action: ReturnType<typeof actions.uploadImageAsync.request>
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  if (!serviceProvider.fileSizeValidator.isFileSizeValid(action.payload.file, MAX_FILE_SIZE)) {
    yield put(actions.uploadImageAsync.failure(new AsyncError(ErrorType.INVALID_FILE, 'Maximum file size exceeded.')));
    return;
  }

  try {
    const response: ImageUploadResponse = yield serviceProvider.api.uploadImage(action.payload.file);
    yield put(actions.uploadImageAsync.success(response, action.payload.detailPath));
    yield put(actions.saveTourAsync.request());
    yield put(navigationActions.goBackOrReplace(action.payload.parentUrl));
  } catch (e) {
    yield put(actions.uploadImageAsync.failure(e));
    serviceProvider.analyticsService.logError(e);
  }
});

export default [uploadImage];
