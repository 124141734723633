import { put, race, select, take, takeLatest, takeLeading } from 'redux-saga/effects';
import { Action, getType } from 'typesafe-actions';
import { getServiceProvider, IServiceProvider, NotificationPermission } from '../../services';
import { showErrorSnackbar, showSnackbar } from '../common/utils/showSnackbar';
import { AsyncActionResult, requireUserOrThrow, takeAsyncActionResult, takeConfirmation } from '../utils/sagas';
import { anyNotificationsEnabled, AsyncError, ErrorType } from '../../common';
import * as selectors from './selectors';
import * as actions from './actions';
import { minutesBetween } from '@lialo/common/lib/date';
import { commonActions } from '../common';

const ONE_WEEK_IN_MINUTES = 60 * 24 * 7;

const requestFcmTokenSaga = takeLatest(getType(actions.requestFcmToken.request), function* (
  action: ReturnType<typeof actions.requestFcmToken.request>
) {
  const { silent, permissionTranslationKey } = action.payload;

  const serviceProvider: IServiceProvider = yield getServiceProvider();

  if (!(yield serviceProvider.pushMessagingService.isSupported())) {
    yield put(actions.requestFcmToken.failure(new AsyncError(ErrorType.FCM_UNSUPPORTED, 'FCM not supported')));
    return;
  }

  const permission = serviceProvider.pushMessagingService.getNotificationPermission();

  try {
    if (permission === NotificationPermission.DENIED) {
      if (!silent) {
        yield showSnackbar({
          key: 'common.push_messaging.permission_denied',
        });
      }
      yield put(actions.requestFcmToken.cancel());
      return;
    } else if (permission === NotificationPermission.DEFAULT) {
      if (
        !permissionTranslationKey ||
        !(yield takeConfirmation({
          content: {
            key: permissionTranslationKey,
          },
          positiveKey: 'common.ok',
          negativeKey: false,
        }))
      ) {
        yield put(actions.requestFcmToken.cancel());
        return;
      }
    }

    const token: string = yield serviceProvider.pushMessagingService.getToken();
    const previousToken = selectors.selectFcmToken(yield select());
    yield serviceProvider.api.upsertPushMessagingToken(token, previousToken ?? undefined);
    yield put(actions.requestFcmToken.success(token));
  } catch (e) {
    const error = e instanceof AsyncError ? e : new AsyncError(ErrorType.UNKNOWN, (e as Error).message);
    yield put(actions.requestFcmToken.failure(error));

    if (!silent) {
      yield showErrorSnackbar(error);
    }
  }
});

const deleteFcmTokenSaga = takeLatest(getType(actions.deleteFcmToken.request), function* () {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const fcmToken = selectors.selectFcmToken(yield select());

  try {
    if (fcmToken) {
      yield serviceProvider.api.deletePushMessagingToken(fcmToken);
    }
    yield put(actions.deleteFcmToken.success());
  } catch (e) {
    yield put(actions.deleteFcmToken.failure(e));
  }
});

const refreshFcmTokenSaga = takeLeading(commonActions.initApp, function* () {
  const fcmTokenUpdatedAt = selectors.selectFcmTokenUpdatedAt(yield select());

  if (minutesBetween(fcmTokenUpdatedAt ?? new Date(), new Date()) > ONE_WEEK_IN_MINUTES) {
    yield put(actions.requestFcmToken.request({ silent: true, permissionTranslationKey: false }));
  }
});

const fetchNotificationPreferencesSaga = takeLeading(actions.fetchNotificationPreferences.request, function* () {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    const notificationPreferences = yield serviceProvider.api.fetchNotificationPreferences();
    yield put(actions.fetchNotificationPreferences.success(notificationPreferences));
  } catch (e) {
    yield showErrorSnackbar(e);
    yield put(actions.fetchNotificationPreferences.failure(e));
  }
});

const updateNotificationPreferencesSaga = takeLatest(actions.updateNotificationPreferences.request, function* (
  action: ReturnType<typeof actions.updateNotificationPreferences.request>
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    const response = yield serviceProvider.api.updateNotificationPreferences(action.payload);
    yield put(actions.updateNotificationPreferences.success(response));
  } catch (e) {
    yield put(actions.updateNotificationPreferences.failure(e));
  }
});

const postSignInSaga = takeLeading(getType(commonActions.signIn.success), function* () {
  yield put(actions.fetchNotificationPreferences.request());
  yield takeAsyncActionResult(actions.fetchNotificationPreferences);
  if (selectors.selectNotificationPreferences(yield select())?.pushNotificationsEnabled) {
    yield put(
      actions.requestFcmToken.request({
        permissionTranslationKey: 'common.push_messaging.permission_dialog.content_post_sign_in',
        silent: true,
      })
    );
  }
});

export function* setupNotifications() {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  yield requireUserOrThrow();

  yield put(actions.fetchNotificationPreferences.request());
  yield takeAsyncActionResult(actions.fetchNotificationPreferences);
  let notificationPreferences = selectors.selectNotificationPreferences(yield select());
  if (!notificationPreferences) {
    return false;
  }

  if (!anyNotificationsEnabled(notificationPreferences)) {
    yield put(actions.updateNotificationPreferences.prompt());
    const { canceled } = yield race({
      success: take(actions.updateNotificationPreferences.success),
      canceled: take(actions.updateNotificationPreferences.canceled),
    });

    if (canceled) {
      return false;
    }
  }

  notificationPreferences = selectors.selectNotificationPreferences(yield select());
  if (!notificationPreferences) {
    return false;
  }

  const isPushSupported = yield serviceProvider.pushMessagingService.isSupported();
  if (notificationPreferences.pushNotificationsEnabled && isPushSupported) {
    yield put(
      actions.requestFcmToken.request({
        permissionTranslationKey: 'common.push_messaging.permission_dialog.content_generic',
        silent: false,
      })
    );
    const result: AsyncActionResult = yield takeAsyncActionResult(actions.requestFcmToken);
    if (result !== AsyncActionResult.SUCCESS) {
      return false;
    }
  }

  return true;
}

const sagas = [
  requestFcmTokenSaga,
  deleteFcmTokenSaga,
  refreshFcmTokenSaga,
  fetchNotificationPreferencesSaga,
  updateNotificationPreferencesSaga,
  postSignInSaga,
];
export default sagas;
