import { put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { commonActions } from '../../common';
import selectors from '../selectors';

const requestReviewSaga = takeEvery(getType(actions.requestReview.request), function* () {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  const tourCode: string = yield select(selectors.selectTourCode);

  try {
    yield serviceProvider.api.requestReview(tourCode);
    yield put(actions.requestReview.success());
    yield put(
      commonActions.showSnackbar({
        key: 'create_tour.visibility.public.requested_review_success',
      })
    );
  } catch (e) {
    yield put(actions.requestReview.failure(e));
    serviceProvider.analyticsService.logError(e);
  }
});

export default [requestReviewSaga];
