import { styled } from '@mui/material/styles';
import React from 'react';
import { Badge, ButtonBase } from '@mui/material';
import classNames from 'classnames';
import { EditOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import routes from '@lialo/core/lib/pages/utils/routes';
import { commonSelectors } from '@lialo/core/lib/pages/common';
import ProfileAvatar from './ProfileAvatar';
import { NavLink } from 'react-router-dom';
import { IFirebaseUser, IUserPreview } from '@lialo/core/lib/models';
import * as H from 'history';
import { useServiceProvider } from '../Providers';

const PREFIX = 'ProfileAvatarLink';

const classes = {
  base: `${PREFIX}-base`,
  badge: `${PREFIX}-badge`,
  editIcon: `${PREFIX}-editIcon`,
};

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  [`&.${classes.base}`]: {
    borderRadius: '50%',
    '&.active': {
      pointerEvents: 'none',
    },
  },

  [`& .${classes.badge}`]: {
    border: '2px solid white',
    padding: 0,
    width: 22,
    height: 22,
    borderRadius: 11,
  },

  [`& .${classes.editIcon}`]: {
    fontSize: 14,
    fill: theme.palette.common.white,
  },
})) as typeof NavLink;

interface IProps {
  size: number;
  className?: string;
  onClick?: () => void;
  hideBadge?: boolean;
  user?: IUserPreview;
  stopPropagation?: boolean;
}

const ProfileAvatarLink = (props: IProps) => {
  const loggedInUser = useSelector(commonSelectors.selectUser);

  const to = useGenerateLink(loggedInUser, props.user);

  const onClick = (e: React.MouseEvent) => {
    if (props.stopPropagation) {
      e.stopPropagation();
    }
    props.onClick && props.onClick();
  };

  return (
    <StyledButtonBase
      className={classNames(classes.base, props.className)}
      onClick={onClick}
      component={NavLink}
      to={to}
    >
      <Badge
        classes={{ badge: classes.badge }}
        color="primary"
        overlap="circular"
        invisible={props.hideBadge}
        badgeContent={<EditOutlined className={classes.editIcon} />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <ProfileAvatar size={props.size} user={props.user ?? loggedInUser} />
      </Badge>
    </StyledButtonBase>
  );
};

function useGenerateLink(loggedInUser?: IFirebaseUser, otherUser?: IUserPreview): H.LocationDescriptor {
  const { navigationService } = useServiceProvider();

  if (otherUser) {
    const to = navigationService.generatePublicProfileUrl(otherUser.id);
    const state = { username: otherUser.username };
    return routes.createLocationDescriptorObject(to, state);
  }

  return loggedInUser?.anonymous === false
    ? navigationService.generateI18nPath(routes.PROFILE)
    : navigationService.generateI18nPath(routes.SIGN_IN);
}

export default ProfileAvatarLink;
