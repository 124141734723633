import { ActionPattern, race, take } from 'redux-saga/effects';

export enum AsyncActionResult {
  SUCCESS,
  FAILURE,
  CANCELED,
}

export function* takeAsyncActionResult(asyncAction: {
  success: ActionPattern;
  failure: ActionPattern;
  cancel?: ActionPattern;
}) {
  const { failure, canceled } = yield race({
    success: take(asyncAction.success),
    failure: take(asyncAction.failure),
    canceled: take(asyncAction.cancel ?? 'NEVER'),
  });

  if (canceled !== undefined) {
    return AsyncActionResult.CANCELED;
  } else if (failure !== undefined) {
    return AsyncActionResult.FAILURE;
  } else {
    return AsyncActionResult.SUCCESS;
  }
}
