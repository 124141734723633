import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { IAsyncActionState, reduceFailureAction, reduceRequestAction } from '../utils';
import { RootAction } from '../../store/root';
import { commonActions } from '../common';
import { IAsyncError } from '../../common';
import { ITourDetail, ITourHighlight, ITourInsight, ITourRating } from '../../models';
import actions from './actions';

export interface ITourDetailState extends IAsyncActionState {
  tourDetails?: ITourDetail;
  ratings: ITourRating[];
  numberOfComments?: number;
  sendTourRatingError?: IAsyncError;
  isLoadingTourRatings: boolean;
  isLoadingInsight: boolean;
  isLoadingHighlights: boolean;
  insightError?: IAsyncError;
  tourInsight?: ITourInsight;
  tourHighlights?: ITourHighlight[];
}

const defaultState: ITourDetailState = {
  isLoading: false,
  isLoadingTourRatings: false,
  ratings: [],
  isLoadingInsight: false,
  isLoadingHighlights: false,
};

const tourDetailReducer = createReducer<ITourDetailState, RootAction>(defaultState)
  .handleAction(actions.fetchTourDetailsAsync.request, reduceRequestAction)
  .handleAction(actions.fetchTourDetailsAsync.failure, reduceFailureAction)
  .handleAction(actions.fetchTourDetailsAsync.success, (state, action) =>
    produce(state, draft => {
      draft.tourDetails = action.payload;
      draft.isLoading = false;
    })
  )
  .handleAction(commonActions.signOut.success, (state, action) => ({
    ...defaultState,
  }))
  .handleAction(actions.fetchTourRatingsAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isLoadingTourRatings = true;
      draft.sendTourRatingError = undefined;
    })
  )
  .handleAction(actions.fetchTourRatingsAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoadingTourRatings = false;
      draft.ratings.push(...action.payload.tourRatings);
      draft.numberOfComments = action.payload.numberOfComments;
    })
  )
  .handleAction(actions.fetchTourRatingsAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoadingTourRatings = false;
      draft.sendTourRatingError = action.payload;
    })
  )
  .handleAction(actions.resetTourRating, state =>
    produce(state, draft => {
      draft.ratings = [];
    })
  )
  .handleAction(actions.fetchTourInsightAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isLoadingInsight = true;
      draft.insightError = undefined;
    })
  )
  .handleAction(actions.fetchTourInsightAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoadingInsight = false;
      draft.tourInsight = action.payload;
    })
  )
  .handleAction(actions.fetchTourInsightAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoadingInsight = false;
      draft.insightError = action.payload;
    })
  )
  .handleAction(actions.fetchTourHighlightsAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isLoadingHighlights = true;
      draft.tourHighlights = undefined;
    })
  )
  .handleAction(actions.fetchTourHighlightsAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoadingHighlights = false;
      draft.tourHighlights = action.payload;
    })
  )
  .handleAction(actions.fetchTourHighlightsAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoadingHighlights = false;
    })
  );

export default tourDetailReducer;
