import { put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IAuthService, IServiceProvider } from '../../../services';
import { commonActions } from '../../common';

const updateAuthorities = takeLatest(
  getType(commonActions.signIn.success),
  function* () {
    const serviceProvider: IServiceProvider = yield getServiceProvider();
    const authService: IAuthService = yield serviceProvider.getAuthService();
    const authorities = yield authService.getAuthorities();
    yield put(actions.setAuthorities(authorities));
  }
);

const reloadAuthoritiesSaga = takeLatest(
  getType(commonActions.updateAuthorities),
  function* () {
    const serviceProvider: IServiceProvider = yield getServiceProvider();
    const authService: IAuthService = yield serviceProvider.getAuthService();
    const permissions = yield authService.getAuthorities(true);
    yield put(actions.setAuthorities(permissions));
  }
);

export default [updateAuthorities, reloadAuthoritiesSaga];