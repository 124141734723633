import { put, select, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { AsyncError } from '../../../common';
import { commonSelectors } from '../../common';
import * as selectors from '../selectors';
import * as actions from '../actions';
import { minutesBetween } from '@lialo/common/lib/date';

const fetchFolloweeIdsSaga = takeLeading(getType(actions.fetchFolloweeIds.request), function* (
  action: ReturnType<typeof actions.fetchFolloweeIds.request>
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const isUserRegistered = commonSelectors.selectIsRegisteredUser(yield select());
  const lastUpdateAt = selectors.selectFolloweesFetchedAt(yield select());
  const shouldUpdate =
    action.payload.force ||
    (isUserRegistered && (lastUpdateAt === null || minutesBetween(lastUpdateAt, new Date()) > 15));

  if (!shouldUpdate) {
    yield put(actions.fetchFolloweeIds.cancel());
    return;
  }

  try {
    const followeeIds: string[] = yield serviceProvider.api.fetchFolloweeIds();
    yield put(actions.fetchFolloweeIds.success(followeeIds));
  } catch (e) {
    yield put(actions.fetchFolloweeIds.failure(e as AsyncError));
  }
});

const sagas = [fetchFolloweeIdsSaga];
export default sagas;
