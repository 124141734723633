import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { requireUser } from '../../utils/sagas';
import { createEmptyTour, ICreateTourModel } from '../models/Tour';

const populateTourState = takeEvery(getType(actions.populateTourState.request), function* (
  action: ReturnType<typeof actions.populateTourState.request>
) {
  if (action.payload !== undefined) {
    yield call(fetchTourAndPopulateTourState, action.payload);
  } else {
    const result = yield requireUser();
    if (result.user) {
      yield put(actions.populateTourState.success(createEmptyTour(result.user)));
    } else {
      yield put(actions.populateTourState.failure(result.error));
    }
  }
});

function* fetchTourAndPopulateTourState(shortLink: string) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    const tour: ICreateTourModel = yield serviceProvider.api.fetchCreateTourModel(shortLink);

    yield put(actions.populateTourState.success(tour));
  } catch (error) {
    yield put(actions.populateTourState.failure(error));
    serviceProvider.analyticsService.logError(error);
  }
}

export default [populateTourState];
