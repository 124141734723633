import { styled } from '@mui/material/styles';
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PasswordField } from '@lialo/pwa-ui/lib/TextFields';
import { HintIcon } from '../../common/Icons';
import { errorTranslationKey } from '@lialo/core/lib/translations/translationHelper';
import { connectDialog, IDialogProps } from '@lialo/core/lib/pages/common/connect/connectReauthenticationDialog';
import { LoadingButton } from '@lialo/pwa-ui/lib/LoadingButton';

const PREFIX = 'ReauthenticateDialog';

const classes = {
  paper: `${PREFIX}-paper`,
  resetPassword: `${PREFIX}-resetPassword`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: '100%',
  },

  [`& .${classes.resetPassword}`]: {
    marginTop: theme.spacing(0.5),
  },
}));

const ReauthenticateDialog = (props: IDialogProps) => {
  const { t } = useTranslation();
  const open = props;

  const [password, setPassword] = React.useState('');

  // Reset password
  React.useEffect(() => {
    setPassword('');
  }, [open]);

  const onContinue = () => props.continue(password);

  const renderPasswordInput = () => (
    <>
      <DialogTitle id="form-dialog-title">{t('profile.reauthentication.dialog_title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('profile.reauthentication.dialog_text')}</DialogContentText>
        <PasswordField
          value={password}
          onChange={e => setPassword(e.target.value)}
          error={!!props.error}
          helperText={props.error ? t(errorTranslationKey(props.error)) : undefined}
          label={t('profile.reauthentication.current_password')}
          fullWidth={true}
        />
        <LoadingButton
          component={Button}
          isLoading={props.isSendingPasswordEmail}
          className={classes.resetPassword}
          size={'small'}
          color={'primary'}
          onClick={() => props.sendResetPasswordEmail()}
          startIcon={<HintIcon />}
        >
          {t('common.forgot_password')}
        </LoadingButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.cancel()} color="primary">
          {t('actions.cancel')}
        </Button>
        <LoadingButton
          component={Button}
          isLoading={props.isLoading}
          onClick={onContinue}
          disabled={props.isLoading}
          color="primary"
        >
          {t('actions.continue')}
        </LoadingButton>
      </DialogActions>
    </>
  );

  const renderPasswordEmailConfirmation = () => (
    <>
      <DialogTitle id="form-dialog-title">{t('profile.reauthentication.reset_password.dialog_title')}</DialogTitle>
      <DialogContent>
        {props.hasSentPasswordEmail && t('profile.reauthentication.reset_password.success_message')}
        {props.passwordEmailError && t(errorTranslationKey(props.passwordEmailError!))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.cancel()} color="primary">
          {t('common.ok')}
        </Button>
      </DialogActions>
    </>
  );

  const dialogContent =
    props.hasSentPasswordEmail || !!props.passwordEmailError
      ? renderPasswordEmailConfirmation()
      : renderPasswordInput();

  return (
    <StyledDialog
      open={props.open}
      classes={{ paper: classes.paper }}
      onClose={() => props.cancel()}
      aria-labelledby="form-dialog-title"
    >
      {dialogContent}
    </StyledDialog>
  );
};

export default connectDialog(ReauthenticateDialog);
