import { enumValues } from '../../utils/helper/enum';
import { equals } from '../../utils/helper/deep-equal';
import { Chapter, IPlaybookPage, ITourStop, IWaypoint, StopSection } from '../../models';
import '../../utils/helper/array';

export interface IScore {
  achieved: number;
  max: number;
}

export interface IMapStopLocation {
  lat: number;
  lng: number;
  stopNumber: number;
}

export interface IMapStopLocations {
  previous: IMapStopLocation[]; // previous stop locations
  current: [IMapStopLocation, ...IWaypoint[]] | undefined; // list of waypoints from recent to current stop
  next: IMapStopLocation[]; // upcoming stop locations
}

/**
 * <0 if page1 is before page2
 * 0  if page1 and page2 are equal
 * >0 if page1 is after page2
 * @param page1
 * @param page2
 */
export function comparePlayProgress(page1: IPlaybookPage, page2: IPlaybookPage): number {
  const chapters = enumValues(Chapter);
  const chapterComparison = chapters.indexOf(page1.chapter) - chapters.indexOf(page2.chapter);

  if (chapterComparison !== 0) {
    return chapterComparison;
  }

  const stopComparison = (page1.stopIndex || 0) - (page2.stopIndex || 0);
  if (stopComparison !== 0) {
    return stopComparison;
  }

  const stopSections = enumValues(StopSection);
  return stopSections.indexOf(page1.stopSection!) - stopSections.indexOf(page2.stopSection!);
}

export function getPreviousPlaybookPage(tourStops: ITourStop[], page: IPlaybookPage): IPlaybookPage | undefined {
  const pages = generatePlaybookPages(tourStops);
  const currentPageIndex = pages.findIndex(p => equals(p, page));
  const previousIndex = currentPageIndex - 1;
  return previousIndex >= 0 ? pages[previousIndex] : undefined;
}

export function getNextPlaybookPage(tourStops: ITourStop[], page: IPlaybookPage): IPlaybookPage | undefined {
  const pages = generatePlaybookPages(tourStops);
  const currentPageIndex = pages.findIndex(p => equals(p, page));
  if (currentPageIndex === -1) return undefined;

  const nextIndex = currentPageIndex + 1;
  return nextIndex < pages.length ? pages[nextIndex] : undefined;
}

function generatePlaybookPages(tourStops: ITourStop[]): IPlaybookPage[] {
  const generateTourStopSectionPages = (stop: ITourStop, stopIndex: number): IPlaybookPage[] => {
    const sections = [
      StopSection.directions,
      stop.task === undefined ? undefined : StopSection.task,
      StopSection.information,
    ].filter(s => s !== undefined);

    return sections.map(
      stopSection =>
        ({
          chapter: Chapter.stop,
          stopIndex,
          stopSection,
        } as IPlaybookPage)
    );
  };

  const stopPages = tourStops.sortBy('orderId', 'ASC').map((ts, idx) => generateTourStopSectionPages(ts, idx));
  const flattendStopPages = ([] as IPlaybookPage[]).concat(...stopPages);

  return [{ chapter: Chapter.start }, ...flattendStopPages, { chapter: Chapter.end }];
}
