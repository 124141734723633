import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { commonActions } from '../../common';
import { AsyncError } from '../../../common';

const updateEmailSaga = takeLatest(getType(actions.updateEmail.request), function* (
  action: ReturnType<typeof actions.updateEmail.request>
) {
  const { val: email, done } = action.payload;

  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService = yield serviceProvider.getAuthService();

  try {
    const user = yield authService.updateEmail(email);
    yield put(actions.updateEmail.success(user));
    yield put(commonActions.updateUser(user));
    yield call(done);
  } catch (e) {
    yield put(actions.updateEmail.failure(e as AsyncError));
    serviceProvider.analyticsService.logError(e as AsyncError);
  }
});

export default [updateEmailSaga];
