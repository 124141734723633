import populateSaga from './populateSaga';
import saveSaga from './saveSaga';
import routeSaga from './routeSaga';
import uploadImageSaga from './uploadImageSaga';
import updateTourVisibilitySaga from './updateTourVisibilitySaga';
import shareSaga from './shareSaga';
import reviewSaga from './reviewSaga';
import deleteTourSaga from './deleteTourSaga';
import cloneSaga from './cloneSaga';
import translateSaga from './translateSaga';

export default [
  ...updateTourVisibilitySaga,
  ...populateSaga,
  ...saveSaga,
  ...routeSaga,
  ...uploadImageSaga,
  ...shareSaga,
  ...reviewSaga,
  ...deleteTourSaga,
  ...cloneSaga,
  ...translateSaga,
];
