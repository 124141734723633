import actions from './actions';
import { getType } from 'typesafe-actions';
import { getServiceProvider, IServiceProvider } from '../../services';
import { put, takeEvery } from 'redux-saga/effects';
import { showErrorSnackbar } from '../common/utils/showSnackbar';
import { emptyStringToUndefined } from '../../utils/helper/strings';
import { requireUserOrThrow } from '../utils/sagas';

function* fetchData(action: ReturnType<typeof actions.sendFeedbackAsync.request>) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  const appFeedback = action.payload;

  try {
    yield requireUserOrThrow();
    yield serviceProvider.api.sendAppFeedback({
      feedback: appFeedback.feedback,
      username: emptyStringToUndefined(appFeedback.username),
      email: emptyStringToUndefined(appFeedback.email),
    });
    yield put(actions.sendFeedbackAsync.success());
  } catch (e) {
    serviceProvider.analyticsService.logError(e);
    yield showErrorSnackbar(e);
    yield put(actions.sendFeedbackAsync.failure(e));
  }
}

const requestSaga = takeEvery(getType(actions.sendFeedbackAsync.request), fetchData);
export default [requestSaga];
