import { getType } from 'typesafe-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { getServiceProvider, IServiceProvider } from '../../../services';
import commonActions from '../actions';

const reauthenticateSaga = takeLatest(getType(commonActions.reauthenticate.request), function* ({
  payload: password,
}: ReturnType<typeof commonActions.reauthenticate.request>) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const authService = yield serviceProvider.getAuthService();

  try {
    yield authService.reauthenticate(password);
    yield put(commonActions.reauthenticate.success());
  } catch (e) {
    yield put(commonActions.reauthenticate.failure(e));
    serviceProvider.analyticsService.logError(e);
  }
});

export default [reauthenticateSaga];
