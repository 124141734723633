import { styled } from '@mui/material/styles';
import React from 'react';
import * as H from 'history';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { ExternalLink } from '@lialo/pwa-ui/lib/ExternalLink';
import { Badge, ListItem, Link, ListItemIcon, ListItemText, SvgIconProps } from '@mui/material';

const PREFIX = 'DrawerListItem';

const classes = {
  listItem: `${PREFIX}-listItem`,
  active: `${PREFIX}-active`,
  icon: `${PREFIX}-icon`,
  badge: `${PREFIX}-badge`,
  title: `${PREFIX}-title`,
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [`&.${classes.listItem}`]: {
    width: 'initial',
    borderRadius: 5,
    margin: theme.spacing(0.25, 0.5),
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fill: theme.palette.common.white,
      pointerEvents: 'none',
      [`& .${classes.icon}`]: {
        color: theme.palette.common.white,
      },
    },
  },

  [`& .${classes.active}`]: {},

  [`& .${classes.icon}`]: {
    color: theme.palette.grey[600],
    minWidth: theme.spacing(5),
  },

  [`& .${classes.badge}`]: {
    right: 5,
    position: 'relative',
  },

  [`& .${classes.title}`]: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

interface IProps<S = H.LocationState> {
  title: React.ReactNode;
  component:
    | {
        type: 'external';
        href: string;
      }
    | {
        type: 'navLink';
        to: H.LocationDescriptor<S>;
      }
    | {
        type: 'button';
      }
    | {
        type: 'link';
        href: string;
      };
  icon: React.ComponentType;
  onClick: () => void;
  highlightExact?: boolean;
  badgeIcon?: React.ReactElement<SvgIconProps>;
}

const DrawerListItem = ({ ...props }: IProps) => {
  function linkProps(): React.ComponentProps<any> {
    switch (props.component.type) {
      case 'external':
        return {
          component: ExternalLink,
          href: props.component.href,
        };
      case 'button':
        return {
          component: 'div',
        };
      case 'navLink':
        return {
          component: NavLink,
          to: props.component.to,
        };
      case 'link':
        return {
          component: Link,
          href: props.component.href,
        };
    }
  }

  return (
    <StyledListItem
      button
      className={classes.listItem}
      onClick={props.onClick}
      exact={props.highlightExact}
      {...linkProps()}
    >
      <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        overlap="circular"
        invisible={!props.badgeIcon}
        badgeContent={
          props.badgeIcon
            ? React.cloneElement(props.badgeIcon, {
                style: { fontSize: 12 },
                className: classNames(classes.icon, classes.badge),
              })
            : null
        }
      >
        <ListItemIcon className={classes.icon}>{React.createElement(props.icon)}</ListItemIcon>
      </Badge>
      <ListItemText classes={{ primary: classes.title }} primary={props.title} />
    </StyledListItem>
  );
};

export default DrawerListItem;
