import { equals } from '../../../utils/helper/deep-equal';
import { distanceBetweenLocations } from './mapUtils';
import { ITourFilter, SearchArea } from '../../../common';
import { Language } from '@lialo/common/lib/language';

export type NearbyToursRequest = {
  searchArea: SearchArea;
  filter: ITourFilter;
  uiLang: Language;
};

export function hasSignificantlyChanged(current: NearbyToursRequest, previous: NearbyToursRequest): boolean {
  if (!equals(current.filter, previous.filter) || current.uiLang !== previous.uiLang) {
    return true;
  }

  const currentDiameter = calculateDiameter(current.searchArea);
  const previousDiameter = calculateDiameter(previous.searchArea);
  if (calculateDiameterChangeFactor(currentDiameter, previousDiameter) > 1.25) {
    return true;
  }

  const panDiff = calulatePanChangeFactor(current.searchArea, previous.searchArea, currentDiameter);
  return panDiff > 0.1;
}

// change factor is usually 2 for one google maps zoom level
function calculateDiameterChangeFactor(diameter1: number, diameter2: number) {
  return diameter1 > diameter2 ? diameter1 / diameter2 : diameter2 / diameter1;
}

function calculateDiameter(searchArea: SearchArea) {
  return distanceBetweenLocations(
    { lat: searchArea.north, lng: searchArea.east },
    { lat: searchArea.south, lng: searchArea.west }
  );
}

function calulatePanChangeFactor(
  currentSearchArea: SearchArea,
  previousSearchArea: SearchArea,
  currentDiameter: number
) {
  const distance = distanceBetweenLocations(
    { lat: currentSearchArea.north, lng: currentSearchArea.east },
    { lat: previousSearchArea.north, lng: previousSearchArea.east }
  );

  return distance / currentDiameter;
}
