import { Language } from "@lialo/common/lib/language";

export interface INotificationPreferences {
  pushNotificationsEnabled: boolean;
  emailNotificationsEnabled: boolean;
  language: Language;
  fallbackEmail: string | null;
}

export function anyNotificationsEnabled(preferences: INotificationPreferences) {
  return preferences.emailNotificationsEnabled || preferences.pushNotificationsEnabled;
}
