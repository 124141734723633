import { createAction } from 'typesafe-actions';
import { Callback } from '../../utils/helper/promisify';
import { IFirebaseUser } from '../../models';
import { AsyncError } from '../../common';

const switchAccount = createAction('@app/SWITCH_ACCOUNT', action => {
  return (credential: any, done: Callback<AsyncError, void>) => action({ credential, done });
});

const upgradeAccount = createAction('@app/UPGRADE_ACCOUNT', action => {
  return (user: IFirebaseUser, done: Callback<AsyncError, void>) => action({ user, done });
});

const setAuthorities = createAction('@app/SET_AUTHORITIES', action => {
  return (authorities: string[]) => action({ authorities });
});

const appUpdate = {
  request: createAction('@app/APP_UPDATE_REQUEST'),
  cancel: createAction('@app/APP_UPDATE_CANCEL'),
  updateAvailable: createAction('@app/APP_UPDATE_AVAILABLE'),
  updateApp: createAction('@app/APP_UPDATE_UPDATE'),
};

export default {
  switchAccount,
  upgradeAccount,
  setAuthorities,
  appUpdate,
};
