export enum TourEventType {
  started = 'STARTED',
  completed = 'COMPLETED',
  cancelled = 'CANCELLED',
}

export interface ITourEvent {
  shortLink: string;
  eventType: TourEventType;
  timestamp: Date;
}
