import React from 'react';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { Divider, List } from '@mui/material';
import {
  AlternateEmailOutlined,
  EmojiPeopleOutlined,
  ExitToAppOutlined,
  FeedbackOutlined,
  InfoOutlined as InfoIcon,
  PersonAddOutlined,
  PlaylistAddOutlined,
  PlaylistPlayOutlined,
  SearchOutlined,
} from '@mui/icons-material';
import { ArticleIcon } from '../Icons';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/lialo_logo.svg';
import routes from '@lialo/core/lib/pages/utils/routes';
import { connectDrawer, IDrawerProps } from '@lialo/core/lib/pages/app/connect/connectDrawer';
import DrawerListItem from './DrawerListItem';
import ProfileLink from './ProfileLink';
import SocialMediaIconGroup from './SocialMediaIconGroup';
import InstallationListItem from './InstallationListItem';
import { useIsNotIOSApp } from '../../services/utils';
import { OpenInNewTabTitle } from './OpenInNewTabTitle';
import { useServiceProvider } from '../Providers';
import { ChangeLanguageListItem } from './ChangeLanguageListItem';

const PREFIX = 'DrawerContent';

const classes = {
  logo: `${PREFIX}-logo`,
  clear: `${PREFIX}-clear`,
  content: `${PREFIX}-content`,
  upper: `${PREFIX}-upper`,
  nonInteractive: `${PREFIX}-nonInteractive`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.logo}`]: {
    width: 100,
    float: 'right',
    padding: theme.spacing(2),
  },

  [`& .${classes.clear}`]: {
    clear: 'both',
  },

  [`&.${classes.content}`]: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },

  [`& .${classes.upper}`]: {
    justifyContent: 'normal',
  },

  [`& .${classes.nonInteractive}`]: {
    pointerEvents: 'none',
  },
}));

interface IProps {
  onItemClicked: () => void;
}

const DrawerContent = (props: IDrawerProps & IProps) => {
  const { t } = useTranslation();
  const isNotIOSApp = useIsNotIOSApp();
  const { navigationService } = useServiceProvider();

  const logout = async () => {
    props.onItemClicked();
    props.signOut();
  };

  return (
    <Root className={classes.content}>
      <NavLink
        onClick={props.onItemClicked}
        to={navigationService.generateI18nPath(routes.ROOT)}
        exact={true}
        activeClassName={classes.nonInteractive}
      >
        <Logo className={classes.logo} />
      </NavLink>
      <div className={classes.clear} />
      {isNotIOSApp && <ProfileLink onClick={props.onItemClicked} username={props.username ?? t('drawer.guest')} />}

      <Divider />

      <List>
        <DrawerListItem
          component={{ type: 'navLink', to: navigationService.generateI18nPath(routes.ROOT) }}
          icon={SearchOutlined}
          title={t('drawer.find_tours')}
          highlightExact={true}
          onClick={props.onItemClicked}
          key={'find'}
        />
        {isNotIOSApp && (
          <DrawerListItem
            key={'my_played_tours'}
            icon={PlaylistPlayOutlined}
            title={t('drawer.my_played_tours')}
            component={{ type: 'navLink', to: navigationService.generateI18nPath(routes.MY_TOURS_PLAYED) }}
            onClick={props.onItemClicked}
          />
        )}
        {isNotIOSApp && (
          <DrawerListItem
            key={'my_created_tours'}
            icon={PlaylistAddOutlined}
            title={t('drawer.my_created_tours')}
            component={{ type: 'navLink', to: navigationService.generateI18nPath(routes.MY_TOURS_CREATED) }}
            onClick={props.onItemClicked}
          />
        )}
      </List>
      <Divider />
      <List>
        <DrawerListItem
          component={{ type: 'external', href: routes.BLOG }}
          key={'blog'}
          icon={ArticleIcon}
          title={<OpenInNewTabTitle>{t('drawer.blog')}</OpenInNewTabTitle>}
          onClick={props.onItemClicked}
        />
        <DrawerListItem
          key={'about_app'}
          icon={InfoIcon}
          title={t('drawer.about_app')}
          component={{ type: 'navLink', to: navigationService.generateI18nPath(routes.ABOUT_APP) }}
          onClick={props.onItemClicked}
        />
        <DrawerListItem
          key={'feedback'}
          icon={FeedbackOutlined}
          title={t('drawer.feedback')}
          component={{ type: 'navLink', to: navigationService.generateI18nPath(routes.FEEDBACK) }}
          onClick={props.onItemClicked}
        />
        <DrawerListItem
          key={'for_guides'}
          icon={EmojiPeopleOutlined}
          title={<OpenInNewTabTitle>{t('drawer.for_guides')}</OpenInNewTabTitle>}
          component={{ type: 'external', href: routes.SELL_TOUR_BLOG_ARTICLE }}
          onClick={props.onItemClicked}
        />
        {isNotIOSApp && <InstallationListItem onItemClicked={props.onItemClicked} />}
        <DrawerListItem
          key={'contact'}
          icon={AlternateEmailOutlined}
          title={t('drawer.contact')}
          component={{ type: 'navLink', to: navigationService.generateI18nPath(routes.CONTACT) }}
          onClick={props.onItemClicked}
        />
      </List>
      <Divider />
      <ChangeLanguageListItem onClick={props.onItemClicked} />

      {isNotIOSApp && (
        <>
          <Divider />
          <List>
            {!props.isRegisteredUser && (
              <DrawerListItem
                key={'login'}
                icon={PersonAddOutlined}
                title={t('drawer.sign_in')}
                component={{ type: 'navLink', to: navigationService.generateI18nPath(routes.SIGN_IN) }}
                onClick={props.onItemClicked}
              />
            )}
            {props.isRegisteredUser && (
              <DrawerListItem
                component={{ type: 'button' }}
                key={'logout'}
                icon={ExitToAppOutlined}
                title={t('drawer.sign_out')}
                onClick={logout}
              />
            )}
          </List>
        </>
      )}
      <SocialMediaIconGroup />
    </Root>
  );
};

export default connectDrawer(DrawerContent);
