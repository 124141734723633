import { ITourRating, IUserPreview, TourRatingAuthorResponse } from "../../models";
import { formatDate } from '../../utils/formatter/dateFormatter';

export interface IRatingVM {
  user?: IUserPreview;
  name?: string;
  userComment?: string;
  rating: number;
  userCommentDate: string;
  userCommentDateIsoString: string;
  authorResponse: TourRatingAuthorResponse | null;
}

export function convertToRatingVM(review: ITourRating): IRatingVM {
  return {
    user: review.user,
    name: review.user?.username,
    userComment: review.review,
    rating: review.rating,
    userCommentDate: formatDate(review.createdAt),
    userCommentDateIsoString: review.createdAt.toISOString(),
    authorResponse: review.response,
  };
}
