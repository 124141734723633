import updateUsernameSaga from './updateUsernameSaga';
import reauthenticateSaga from '../../common/sagas/reauthenticateSaga';
import updateEmailSaga from './updateEmailSaga';
import updateImageSaga from './updateImageSaga';
import updatePasswordSaga from './updatePasswordSaga';
import deleteProfileSaga from './deleteProfileSaga';
import showReauthenticationDialogSaga from './showReauthenticationDialogSaga';
import fetchPrivateUserSaga from './fetchPrivateUserSaga';
import updateUserTranslationSaga from './updatePrivateUserSaga';

export default [
  ...reauthenticateSaga,
  ...updateUsernameSaga,
  ...updateEmailSaga,
  ...updatePasswordSaga,
  ...updateImageSaga,
  ...deleteProfileSaga,
  ...showReauthenticationDialogSaga,
  ...fetchPrivateUserSaga,
  ...updateUserTranslationSaga,
];
