import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

interface Props {}

export const LanguageRedirect = (props: Props) => {
  const { i18n } = useTranslation();

  const urlWithoutDomain = window.location.href.replace(window.location.origin, '');

  return <Redirect to={'/' + i18n.language + urlWithoutDomain} />;
};
