import { createAction, createAsyncAction } from 'typesafe-actions';
import { ITourDetail, ITourHighlight, ITourInsight, TourCode } from '../../models';
import { IAsyncError, TourRatingListResponse } from '../../common';

const fetchTourRatingsAsync = createAsyncAction(
  'FETCH_TOUR_RATINGS_REQUEST',
  'FETCH_TOUR_RATINGS_SUCCESS',
  'FETCH_TOUR_RATINGS_FAILURE'
)<TourCode, TourRatingListResponse, IAsyncError>();

const fetchTourDetailsAsync = createAsyncAction(
  'FETCH_TOUR_DETAIL_REQUEST',
  'FETCH_TOUR_DETAIL_SUCCESS',
  'FETCH_TOUR_DETAIL_FAILURE'
)<TourCode, ITourDetail, IAsyncError>();

const fetchTourInsightAsync = createAsyncAction(
  'FETCH_TOUR_INSIGHT_REQUEST',
  'FETCH_TOUR_INSIGHT_SUCCESS',
  'FETCH_TOUR_INSIGHT_FAILURE'
)<TourCode, ITourInsight, IAsyncError>();

const fetchTourHighlightsAsync = createAsyncAction(
  'FETCH_TOUR_HIGHLIGHTS_REQUEST',
  'FETCH_TOUR_HIGHLIGHTS_SUCCESS',
  'FETCH_TOUR_HIGHLIGHTS_FAILURE',
)<TourCode, ITourHighlight[], IAsyncError>();

const resetTourRating = createAction('RESET_TOUR_RATINGS');

export default {
  fetchTourRatingsAsync,
  resetTourRating,
  fetchTourDetailsAsync,
  fetchTourHighlightsAsync,
  fetchTourInsightAsync,
};
