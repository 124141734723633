import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import strings_de from '@lialo/core/lib/translations/de.json';
import strings_en from '@lialo/core/lib/translations/en.json';
import { Language } from '@lialo/common/lib/language';

export const availableLanguages = [Language.en, Language.de];

function detectPath() {
  const languages = window.location.pathname.match(/\/([a-zA-Z-]*)/g);
  if (languages && availableLanguages.map(it => `/${it}`).includes(languages[0])) {
    return languages[0].replace('/', '');
  }
}

export const languageDetector = {
  type: 'languageDetector' as const,
  async: false,
  init: () => {
    /* NOOP */
  },
  detect: () => {
    return detectPath() ?? Language.de;
  },
  cacheUserLanguage: () => {
    /* NOOP */
  },
};

const instance = i18n.createInstance();
instance
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources: {
      [Language.de]: {
        translation: strings_de,
      },
      [Language.en]: {
        translation: strings_en,
      },
    },
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

instance.on('languageChanged', lng => {
  console.log('current language', lng);
});
export default instance;
