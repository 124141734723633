import { Language } from '@lialo/common/lib/language';
import { emptySettings, ISimpleLocation, ITourSettings, IWaypoint } from "../../../models";
import { TourVisibility } from '../../../models/TourVisibility'; //error when imported through index due to circular dependency?
import { exclude } from '../../../utils/helper/object';
import { isFalsy } from '../../../utils/helper/utilities';
import { IAsyncError } from '../../../common';
import ReviewStatus from '../../../models/ReviewStatus';
import { ImageType } from './Image';
import { ICreateTourModel } from './Tour';

export interface ISingleChoiceTaskState {
  id: number;
  question?: string;
  answer?: string;
  wrongAnswer1?: string;
  wrongAnswer2?: string;
  wrongAnswer3?: string;
  hint?: string;
  images: IImageState[];
}

export interface ITourStopState {
  id: number;
  localId: number;
  orderId: number;
  task: ISingleChoiceTaskState;
  title?: string;
  waypointsEnabled: boolean;
  waypoints?: Array<IWaypoint>;
  directions?: string;
  information?: string;
  images: IImageState[];
  lookIntoTour: boolean;
}

export interface ITourState {
  id: number;
  title?: string;
  startLocation?: ISimpleLocation;
  isRoundTrip: boolean;
  endLocation?: ISimpleLocation;
  stops: Array<ITourStopState>;
  description?: string;
  startText?: string;
  images: IImageState[];
  endText?: string;
  reviewStatus: ReviewStatus;
  settings: ITourSettings;
  visibility: TourVisibility;
  language: Language;
}

export interface ICreateTourState {
  saveError?: IAsyncError;
  language: Language;
  tourState: ITourState;
  editingSnapshot?: ITourState;
  tour?: ICreateTourModel;
  isSaving: boolean;
  showValidation: boolean;
  isFetching: boolean;
  fetchError?: IAsyncError;
  showGuidanceDialog: boolean;
}

export interface IImageState {
  id: number;
  localId: number;
  orderId: number;
  url?: string;
  filename?: string;
  caption?: string;
  type: ImageType;
}

const emptyTourState: ITourState = {
  id: 0,
  language: Language.de,
  stops: [],
  settings: emptySettings,
  isRoundTrip: true,
  images: [],
  visibility: TourVisibility.PRIVATE,
  reviewStatus: ReviewStatus.NONE,
};

export const defaultState: ICreateTourState = {
  tourState: emptyTourState,
  language: Language.de,
  showValidation: false,
  isSaving: false,
  isFetching: false,
  showGuidanceDialog: false,
};

export const defaultTask: ISingleChoiceTaskState = {
  id: 0,
  images: [],
};

export function isEmptyTaskState(taskState: ISingleChoiceTaskState) {
  const contentOnly = exclude(taskState, ['id']);
  return Object.values(contentOnly).every(isFalsy);
}
