import { useServiceProvider } from '../../common/Providers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { followAuthorSelectors } from '@lialo/core/lib/pages/followAuthor';

export function useReceivePushMessages() {
  const { pushMessagingService } = useServiceProvider();
  const { t } = useTranslation();

  const followeeIds = useSelector(followAuthorSelectors.selectFolloweeIds);
  const shouldSubscribe = followeeIds.length > 0;

  React.useEffect(() => {
    let unsubscribe: Promise<VoidFunction>;

    (async () => {
      if (shouldSubscribe && (await pushMessagingService.isSupported())) {
        unsubscribe = pushMessagingService.registerMessageListener(({ data }) => {
          if (data && data.type === 'TOUR_PUBLISHED') {
            pushMessagingService.showNotification(
              t('notifications.published_tour.title', { authorName: data.authorName }),
              {
                body: t('notifications.published_tour.body', { tourTitle: data.tourTitle }),
                icon: data.authorImage,
                data,
              }
            );
          }
        });
      }
    })();

    return () => {
      unsubscribe && unsubscribe.then(fn => fn());
    };
  }, [shouldSubscribe, pushMessagingService, t]);
}
