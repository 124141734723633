import { pick } from '../../../utils/helper/object';
import { ILocation, ISimpleLocation } from '../../../models';
import { defaultTask, IImageState, ISingleChoiceTaskState, ITourState, ITourStopState } from '../models/state';
import { ICreateTourModel } from '../models/Tour';
import { IImageList } from '../models/Image';
import { ICreateTourStopModel } from '../models/TourStop';
import { ICreateTaskModel } from '../models/Task';

export function convertTourToTourState(tour: ICreateTourModel): ITourState {
  return {
    id: tour.id,
    language: tour.language,
    startLocation: convertToSimpleLocation(tour.startLocation),
    endLocation: convertToSimpleLocation(tour.endLocation),
    stops: tour.stops.map(convertTourStopToTourStopState),
    settings: tour.settings,
    images: convertImageList(tour.imageList),
    visibility: tour.visibility,
    reviewStatus: tour.reviewStatus,
    isRoundTrip: !tour.endLocation,
    title: tour.title,
    description: tour.description,
    startText: tour.startText,
    endText: tour.endText,
  };
}

export function convertToSimpleLocation(location?: ILocation): ISimpleLocation | undefined {
  return location ? pick(location, ['lat', 'lng', 'city', 'country']) : undefined;
}

function convertTourStopToTourStopState(stop: ICreateTourStopModel): ITourStopState {
  return {
    id: stop.id,
    localId: stop.localId || 0,
    orderId: stop.orderId,
    task: stop.task ? convertTaskToTaskState(stop.task) : defaultTask,
    images: convertImageList(stop.imageList),
    lookIntoTour: stop.lookIntoTour,
    title: stop.title,
    information: stop.information,
    directions: stop.directions,
    waypointsEnabled: stop.waypointsEnabled,
    waypoints: stop.waypoints,
  };
}

function convertTaskToTaskState(task: ICreateTaskModel): ISingleChoiceTaskState {
  const { imageList, ...fields } = task;

  return {
    ...fields,
    images: convertImageList(task.imageList),
  };
}

function convertImageList(imageList?: IImageList): IImageState[] {
  if (!imageList) {
    return [];
  } else {
    return imageList.images.map(image => {
      return {
        ...image,
      };
    });
  }
}
