import { commonActions, commonSelectors } from '../../common';
import { put, race, select, take } from 'redux-saga/effects';
import { IFirebaseUser } from '../../../models';
import { IAsyncError } from '../../../common';

export type RequireUserResult = { user: IFirebaseUser; error: null } | { user: null; error: IAsyncError };

/**
 * Check if user is already registered, otherwise sign up anonymously
 * @return Tupel [IFirebaseUser | null, IAsyncError | null]
 */
export function* requireUser() {
  const user = commonSelectors.selectUser(yield select());
  if (user) {
    return { user, error: null };
  }

  yield put(commonActions.signInAnonymously());
  const { success, failure } = yield race({
    success: take(commonActions.signIn.success),
    failure: take(commonActions.signIn.failure),
  });

  if (success) {
    return { user: success.payload, error: null } as RequireUserResult;
  } else {
    return { user: null, error: failure.payload } as RequireUserResult;
  }
}

export function* requireUserOrThrow() {
  const { user, error } = yield requireUser();
  if (error) throw error;
  return user;
}
