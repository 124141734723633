import { RootState } from '../../store/root';
import { parsePlayTourUrl, PlayTourParams } from './utils/routerUtils';
import { createSelector } from 'reselect';
import { comparePlayProgress, IMapStopLocations, IScore } from './models';
import {
  calculateTourStopProgress,
  countAnswerOptions,
  getAudioFile,
  getDirectionImages,
  getEndImages,
  getInformationImages,
  getSortedStops,
  getStartImages,
  getTaskImages,
  IPlaybookPage,
  IQuizResponse,
  IStaticMapSize,
  ITask,
  ITour,
  ITourHighlight,
  ITourProgress,
  ITourStop,
  selectImageUrlsFromTour,
  TourCode,
  TourProgressStatus,
} from '../../models';
import { chooseTourImageLink, cropAvatarImage } from '../../utils/helper/image-link';
import { commonSelectors } from '../common';
import { calculateAnswerPrefix, IQuizResponseVM } from '../../common';
import { staticMapUrl } from '../../utils/helper/static-map';
import { staticHighlightMap } from './utils/staticHighlightMap';

const FALLBACK_IMAGE_WIDTH = 600;

const selectIsLoading = (state: RootState) => state.playTour.isLoading;

const selectError = (state: RootState) => state.playTour.error;

const selectSendReportError = (state: RootState) => state.playTour.sendReportError;

const selectPlayTourParams = (state: RootState, params: PlayTourParams) => {
  return parsePlayTourUrl(params);
};

const selectTourCode = createSelector(selectPlayTourParams, params => params.tourCode);

const selectTourProgresses = (state: RootState): Record<TourCode, ITourProgress> => state.playTour.tourProgresses;

const selectTourProgressByTourCode = createSelector(
  [selectTourProgresses, selectTourCode],
  (tourProgresses, tourCode) => tourProgresses[tourCode]
);

const selectUnsynchronizedTourProgresses = createSelector(selectTourProgresses, (tourProgresses): ITourProgress[] => {
  return Object.values(tourProgresses).filter(tourProgress => tourProgress.isSyncPending);
});

const selectIsSynchronizingTourProgresses = (state: RootState) => state.playTour.isSynchronizingTourProgresses;

const selectPlaybookPage = createSelector(selectPlayTourParams, (params): IPlaybookPage => params.playbookPage);

const selectTour = createSelector([commonSelectors.selectTours, selectTourCode], (tours, tourCode):
  | ITour
  | undefined => {
  return tours[tourCode];
});

const selectTourProgress = createSelector([selectTourCode, selectTourProgresses], (tourCode: string, tourProgresses):
  | ITourProgress
  | undefined => {
  return tourProgresses[tourCode];
});

const selectRunningTourProgress = createSelector(selectTourProgress, (tp: ITourProgress | undefined) => {
  return tp?.status === TourProgressStatus.running ? tp : undefined;
});

const selectCompletedTourShortLinks = createSelector(selectTourProgresses, progresses => {
  return Object.entries(progresses)
    .map(([tourCode, progress]) => {
      if (progress.status === TourProgressStatus.completed) {
        return progress.shortLink;
      }
      return undefined;
    })
    .filter(it => it !== undefined) as string[];
});

const selectCanRateTour = createSelector(
  selectTour,
  commonSelectors.selectUser,
  selectTourProgresses,
  (tour, user, tourProgresses) => {
    if (tour && user) {
      if (tour.user.id === user.id) return false;

      const tp = tourProgresses[tour.shortLink!];
      if (!tp || tp.didRateTour) return false;

      // Temporarily disable duration constraint
      return true;
      // return isMinPlayDurationFulfilled(tp, tour.settings?.duration ?? undefined);
    }
    return false;
  }
);

const selectImageWidth = createSelector(selectTourProgress, (tour): number => {
  return tour?.playbook?.imageWidth ?? FALLBACK_IMAGE_WIDTH;
});

const selectTourAndTourProgress = createSelector([selectTour, selectRunningTourProgress], (tour, tourProgress): [
  ITour | undefined,
  ITourProgress | undefined
] => [tour, tourProgress]);

const selectTourStop = createSelector([selectTour, selectPlaybookPage], (tour, page) => {
  if (tour && page.stopIndex !== undefined) {
    return getSortedStops(tour)[page.stopIndex];
  }
});

const selectQuizResponse = createSelector([selectRunningTourProgress, selectPlaybookPage], (runningTour, page) => {
  if (runningTour?.playbook && page.stopIndex !== undefined) {
    return runningTour.playbook.quizResponses[page.stopIndex];
  }
});

const selectShuffledAnswers = createSelector(
  [selectTourStop, selectRunningTourProgress, selectQuizResponse],
  (tourStop?: ITourStop, runningTour?: ITourProgress, quizResponse?: IQuizResponse) => {
    const task = tourStop?.task;
    if (runningTour && tourStop && task) {
      const keys: Array<keyof ITask> = ['answer', 'wrongAnswer1', 'wrongAnswer2', 'wrongAnswer3'];
      const answers: IQuizResponseVM[] = keys
        .filter(key => task[key])
        .shuffle(task.question?.length ?? tourStop.orderId)
        .map((key, idx, answers) => ({
          key: key,
          prefix: calculateAnswerPrefix(idx, answers.length),
          value: task[key]?.toString() || '',
          selected: key === quizResponse?.answer,
        }));
      return answers;
    }

    return [];
  }
);

const selectTourStopProgress = createSelector([selectTour, selectPlaybookPage], (tour, page) => {
  if (tour) {
    return calculateTourStopProgress(tour.stops.length, page);
  }
});

const selectIsOnCompletedPage = createSelector([selectPlaybookPage, selectRunningTourProgress], (page, runningTour) => {
  if (page && runningTour?.playbook) {
    return comparePlayProgress(page, runningTour.playbook.currentPage) < 0;
  }
  return false;
});
const selectIsOnFinalStopPage = createSelector([selectPlaybookPage, selectTour], (page, tour) => {
  if (page && tour) {
    return page.stopIndex === tour.stops.length - 1;
  }
  return false;
});

const selectIsOnFuturePage = createSelector([selectPlaybookPage, selectRunningTourProgress], (page, runningTour) => {
  if (page && runningTour?.playbook) {
    return comparePlayProgress(page, runningTour.playbook.currentPage) > 0;
  }
  return false;
});

const selectIsValidResponse = createSelector([selectTourStop, selectQuizResponse], (stop, quizResponse) => {
  if (quizResponse === undefined) {
    return false;
  }
  return quizResponse.answer === 'answer';
});

const selectCurrentStopCount = createSelector(selectPlaybookPage, page => (page?.stopIndex ?? 0) + 1);

const selectShowNextButton = createSelector(selectQuizResponse, quizResponse => {
  return quizResponse && quizResponse.answer !== undefined;
});

// including user image
const selectAllTourImages = createSelector([selectTour, selectImageWidth], (tour, imageWidth) => {
  const tourImages = tour ? selectImageUrlsFromTour(tour).map(url => chooseTourImageLink(url, imageWidth)) : [];
  return tour?.user?.imageUrl ? [...tourImages, cropAvatarImage(tour.user.imageUrl, 96)] : tourImages;
});

const selectAllAudioUrls = createSelector(selectTour, (tour: ITour | undefined) => {
  return tour?.audioFiles?.map(it => it.url) ?? [];
});

const selectStaticMapSize = createSelector(
  [selectTourProgress],
  (tourProgress): IStaticMapSize => {
    return (
      tourProgress?.playbook?.staticMapSize ?? {
        width: 512,
        devicePixelRatio: 2,
      }
    );
  }
);

const selectStartImages = createSelector(selectTour, tour => {
  return tour ? getStartImages(tour) : undefined;
});

const selectDirectionImages = createSelector(selectTourStop, stop => {
  return stop ? getDirectionImages(stop) : undefined;
});

const selectInformationImages = createSelector(selectTourStop, stop => {
  return stop ? getInformationImages(stop) : undefined;
});

const selectTaskImages = createSelector(selectTourStop, stop => {
  return stop ? getTaskImages(stop) : undefined;
});

const selectEndImages = createSelector(selectTour, tour => {
  return tour ? getEndImages(tour) : undefined;
});

const selectIntroductionAudio = createSelector(selectTour, tour => {
  return tour ? getAudioFile(tour.audioFiles ?? [], 'INTRODUCTION') : undefined;
});

const selectOnboardingAudio = createSelector(selectTour, tour => {
  return tour ? tour.audioFiles?.find(it => it.type === 'TourOnboardingAudioFile')?.url : undefined;
});

const selectDirectionsAudio = createSelector([selectTour, selectTourStop], (tour, stop) => {
  return tour && stop ? getAudioFile(tour.audioFiles ?? [], 'DIRECTIONS', stop.id) : undefined;
});

const selectTaskAudio = createSelector([selectTour, selectTourStop], (tour, stop) => {
  return tour && stop ? getAudioFile(tour.audioFiles ?? [], 'TASK', stop.id) : undefined;
});

const selectInformationAudio = createSelector([selectTour, selectTourStop], (tour, stop) => {
  return tour && stop ? getAudioFile(tour.audioFiles ?? [], 'INFORMATION', stop.id) : undefined;
});

const selectHighlights = createSelector([selectTour, selectImageWidth], (tour, imageWidth): ITourHighlight[] => {
  if (!tour) return [];
  const sortedStops = getSortedStops(tour);

  return sortedStops.mapNotOptional((stop, idx): ITourHighlight | undefined => {
    if (!stop.waypoints) return;
    const imageSrc = getInformationImages(stop)[0]?.url;

    return {
      id: stop.id,
      stopNumber: idx + 1,
      location: stop.waypoints[0],
      imageSrc: imageSrc ? chooseTourImageLink(imageSrc, imageWidth) : undefined,
      title: stop.title ?? '',
    };
  });
});

const selectStaticHighlightMapUrl = createSelector(
  [selectHighlights, selectStaticMapSize],
  (highlights, staticMapSize) => {
    return highlights.length > 0 ? staticHighlightMap(highlights, staticMapSize) : undefined;
  }
);

const selectMapStopLocations = createSelector([selectTour, selectPlaybookPage], (tour, page) => {
  if (tour && page.stopIndex !== undefined) {
    const sortedStops = getSortedStops(tour);

    return sortedStops.reduce(
      (acc, stop, idx) => {
        if (stop.waypoints && stop.waypoints.length > 0) {
          if (idx < page.stopIndex) {
            acc.previous.push({ ...stop.waypoints[0], stopNumber: idx + 1 });
          } else if (idx === page.stopIndex) {
            const [target, ...waypoints] = stop.waypoints;
            acc.current = [{ ...target, stopNumber: idx + 1 }, ...waypoints];
          } else {
            acc.next.push({ ...stop.waypoints[0], stopNumber: idx + 1 });
          }
        }

        return acc;
      },
      ({ previous: [], current: [], next: [] } as unknown) as IMapStopLocations
    );
  }
});

const selectStaticDirectionsMapUrl = createSelector([selectTourStop, selectStaticMapSize], (tourStop, staticMapSize):
  | string
  | undefined => {
  const waypoints = tourStop?.waypoints;
  if (!waypoints) return undefined;

  return staticMapUrl(waypoints, staticMapSize);
});

const selectStaticMapUrls = createSelector(
  [selectTour, selectStaticMapSize, selectStaticHighlightMapUrl],
  (tour, staticMapSize, staticHighlightMapUrl) => {
    if (!tour) return [];

    const directionMapUrls = tour.stops.mapNotOptional(stop => {
      if (!stop.waypoints) return undefined;
      return staticMapUrl(stop.waypoints, staticMapSize);
    });

    if (staticHighlightMapUrl) {
      directionMapUrls.push(staticHighlightMapUrl);
    }

    return directionMapUrls;
  }
);

const selectTourEvents = (state: RootState) => state.playTour.tourEvents;

const selectIsSendingProblemReport = (state: RootState) => state.playTour.isSendingProblemReport;

const selectCurrentlyPlayedTour = (state: RootState): TourCode | undefined => state.playTour.currentlyPlayedTour;

const selectOnboardingCompleted = (state: RootState): boolean => state.playTour.onboardingCompleted;

const selectTotalScore = createSelector([selectTour, selectTourProgress], (tour, tourProgress): IScore | undefined => {
  if (tourProgress?.score !== undefined) {
    const maxScore = (tour?.stops ?? []).reduce((acc, stop) => {
      return acc + (stop.task ? countAnswerOptions(stop.task) * 10 : 0);
    }, 0);

    return {
      achieved: tourProgress.score,
      max: maxScore,
    };
  }

  return undefined;
});

const selectScoreOfCurrentTask = createSelector([selectQuizResponse, selectTourStop], (quizResponse, tourStop) => {
  if (quizResponse && tourStop?.task) {
    const numberOfAnswers = countAnswerOptions(tourStop.task);
    return Math.max((numberOfAnswers - quizResponse.attempts + 1) * 10, 0);
  }
  return 0;
});

const selectHasHeartOnboardingAudio = (state: RootState): boolean => state.playTour.hasHeartOnboardingAudio ?? false;

export default {
  selectIsLoading,
  selectError,
  selectTourProgressByTourCode,
  selectUnsynchronizedTourProgresses,
  selectPlayTourParams,
  selectTourCode,
  selectTour,
  selectPlaybookPage,
  selectTourProgress,
  selectRunningTourProgress,
  selectCompletedTourShortLinks,
  selectTourAndTourProgress,
  selectTourStop,
  selectCanRateTour,
  selectShuffledAnswers,
  selectTourStopProgress,
  selectIsValidResponse,
  selectIsOnCompletedPage,
  selectIsOnFinalStopPage,
  selectIsOnFuturePage,
  selectCurrentStopCount,
  selectShowNextButton,
  selectAllTourImages,
  selectStartImages,
  selectDirectionImages,
  selectInformationImages,
  selectTaskImages,
  selectEndImages,
  selectImageWidth,
  selectIsSynchronizingTourProgresses,
  selectTourEvents,
  selectIsSendingProblemReport,
  selectSendReportError,
  selectIntroductionAudio,
  selectHighlights,
  selectDirectionsAudio,
  selectInformationAudio,
  selectTaskAudio,
  selectOnboardingAudio,
  selectAllAudioUrls,
  selectCurrentlyPlayedTour,
  selectMapStopLocations,
  selectStaticMapSize,
  selectStaticHighlightMapUrl,
  selectStaticDirectionsMapUrl,
  selectStaticMapUrls,
  selectOnboardingCompleted,
  selectTotalScore,
  selectScoreOfCurrentTask,
  selectHasHeartOnboardingAudio,
};
