import { put } from 'redux-saga/effects';
import { commonActions } from '../index';
import { IAsyncError, TranslationTO } from '../../../common';
import { errorTranslationKey } from '../../../translations/translationHelper';

export function* showDialog(
  content: TranslationTO,
  titleKey?: string,
  positiveKey?: string,
  negativeKey?: false | string
) {
  yield put(commonActions.showDialog({ content, titleKey, positiveKey, negativeKey }));
}

export function* showErrorDialog(error: IAsyncError) {
  yield showDialog(
    {
      key: errorTranslationKey(error),
    },
    undefined,
    'common.ok',
    false
  );
}
