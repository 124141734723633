import actions from './actions';
import { getType } from 'typesafe-actions';
import { Language } from "@lialo/common/lib/language";
import { getServiceProvider, IServiceProvider } from '../../services';
import { put, takeEvery } from 'redux-saga/effects';
import { emptyTourFilter } from '../../common';
import { LandingPageTypeToFilter } from './models';
import { ITourSummary } from '../../models';

function* fetchTours(action: ReturnType<typeof actions.loadTours.request>) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    const tours: ITourSummary[] = yield serviceProvider.api.filterTours(Language.de, {
      ...emptyTourFilter,
      ...LandingPageTypeToFilter.get(action.payload),
    });
    yield put(actions.loadTours.success({ query: action.payload, tours: tours.sortBy('createdAt', 'DESC') }));
  } catch (e) {
    serviceProvider.analyticsService.logError(e);
    yield put(actions.loadTours.failure(e));
  }
}

const requestSaga = takeEvery(getType(actions.loadTours.request), fetchTours);
export default [requestSaga];
