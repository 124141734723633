import { Dispatch, SetStateAction } from 'react';

export type BooleanPropertyKeys<T> = { [P in keyof T]: T[P] extends boolean ? P : never }[keyof T];

export type NonBooleanPropertyKeys<T> = { [P in keyof T]: T[P] extends boolean ? never : P }[keyof T];

export type PartialBoolean<T> = Partial<Pick<T, Extract<keyof T, BooleanPropertyKeys<T>>>> &
  Pick<T, Exclude<keyof T, BooleanPropertyKeys<T>>>;

export function stringLiterals<T extends string>(...args: T[]): T[] {
  return args;
}

export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType>
  ? ElementType
  : never;

export type UseState<S> = [S | undefined, Dispatch<SetStateAction<S | undefined>>];
