import * as actions from '../actions';
import { put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import { AsyncError } from '../../../common';
import { commonActions } from '../../common';
import { setupNotifications } from '../../notifications';

const followAuthorSaga = takeLatest(getType(actions.followAuthor.request), function* (
  action: ReturnType<typeof actions.followAuthor.request>
) {
  const { id, name } = action.payload;
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    const setup = yield setupNotifications();
    if (!setup) {
      yield put(actions.followAuthor.cancel());
      return;
    }

    yield serviceProvider.api.followAuthor(id);
    yield put(actions.followAuthor.success(id));
    serviceProvider.analyticsService.logFollow(id);
    yield put(
      commonActions.showSnackbar({
        key: 'common.push_messaging.snackbar.on_follow',
        params: {
          authorName: name,
        },
      })
    );
  } catch (e) {
    yield put(actions.followAuthor.failure(e as AsyncError));
  }
});

const unfollowAuthorSaga = takeLatest(getType(actions.unfollowAuthor.request), function* (
  action: ReturnType<typeof actions.unfollowAuthor.request>
) {
  const { id, name } = action.payload;
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    yield serviceProvider.api.unfollowAuthor(id);
    yield put(actions.unfollowAuthor.success(id));
    serviceProvider.analyticsService.logUnfollow(id);
    yield put(
      commonActions.showSnackbar({
        key: 'common.push_messaging.snackbar.on_unfollow',
        params: {
          authorName: name,
        },
      })
    );
  } catch (e) {
    yield put(actions.unfollowAuthor.failure(e as AsyncError));
  }
});

const sagas = [followAuthorSaga, unfollowAuthorSaga];
export default sagas;
