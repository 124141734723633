import { Language } from '@lialo/common/lib/language';
import {
  emptySettings,
  IAverageRating,
  IFirebaseUser,
  ILocation,
  ITour,
  ITourSettings,
  TourVisibility,
} from '../../../models';
import ReviewStatus from '../../../models/ReviewStatus';
import { convertToImages, IImageList, ImageType } from './Image';
import { validateTourState } from '../utils/validation';
import { convertTourToTourState } from '../utils/convert';
import { convertToTourStop, ICreateTourStopModel } from './TourStop';

export interface ICreateTourModel {
  id: number;
  shortLink?: string;
  user: IFirebaseUser;
  startLocation?: ILocation;
  endLocation?: ILocation;
  stops: ICreateTourStopModel[];
  visibility: TourVisibility;
  reviewStatus: ReviewStatus;
  valid: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  imageList?: IImageList;
  settings: ITourSettings;
  title?: string;
  language: Language;
  description?: string;
  startText?: string;
  endText?: string;
  lockChanges: boolean;
}

export function createEmptyTour(user: IFirebaseUser): ICreateTourModel {
  return {
    id: 0,
    user: user,
    stops: [],
    reviewStatus: ReviewStatus.NONE,
    visibility: TourVisibility.PRIVATE,
    settings: emptySettings,
    valid: false,
    language: Language.de,
    lockChanges: false,
  };
}

export function isTourValid(tour: ICreateTourModel): boolean {
  return validateTourState(convertTourToTourState(tour)).isTourValid;
}

export function convertToTour(createTourModel: ICreateTourModel): ITour {
  return {
    id: createTourModel.id,
    shortLink: createTourModel.shortLink!!,
    user: createTourModel.user,
    valid: createTourModel.valid,
    mainImages: convertToImages(createTourModel.imageList, ImageType.MAIN),
    startImages: convertToImages(createTourModel.imageList, ImageType.START),
    endImages: convertToImages(createTourModel.imageList, ImageType.END),
    visibility: createTourModel.visibility,
    createdAt: createTourModel.createdAt,
    startLocation: createTourModel.startLocation,
    endLocation: createTourModel.endLocation,
    title: createTourModel.title,
    description: createTourModel.description,
    startText: createTourModel.startText,
    endText: createTourModel.endText,
    language: createTourModel.language,
    settings: createTourModel.settings,
    rating: { ratingCount: 0 }, // dummy value
    reviewStatus: createTourModel.reviewStatus,
    updatedAt: createTourModel.updatedAt,
    stops: createTourModel.stops.map(convertToTourStop),
    enableDonation: false, // dummy value
  };
}
