import { Language } from "@lialo/common/lib/language";
import { put, select, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { getType } from 'typesafe-actions';
import { getServiceProvider, IServiceProvider } from '../../services';
import { commonSelectors } from '../common';

const fetchPublicProfileSaga = takeLatest(getType(actions.fetchPublicProfileAsync.request), function* fetchData(
  action: ReturnType<typeof actions.fetchPublicProfileAsync.request>,
) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    const language: Language = yield select(commonSelectors.selectLanguage);
    const profile = yield serviceProvider.api.fetchPublicProfile(action.payload, language);
    yield put(actions.fetchPublicProfileAsync.success(profile));
  } catch (e) {
    yield put(actions.fetchPublicProfileAsync.failure(e));
    serviceProvider.analyticsService.logError(e);
  }
});

export default [fetchPublicProfileSaga];
