import { ITourSummary } from '../../models';
import { ITourFilter } from '../../common';

export enum LandingPageType {
  STAY_AT_HOME = 'STAY_AT_HOME',
}

export const LandingPageTypeToFilter = new Map<LandingPageType, Partial<ITourFilter>>([
  [LandingPageType.STAY_AT_HOME, { playAtHome: true }],
]);

export interface LoadSuccess {
  query: LandingPageType;
  tours: ITourSummary[];
}
