import { styled } from '@mui/material/styles';
import React from 'react';
import { Drawer, Hidden, SwipeableDrawer } from '@mui/material';
import DrawerContent from './DrawerContent';
import { useNavigationDrawerState } from '../Providers';

const PREFIX = 'NavigationDrawer';

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
};

const Root = styled('nav')(({ theme }) => ({
  [`&.${classes.drawer}`]: {
    [theme.breakpoints.up('md')]: {
      width: 300,
      flexShrink: 0,
    },
    boxShadow: '1px 0 5px rgba(0,0,0,0.4)',
    zIndex: 1300,
  },

  [`& .${classes.drawerPaper}`]: {
    width: '80%',
    maxWidth: 300,
    left: 'initial',
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
  },
}));

interface IProps {
}

type IAllProps = IProps;

const NavigationDrawer = (props: IAllProps) => {
  const { isOpen, toggleState } = useNavigationDrawerState();

  const drawer = <DrawerContent onItemClicked={toggleState} />;

  return (
    <Root className={classes.drawer}>
      <Hidden mdUp implementation="js">
        <SwipeableDrawer
          disableDiscovery={true}
          variant="temporary"
          anchor={'left'}
          disableSwipeToOpen={true}
          open={isOpen}
          onOpen={toggleState}
          onClose={toggleState}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open={true}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </Root>
  );
};

export default NavigationDrawer;
