import { IAppUpdateService } from '@lialo/core/lib/services';
import * as serviceWorker from '../serviceWorkerRegistration';
import config from '../config';

export default class AppUpdateService implements IAppUpdateService {
  init(onUpdate: () => void) {
    serviceWorker.register({
      onUpdate: registration => {
        onUpdate();
      },
      onSuccess: registration => {
        onUpdate();
      },
    });
  }

  async checkForUpdates(): Promise<void> {
    const registration = await serviceWorker.getRegistration();
    this.log('manually check for update', !!registration);
    try {
      await registration?.update();
      this.log('did update manually');
    } catch (e) {
      this.log('error during manual update', e);
    }
  }

  update() {
    window.location.reload();
  }

  private log(message: any, ...params: any[]) {
    if (config.projectId !== 'lialo-com') {
      console.log(message, params);
    }
  }
}
