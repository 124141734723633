import { IConnectionService } from '@lialo/core/lib/services/';

export default class ConnectionService implements IConnectionService {
  get online(): boolean {
    return window.navigator.onLine;
  }

  get networkType(): string | undefined {
    return (window.navigator as any).connection?.effectiveType;
  }

  subscribe(onlineHandler: () => void, offlineHandler: () => void) {
    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);
  }

  unsubscribe(onlineHandler: () => void, offlineHandler: () => void): void {
    window.removeEventListener('online', onlineHandler);
    window.removeEventListener('offline', offlineHandler);
  }
}
