import React from 'react';
import { useSelector } from 'react-redux';
import { commonSelectors } from '@lialo/core/lib/pages/common';
import { useServiceProvider } from '../../common/Providers';

export function useUpdateAnalyticsConfig() {
  const { analyticsService } = useServiceProvider();
  const isUserRegistered = useSelector(commonSelectors.selectIsRegisteredUser);
  const canPlayForFree = useSelector(commonSelectors.selectCanPlayForFree);

  React.useEffect(() => {
    analyticsService.setUserSignedIn(isUserRegistered);
  }, [analyticsService, isUserRegistered]);

  React.useEffect(() => {
    analyticsService.setLoggingEnabled(!canPlayForFree);
  }, [analyticsService, canPlayForFree]);
}
