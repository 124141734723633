import { createAsyncAction } from 'typesafe-actions';
import { IAsyncError } from '../../common';

interface Author {
  id: string;
  name: string;
}

export const followAuthor = createAsyncAction(
  '@followAuthor/FOLLOW_AUTHOR_REQUEST',
  '@followAuthor/FOLLOW_AUTHOR_SUCCESS',
  '@followAuthor/FOLLOW_AUTHOR_FAILURE',
  '@followAuthor/FOLLOW_AUTHOR_CANCEL'
)<Author, string, IAsyncError, void>();

export const unfollowAuthor = createAsyncAction(
  '@followAuthor/UNFOLLOW_AUTHOR_REQUEST',
  '@followAuthor/UNFOLLOW_AUTHOR_SUCCESS',
  '@followAuthor/UNFOLLOW_AUTHOR_FAILURE'
)<Author, string, IAsyncError>();

export const fetchFolloweeIds = createAsyncAction(
  '@followAuthor/FETCH_FOLLOWEE_IDS_REQUEST',
  '@followAuthor/FETCH_FOLLOWEE_IDS_SUCCESS',
  '@followAuthor/FETCH_FOLLOWEE_IDS_FAILURE',
  '@followAuthor/FETCH_FOLLOWEE_IDS_CANCEL'
)<{ force: boolean }, string[], IAsyncError, void>();
