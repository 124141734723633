import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { IAsyncActionState, reduceCancelAction, reduceFailureAction, reduceRequestAction } from '../utils';
import { RootAction } from '../../store/root';
import { commonActions } from '../common';

export type ITourContentState = IAsyncActionState;

const defaultState: ITourContentState = {
  isLoading: false,
};

const tourContentReducer = createReducer<ITourContentState, RootAction>(defaultState)
  .handleAction(commonActions.fetchTourAsync.request, reduceRequestAction)
  .handleAction(commonActions.fetchTourAsync.failure, reduceFailureAction)
  .handleAction(commonActions.fetchTourAsync.cancel, reduceCancelAction)
  .handleAction(commonActions.fetchTourAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
    })
  )
  .handleAction(commonActions.signOut.success, (state, action) => ({
    ...defaultState,
  }));

export default tourContentReducer;
