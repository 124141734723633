import React, { useContext } from 'react';

type State = {
  prompt: any | null;
};

const StateContext = React.createContext<State>({
  prompt: null,
});

export const InstallPromptProvider = (props: { children: React.ReactNode; onInstall?: () => void }) => {
  const { children, onInstall } = props;
  const [deferredPrompt, setDeferredPrompt] = React.useState<any>(null);

  React.useEffect(() => {
    const listener = (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', listener);
    return () => window.removeEventListener('beforeinstallprompt', listener);
  }, []);

  React.useEffect(() => {
    const listener = () => {
      setDeferredPrompt(null);
      onInstall && onInstall();
    };
    window.addEventListener('appinstalled', listener);
    return () => window.removeEventListener('appinstalled', listener);
  }, [onInstall]);

  return <StateContext.Provider value={{ prompt: deferredPrompt }}>{children}</StateContext.Provider>;
};

export const useInstallationPrompt = () => useContext(StateContext);
