import { RootState } from '../../store/root';

const selectIsLoading = (state: RootState) => state.app.isLoading;
const selectError = (state: RootState) => state.app.error;
const selectShowUpdateDialog = (state: RootState) => state.app.showUpdateDialog;
const selectIsAppUpdateAvailable = (state: RootState) => state.app.isAppUpdateAvailable;

export default {
  selectIsLoading,
  selectError,
  selectShowUpdateDialog,
  selectIsAppUpdateAvailable,
};
