import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Language } from '@lialo/common/lib/language';

export function useChangeLanguageUrl() {
  const { i18n } = useTranslation();
  const { pathname, search, hash } = useLocation();

  const lang = i18n.language === Language.de ? Language.en : Language.de;
  return {
    url: `/${lang}/${pathname.replace(/^\/(de|en)\/?/, '')}${search}${hash}`,
    language: lang,
  } as const;
}
