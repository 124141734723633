import React from 'react';
import { useSelector } from 'react-redux';
import { commonSelectors } from '@lialo/core/lib/pages/common';
import { BroadcastChannel } from 'broadcast-channel';
import usePrevious from '@lialo/core/lib/utils/hooks/usePrevious';

const USER_ID_CHANGED = 'USER_ID_CHANGED';

export function useSyncBrowserTabs() {
  const broadcastChannel = React.useMemo(() => {
    return new BroadcastChannel('lialo');
  }, []);

  const user = useSelector(commonSelectors.selectUser) ?? null;
  const previousUser = usePrevious(user);
  React.useEffect(() => {
    if (previousUser === undefined) {
      // We don't want to send broadcast right after page load
      console.log('Skip first user change');
      return;
    }

    console.log('Send broadcast with userId=', user?.id, 'currentUser=', user?.id);
    broadcastChannel.postMessage({
      type: USER_ID_CHANGED,
      payload: user?.id,
    });
    // previousUser is excluded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastChannel, user]);

  broadcastChannel.onmessage = (data: any) => {
    console.log('Received broadcast with userId=', data.payload, 'currentUser=', user?.id);
    if (data.type === USER_ID_CHANGED && user?.id !== data.payload) {
      window.location.reload();
    }
  };
}
