import { createAction } from 'typesafe-actions';
import { LocationDescriptorObject, LocationState, Path } from 'history';

const goBackOrReplace = createAction('@navigation/GO_BACK_OR_REPLACE', action => {
  return (replaceUrl: LocationDescriptorObject<LocationState> | Path, forceReplace?: boolean) =>
    action({ replaceUrl, forceReplace });
});

export default {
  goBackOrReplace,
};
