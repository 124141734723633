const DATE_FORMAT = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

export function dateReviver(name: any, value: any) {
  if (typeof value === 'string' && DATE_FORMAT.test(value)) {
    return new Date(value);
  }
  return value;
}

export function justNow(date: Date, deltaMs = 500): boolean {
  return Math.abs(Date.now() - date.getTime()) < deltaMs;
}

export function minutesBetween(date1: Date, date2: Date): number {
  const diff = date2.getTime() - date1.getTime();
  return Math.abs(diff / 1000 / 60);
}

export function addYears(date: Date, years: number) {
  date.setFullYear(date.getFullYear() + years);
  return date;
}
