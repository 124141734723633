import { RootState } from '../../../store/root';
import { connect } from 'react-redux';
import { commonActions, commonSelectors } from '../index';

const mapStateToProps = (state: RootState) => ({
  open: commonSelectors.selectIsReauthenticationRequested(state),
  error: commonSelectors.selectReauthenticationError(state),
  isLoading: commonSelectors.selectIsReauthenticating(state),
  isSendingPasswordEmail: commonSelectors.selectIsSendingPasswordEmail(state),
  hasSentPasswordEmail: commonSelectors.selectHasSentPasswordEmail(state),
  passwordEmailError: commonSelectors.selectPasswordEmailError(state),
});

const dispatchProps = {
  continue: commonActions.reauthenticate.request,
  cancel: commonActions.reauthenticate.cancel,
  sendResetPasswordEmail: commonActions.resetPassword.request,
};

export type IDialogProps = ReturnType<typeof mapStateToProps> & typeof dispatchProps;
export const connectDialog = connect(mapStateToProps, dispatchProps);
