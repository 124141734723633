export function formatDate(date: Date) {
  return date.toLocaleDateString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}

export function formatMonthAndYear(date: Date): string {
  return date.toLocaleDateString(undefined, {
    month: '2-digit',
    year: 'numeric',
  });
}
