import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import actions from '../actions';
import { getServiceProvider, IServiceProvider } from '../../../services';
import selectors from '../selectors';
import { AsyncError } from '../../../common';
import routes from '../../utils/routes';
import { ICreateTourModel } from '../models/Tour';
import { commonActions } from '../../common';
import { takeConfirmation } from '../../utils/sagas';
import { showErrorSnackbar } from '../../common/utils/showSnackbar';

const cloneTourSaga = takeLatest(getType(actions.cloneTour.request), function* () {
  const tourCode: string = yield select(selectors.selectTourCode);
  const serviceProvider: IServiceProvider = yield getServiceProvider();
  const api = serviceProvider.api;

  const confirmed: boolean = yield takeConfirmation({
    content: {
      key: 'create_tour.clone_tour.confirmation_description',
    },
    positiveKey: 'actions.duplicate',
  });

  if (!confirmed) {
    yield put(actions.cloneTour.cancel());
    return;
  } else {
    yield put(actions.cloneTour.start());
  }

  try {
    const tour: ICreateTourModel = yield call(api.cloneTour.bind(api), tourCode);
    yield put(actions.cloneTour.success(tour));
    serviceProvider.navigationService.navigate(serviceProvider.navigationService.generateEditTourUrl(routes.CREATE_TOUR, tour.shortLink));
    yield put(
      commonActions.showSnackbar({
        key: 'create_tour.clone_tour.success_snackbar',
        params: { title: tour.title },
      })
    );
  } catch (e) {
    yield showErrorSnackbar(e);
    yield put(actions.cloneTour.failure(e as AsyncError));
  }
});

export default [cloneTourSaga];
