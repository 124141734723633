import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { ErrorOutlined } from '@mui/icons-material';

export const TextFieldWithErrorIcon = (props: TextFieldProps) => {
  const { InputProps, ...other } = props;

  return (
    <TextField
      InputProps={{
        endAdornment: props.error && (
          <InputAdornment position="end">
            <ErrorOutlined color={'error'} />
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...other}
    />
  );
};
