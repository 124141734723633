import { getServiceProvider, IServiceProvider } from '../../../services';
import actions from '../actions';
import { getType } from 'typesafe-actions';
import { put, takeLatest } from 'redux-saga/effects';

function* fetchData(action: ReturnType<typeof actions.fetchTourHighlightsAsync.request>) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    const highlights = yield serviceProvider.api.fetchTourHighlights(action.payload);
    yield put(actions.fetchTourHighlightsAsync.success(highlights));
  } catch (e) {
    yield put(actions.fetchTourHighlightsAsync.failure(e));
  }
}

const fetchTourHighlights = takeLatest(getType(actions.fetchTourHighlightsAsync.request), fetchData);

export default [fetchTourHighlights];
