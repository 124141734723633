export function isFalsy(val: any): boolean {
  return (
    val === '' ||
    val === undefined ||
    val === null ||
    (Array.isArray(val) && val.length === 0) ||
    (Object.entries(val).length === 0 && val.constructor === Object)
  );
}

interface CallBack<Params extends any[]> {
  (...args: Params): void;
}

export const callAll = <Params extends any[]>(...fns: Array<CallBack<Params> | undefined>) => (...args: Params) =>
  fns.forEach(fn => typeof fn === 'function' && fn(...args));
