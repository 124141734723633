import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { RootAction } from '../../store/root';
import { TourStopOfflineStatus } from '../../common';
import actions from './actions';

export interface ITourStopOfflineStatusState {
  status: Array<TourStopOfflineStatus & { syncId?: string }>;
}

const defaultState: ITourStopOfflineStatusState = {
  status: [],
};

const tourStopOfflineStatusReducer = createReducer<ITourStopOfflineStatusState, RootAction>(defaultState)
  .handleAction(actions.syncTourStopOfflineStatusAsync.request, (state, action) => {
    const syncId = action.payload;
    return produce(state, draft => {
      draft.status.forEach(it => {
        if (it.syncId === undefined) {
          it.syncId = syncId;
        }
      });
    });
  })
  .handleAction(actions.syncTourStopOfflineStatusAsync.failure, (state, action) => {
    const syncId = action.payload;
    return produce(state, draft => {
      draft.status.forEach(it => {
        if (it.syncId === syncId) {
          it.syncId = undefined; // reset syncId so that it can be tried again
        }
      });
    });
  })
  .handleAction(actions.syncTourStopOfflineStatusAsync.success, (state, action) => {
    const syncId = action.payload;
    return produce(state, draft => {
      draft.status = draft.status.filter(it => it.syncId !== syncId);
    });
  })
  .handleAction(actions.addTourStopOfflineStatus, (state, action) =>
    produce(state, draft => {
      draft.status.push(action.payload);
    })
  );

export default tourStopOfflineStatusReducer;
