import { Language } from '@lialo/common/lib/language';
import { ITask } from '../../../models';
import { convertToImages, IImageList, ImageType } from './Image';

export interface ICreateTaskModel {
  id: number;
  question?: string;
  answer?: string;
  wrongAnswer1?: string;
  wrongAnswer2?: string;
  wrongAnswer3?: string;
  hint?: string;
  imageList?: IImageList;
}

export function convertToTask(createTaskModel: ICreateTaskModel): ITask {
  return {
    taskImages: convertToImages(createTaskModel.imageList, ImageType.TASK),
    question: createTaskModel.question,
    answer: createTaskModel.answer,
    wrongAnswer1: createTaskModel.wrongAnswer1,
    wrongAnswer2: createTaskModel.wrongAnswer2,
    wrongAnswer3: createTaskModel.wrongAnswer3,
    hint: createTaskModel.hint,
  };
}
