import { ITask } from './Task';
import { IImage } from './Media';

export interface ITourStop {
  id: number;
  orderId: number;
  title?: string;
  directions?: string;
  directionImages: IImage[];
  information?: string;
  informationImages: IImage[];
  task?: ITask;
  lookIntoTour: boolean;
  waypoints?: IWaypoint[];
}

export interface IWaypoint {
  lat: number;
  lng: number;
}

export function selectImageUrlsFromTourStop(stop: ITourStop): string[] {
  return [stop.directionImages, stop.informationImages, stop.task?.taskImages]
    .mapNotOptional(list => (list ? list.map(img => img.url) : undefined))
    .flatten();
}

export function getDirectionImages(stop: ITourStop): IImage[] {
  return stop.directionImages.sortBy('orderId', 'ASC');
}

export function getInformationImages(stop: ITourStop): IImage[] {
  return stop.informationImages.sortBy('orderId', 'ASC');
}

export function getTaskImages(stop: ITourStop): IImage[] {
  return stop.task?.taskImages?.sortBy('orderId', 'ASC') ?? [];
}
