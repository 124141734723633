import { RootState } from '../../../store/root';

const selectPreviousLocation = (state: RootState) => state.previousLocation.prevLocation;

const selectReturnToLocation = (state: RootState) => state.previousLocation.returnToLocation;

export default {
  selectPreviousLocation,
  selectReturnToLocation,
};
