type Key = string | number | symbol;

export function pick<T extends object, K extends keyof T>(obj: T, keysToCopy: Array<K>): Pick<T, K> {
  const newObj: Partial<Pick<T, K>> = {};
  keysToCopy.forEach(k => {
    newObj[k] = obj[k];
  });

  return newObj as Pick<T, K>;
}

export function exclude<T extends object, K extends keyof T>(obj: T, keysToExclude: Array<K | string>): Omit<T, K> {
  const newObj: any = {};
  Object.keys(obj).forEach(key => {
    if (obj.hasOwnProperty(key) && keysToExclude.indexOf(key) < 0) {
      newObj[key] = (obj as any)[key];
    }
  });
  return newObj as Omit<T, K>;
}

export function mapObjectKeys(obj: Record<string, any>, keyMapper: (key: string) => string) {
  const newObj: Record<string, any> = {};
  Object.keys(obj).forEach(key => {
    newObj[keyMapper(key)] = obj[key];
  });
  return newObj;
}

export function mapObjectValues<S extends {}, U>(obj: S, valueMapper: (value: S[keyof S]) => U): Record<keyof S, U> {
  const newObj: Partial<Record<keyof S, U>> = {};
  Object.keys(obj).forEach(key => {
    const k = key as keyof S;
    newObj[k] = valueMapper(obj[k]);
  });
  return newObj as Record<keyof S, U>;
}

export function filterRecord<K extends Key, V>(
  record: Record<K, V>,
  filterFunc: (v: V) => boolean
): Record<K, V> {
  const filtered = Object.entries(record).filter(([k, v]) => {
    return filterFunc(v as any);
  });
  return Object.assign({}, ...Array.from(filtered, ([k, v]) => ({ [k]: v })));
}

export function filterRecordByKeys<K extends Key, V>(
  record: Record<K, V>,
  filterFunc: (k: K) => boolean
): Record<K, V> {
  const filtered = Object.entries(record).filter(([k, v]) => {
    return filterFunc(k as any);
  });
  return Object.assign({}, ...Array.from(filtered, ([k, v]) => ({ [k]: v })));
}

export function hasOwnProperties(obj: any, ...properties: string[]): boolean {
  properties.forEach(p => {
    if (!obj.hasOwnProperty(p)) {
      return false;
    }
  });
  return true;
}
