import { ElementType } from '../utils/types/utilTypes';
import { AvailableFilters } from '../pages/findTour/models';
import { Language } from '@lialo/common/lib/language';

export interface ITourFilter {
  audience: ElementType<typeof AvailableFilters['audience']> | null;
  durations: ElementType<typeof AvailableFilters['durations']>[];
  distances: ElementType<typeof AvailableFilters['distances']>[];
  occasions: ElementType<typeof AvailableFilters['occasions']>[];
  categories: ElementType<typeof AvailableFilters['categories']>[];
  handicappedAccessible: true | undefined;
  playAtHome: boolean | undefined;
  roundTrip: true | undefined;
  languages: Language[];
}

export const emptyTourFilter: ITourFilter = {
  audience: null,
  durations: [],
  distances: [],
  occasions: [],
  categories: [],
  handicappedAccessible: undefined,
  roundTrip: undefined,
  playAtHome: undefined,
  languages: [],
};
