import { getServiceProvider, IServiceProvider } from '../../../services';
import actions from '../actions';
import { getType } from 'typesafe-actions';
import { put, select, takeLatest } from 'redux-saga/effects';
import selectors from '../selectors';

function* fetchData(action: ReturnType<typeof actions.fetchTourRatingsAsync.request>) {
  const serviceProvider: IServiceProvider = yield getServiceProvider();

  try {
    const oldestReview = selectors.selectOldestTourRating(yield select());
    const ratingListResponse = yield serviceProvider.api.fetchTourRatings(action.payload, oldestReview?.createdAt);
    yield put(actions.fetchTourRatingsAsync.success(ratingListResponse));
  } catch (e) {
    yield put(actions.fetchTourRatingsAsync.failure(e));
  }
}

const fetchTourRatings = takeLatest(getType(actions.fetchTourRatingsAsync.request), fetchData);

export default [fetchTourRatings];
